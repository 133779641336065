import { getUniqueId } from '@app/core/utilities/getUniqueId';
import type { IProjectGridRow } from '@app/types';
import { getEmptyProjectGridRowAggregateData } from '@app/core/utilities/projectGrid/getEmptyProjectGridRowAggregateData';

export function getNewProjectGridRow(ids: string[], projectId: string): IProjectGridRow {
  const id = getUniqueId(ids);
  return {
    id,
    externalCode: null,
    path: [id],
    newRow: true,
    projectId,
    budgetResources: [],
    actualResources: [],
    remainingResources: [],
    forecastResources: [],
    children: [],
    wbsFullLabel: '',
    parentScopeElementId: null,
    programId: null,
    scheduledStartDate: null,
    projectSubStatusId: null,
    scopeElementTypeId: null,
    scopeElementStatusId: null,
    name: '',
    description: '',
    isClientVisible: false,
    isTimeSheetTask: true,
    velocityWeightId: null,
    activityCodeId: null,
    tags: [],
    dependencies: [],
    dependencyString: '',
    scopeElementItems: 0,
    scopeElementItemsComplete: 0,
    redAlert: false,
    yellowAlert: false,
    notes: '',
    sequenceId: 0,
    subcontractGridRows: [],
    subcontractGridRowIds: [],
    costDistribution: [],
    fundData: [],
    priority: null,
    agg: getEmptyProjectGridRowAggregateData(),
  };
}
