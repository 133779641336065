import { FaList } from 'react-icons/fa';
import { FaCalendarWeek } from '../icons/FaCalendarWeek';
import { FaFileInvoiceDollar } from '../icons/FaFileInvoiceDollar';
import type {
  IColumnGroupShowConfig,
  IProjectGridSectionConfig,
  IProjectGridSectionId,
  IFinanceSectionInputModeConfig,
} from '@app/types';

const DetailsSectionIcon = FaList;
const FinancialSectionIcon = FaFileInvoiceDollar;
const ScheduleSectionIcon = FaCalendarWeek;

export const TOP_LEVEL_HEADER_GROUP_CLASS_NAME = 'ag-top-level-header-group';
export const SECOND_LEVEL_HEADER_GROUP_CLASS_NAME = 'ag-second-level-header-group';

export const PROJECT_FORM_UNIQUE_CODE_ERROR = 'Project Code Must Be Unique';

export const DATA_SOCKET_SECTIONS: {
  [key in Exclude<IProjectGridSectionId, 'scope'>]: IProjectGridSectionConfig;
} = {
  details: {
    sectionId: 'details' as const,
    className: 'details-identifier',
    icon: DetailsSectionIcon,
  },
  budget: {
    sectionId: 'budget' as const,
    className: 'budget-identifier',
    icon: FinancialSectionIcon,
  },
  actual: {
    sectionId: 'actual' as const,
    className: 'actual-identifier',
    icon: FinancialSectionIcon,
  },
  remaining: {
    sectionId: 'remaining' as const,
    className: 'remaining-identifier',
    icon: FinancialSectionIcon,
  },
  forecast: {
    sectionId: 'forecast' as const,
    className: 'forecast-identifier',
    icon: FinancialSectionIcon,
  },
  schedule: {
    sectionId: 'schedule' as const,
    className: 'schedule-identifier',
    icon: ScheduleSectionIcon,
  },
  costDistribution: {
    sectionId: 'costDistribution' as const,
    className: 'costDistribution-identifier',
    icon: FinancialSectionIcon,
  },
  funds: {
    sectionId: 'funds' as const,
    className: 'funds-identifier',
    icon: FinancialSectionIcon,
  },
} as const;

export const FINANCE_SECTION_INPUT_MODES: IFinanceSectionInputModeConfig = {
  budget: {
    personnel: 'unit',
    equipment: 'unit',
    expense: 'unit',
    subcontract: 'cost',
  },
  actual: {
    personnel: 'cost',
    equipment: 'cost',
    expense: 'cost',
    subcontract: 'cost',
  },
  remaining: {
    personnel: 'cost',
    equipment: 'cost',
    expense: 'cost',
    subcontract: 'cost',
  },
  forecast: {
    personnel: 'unit',
    equipment: 'unit',
    expense: 'unit',
    subcontract: 'cost',
  },
};

/**
 * Default column visibility configuration for the projects overview page.
 * Available values for each column ID are:
 *  - undefined: column is always visible, regardless of the collapsed state on the column group
 *  - 'open': column is only visible when the column group is expanded
 *  - 'closed': column is only visible when the column group is collapsed
 * @type IColumnGroupShowConfig
 */
export const defaultProjectsOverviewColumnGroupShowConfig: IColumnGroupShowConfig = {
  'actual-cost-percent-complete': undefined,
  'actual-equipment-total': 'open',
  'actual-expense-total': 'open',
  'actual-personnel-total': 'open',
  'actual-subcontract-total': 'open',
  'actual-total': undefined,
  'budget-equipment-total': 'open',
  'budget-expense-total': 'open',
  'budget-personnel-total': 'open',
  'budget-pursuit-probability': undefined,
  'budget-subcontract-total': 'open',
  'budget-total': undefined,
  durationValue: 'open',
  'forecast-equipment-total': 'open',
  'forecast-equipment-variance': 'open',
  'forecast-expense-total': 'open',
  'forecast-expense-variance': 'open',
  'forecast-personnel-total': 'open',
  'forecast-personnel-variance': 'open',
  'forecast-subcontract-total': 'open',
  'forecast-subcontract-variance': 'open',
  'forecast-total': undefined,
  'forecast-variance': undefined,
  idLabel: undefined,
  name: undefined,
  notes: 'open',
  percentComplete: undefined,
  programId: 'open',
  priority: 'open',
  projectManagerId: undefined,
  'remaining-equipment-total': 'open',
  'remaining-expense-total': 'open',
  'remaining-personnel-total': 'open',
  'remaining-subcontract-total': 'open',
  'remaining-total': undefined,
  scheduledEndDate: undefined,
  scheduledStartDate: undefined,
  scopeElementTypeId: 'open',
  statusId: undefined,
  tags: 'open',
};

/**
 * Default column visibility configuration for the project details page.
 * Available values for each column ID are:
 *  - undefined: column is always visible, regardless of the collapsed state on the column group
 *  - 'open': column is only visible when the column group is expanded
 *  - 'closed': column is only visible when the column group is collapsed
 * @type IColumnGroupShowConfig
 */
export const defaultProjectColumnGroupShowConfig: IColumnGroupShowConfig = {
  'details-scopeElementTypeId': undefined,
  'details-programId': 'open',
  'details-scopeElementStatusId': undefined,
  'details-activityCodeId': 'open',
  'details-tags': 'open',
  'details-velocityWeightId': 'open',
  'details-externalCode': 'open',
  'details-isTimeSheetTask': undefined,
  'details-notes': 'open',
  'details-priority': 'open',
  'details-description': 'open',
  'schedule-scheduledStartDate': undefined,
  'schedule-scheduledEndDate': undefined,
  'schedule-durationValue': 'open',
  'schedule-percentComplete': undefined,
  'schedule-dependencies': 'open',
};

export const pinnedTopRowId = 'total';
