import type { ITimelineZoomLevel } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setWorkloadTimelineSettings = createAsyncThunk<
  void,
  {
    zoomLevel?: ITimelineZoomLevel;
    startDate?: Date;
    endDate?: Date;
    rememberPeriod?: boolean;
    staticRangeId?: string | null;
  },
  { state: IState }
>('uiView/setWorkloadTimelineSettings', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
