export const SYNCFUSION_LICENSE_KEY =
  'ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0BjWH9XcXRQTmVc'; // version 25.x

export const DEFAULT_SCHEDULE_SETTINGS = {
  HOURS_PER_DAY: 8,
  INCLUDE_WEEKENDS: false,
  WEEKDAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
};

export const ALL_WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const ALL_WEEKDAYS_HASH = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

export const GANTT_MINIMUM_GRID_PANE_WIDTH = '300px';
