import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { indentScopeElement } from '../../api/scopeElement.api';
import { awaitUntil } from '../../core/utilities/awaitUntil';
import type { IProject, IProjectGridRow } from '../../types';
import {
  projectGridRowsSelectors,
  recalculateProjectScopeElementTree,
  updateManyProjectGridRows,
  updateProjectGridRowsTreeVersion,
} from '../projectGridRows';
import { ensureProjectGridRows } from '../projectGridRows/ensureProjectGridRows';
import { findPreviousElementIdForIndent } from '../projectGridRows/findPreviousElementIdForIndent';
import { getProjectGridSiblingRows } from '../projectGridRows/getProjectGridSiblingRows';
import type { IState } from '../store';
import { updateProjectGridRows } from './updateProjectGridRows';
import { updateFundDataTree } from './updateFundDataTree';

interface IIndentProjectGridRowsProps {
  ids: string[];
  project: IProject;
  transactionId?: string;
}
export const indentProjectGridRows = createAsyncThunk<void, IIndentProjectGridRowsProps, { state: IState }>(
  'projectGridRows/indentProjectGridRows',
  async (props, { getState, dispatch }) => {
    // get the updated version from state rather the one passed to the thunk
    const { ids, project, transactionId } = props;

    // make sure all the row IDs are valid
    const refElements = ensureProjectGridRows(getState().projectGridRows, ids);

    invariant(refElements[0], 'Could not find referenced element for indentProjectGridRow');

    const siblings = getProjectGridSiblingRows(getState().projectGridRows, refElements[0]);

    for (const refElement of refElements) {
      const curElementIndex = siblings.findIndex((sibling) => sibling.id === refElement.id);
      const nextParentId = findPreviousElementIdForIndent(curElementIndex, siblings, ids);

      invariant(nextParentId, 'Could not find next parent for indentProjectGridRow');

      const nextParent = projectGridRowsSelectors.selectById(getState().projectGridRows, nextParentId);
      invariant(nextParent);

      const curParent = refElement.parentScopeElementId
        ? projectGridRowsSelectors.selectById(getState().projectGridRows, refElement.parentScopeElementId)
        : null;

      const updates: { id: string; changes: Partial<IProjectGridRow> }[] = [];

      // update current parent
      if (curParent) {
        updates.push({
          id: curParent.id,
          changes: {
            children: curParent.children.filter((childId) => childId !== refElement.id),
          },
        });
      }

      // update next parent
      updates.push({
        id: nextParent.id,
        changes: {
          children: [...nextParent.children, refElement.id],
        },
      });

      // update parent on current element
      updates.push({
        id: refElement.id,
        changes: {
          parentScopeElementId: nextParent.id,
        },
      });

      dispatch(updateManyProjectGridRows(updates));
    }

    await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

    await indentScopeElement({ ids });
    dispatch(recalculateProjectScopeElementTree({ projectId: project.id }));

    const updatedElements = ensureProjectGridRows(getState().projectGridRows, ids);

    dispatch(updateProjectGridRows({ projectGridRows: updatedElements, project, transactionId }));
    dispatch(updateProjectGridRowsTreeVersion({ projectId: project.id }));
    for (const id of ids) {
      dispatch(updateFundDataTree({ projectGridRowId: id }));
    }
  }
);
