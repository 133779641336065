import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { ITag } from '../types';

const adapter = createEntityAdapter<ITag>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAll: adapter.setAll,
    addMany: adapter.addMany,
  },
});

export const tagsSelectors = adapter.getSelectors();

export const tagsActions = slice.actions;

export default slice.reducer;
