import { clsx } from 'clsx';
import * as React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FORM_INPUT_BASE_CLASS } from './constants';
import { getFontSizeClass } from './getFontSizeClass';

type IFormSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & { textSize?: 'xs' | 'sm' | 'base' | 'lg' };

export const FormSelect = React.forwardRef<HTMLSelectElement, IFormSelectProps>(function FormSelect(props, ref) {
  const { className, children, multiple = false, disabled = false, textSize = 'sm', ...rest } = props;
  return (
    <div className={clsx('grid', className)}>
      <select
        className={clsx(FORM_INPUT_BASE_CLASS, ' col-start-1 row-start-1 appearance-none px-4')}
        ref={ref}
        multiple={multiple}
        disabled={disabled}
        {...rest}
      >
        {children}
      </select>
      <div
        className={clsx(
          'pointer-events-none relative col-start-1 row-start-1 justify-self-end',
          getFontSizeClass(textSize),
          {
            'self-start pr-8 pt-3': !!multiple,
            'self-center pr-2': !multiple,
            'opacity-50': disabled,
          }
        )}
      >
        <FaChevronDown className="size-4 text-app-gray-dark " />
      </div>
    </div>
  );
});
