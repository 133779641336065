import type { ITimesheetEntry, ITimesheetRow } from '../../types';

export function generateNewTimesheetRowFromEntry(entry: ITimesheetEntry): ITimesheetRow {
  const {
    personId,
    projectId,
    activityCodeId,
    scopeElementId,
    scopeElementName,
    resourceCategoryId,
    resourceCategoryName,
    projectName,
    projectCode,
    scopeElementWbs,
    firstName,
    lastName,
    isTimeSheetTask,
  } = entry;
  return {
    rowGroup: crypto.randomUUID(),
    personId,
    projectId,
    activityCodeId,
    scopeElementId,
    scopeElementName,
    resourceCategoryId,
    resourceCategoryName,
    timesheetEntryIds: [entry.id],
    projectName,
    projectCode,
    scopeElementWbs,
    firstName,
    lastName,
    isTimeSheetTask,
  };
}
