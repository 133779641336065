import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { IUserAuthorizationResult } from '@app/types';
import type { IState } from './store';

const adapter = createEntityAdapter<IUserAuthorizationResult, string>({
  selectId: (result) => result.permissionId,
});

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'userAuthorizationResult',
  initialState,
  reducers: {
    setManyUserAuthorizationResults: adapter.setMany,
  },
});

export const selectUserAuthorizationResultsByIds = createSelector(
  [(state: IState) => state.userAuthorizationResults, (_state: IState, permissionIds: string[]) => permissionIds],
  (state, permissionIds) => {
    return permissionIds.map((permissionId) => {
      const result = adapter.getSelectors().selectById(state, permissionId);
      if (!result) {
        console.warn('Missing user authorization result for permission', permissionId); // eslint-disable-line no-console
      }
      return result?.result;
    });
  }
);

export const { setManyUserAuthorizationResults } = slice.actions;

export default slice.reducer;
