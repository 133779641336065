import type * as React from 'react';
import { useAppSelector } from '@app/store';
import { selectUserAuthorizationResultsByIds } from '@app/store/userAuthorizationResults';
import type { IFeatureFlag, ITenantType, ITenantTypeName } from '@app/types';
import { tenantTypeSelectors } from '@app/store/tenantTypes';

import { FEATURE_FLAGS } from '@app/config';

function verifyFeatureFlag(featureFlag?: IFeatureFlag) {
  if (!featureFlag) {
    return true;
  }
  return FEATURE_FLAGS[featureFlag] ?? false;
}

function verifyTenantType(
  currentTenantType: ITenantType | undefined,
  requiredTenantType: ITenantTypeName | ITenantTypeName[] | undefined
) {
  if (!requiredTenantType) {
    return true;
  }

  const requiredTypes = Array.isArray(requiredTenantType) ? requiredTenantType : [requiredTenantType];

  if (!currentTenantType || !requiredTypes.includes(currentTenantType.name)) {
    return false;
  }

  return true;
}

interface IProps {
  element: React.ReactNode;
  permissions?: string[];
  tenantType?: ITenantTypeName | ITenantTypeName[];
  featureFlag?: IFeatureFlag;
}

export const Restrict = (props: IProps) => {
  const { permissions = [], element, tenantType, featureFlag } = props;

  const tenantTypeId = useAppSelector((state) => state.auth.user?.currentTenant?.tenantTypeId);
  const currentTenantType = useAppSelector((state) =>
    tenantTypeSelectors.selectById(state.tenantTypes, tenantTypeId ?? '')
  );

  const permissionResults = useAppSelector((state) => selectUserAuthorizationResultsByIds(state, permissions));

  const isValidTenantType = verifyTenantType(currentTenantType, tenantType);
  const isAuthorized = permissions.length === 0 || permissionResults.every((result) => result === true);
  const featureEnabled = verifyFeatureFlag(featureFlag);

  if (isAuthorized && isValidTenantType && featureEnabled) {
    return element;
  }
  return null;
};
