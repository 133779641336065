import { createUserPreferences, updateUserPreferences } from '@app/api/userPreferences.api';
import invariant from '@app/core/utilities/invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserPreferencesQuery } from '@app/queries/userPreferences.queries';
import { queryClient } from '@app/queryClient';
import type { IState } from '@app/store';

export const persistUserPreferences = createAsyncThunk<void, void, { state: IState }>(
  'uiView/persistUserPreferences',
  async (_, { getState }) => {
    const { userPreferences, version, workload, projectsOverview, prioritizeLanding, projectStatusReport, budget } =
      getState().uiView;

    const uiViewState = { version, workload, projectsOverview, prioritizeLanding, projectStatusReport, budget };

    const userId = getState().auth.user?.id;
    const tenantId = getState().auth.user?.currentTenant?.id;
    invariant(userId);
    invariant(tenantId);

    const nextUserPreferences = userPreferences
      ? await updateUserPreferences({ ...userPreferences, uiViewState })
      : await createUserPreferences({ userId, uiViewState });

    queryClient.setQueryData(getUserPreferencesQuery(tenantId).queryKey, () => nextUserPreferences);
  }
);
