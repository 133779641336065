import axios from 'axios';
import type { IWorkloadElement } from '../types';

export interface IGetWorkloadElementsParams {
  startDate: string;
  endDate: string;
  personId?: string;
  name?: string;
  programIds?: string[];
  projectIds?: string[];
  projectManagerIds?: string[];
  projectStatusIds?: string[];
}

export const getWorkloadElements = (params: IGetWorkloadElementsParams) => {
  return axios.post<IWorkloadElement[]>('/workload-elements', params);
};

export const refreshWorkloadElements = (params: IGetWorkloadElementsParams) => {
  return axios.post<IWorkloadElement[]>('/workload-elements/refresh', params);
};

export const applyWorkloadElements = () => {
  return axios.post<IWorkloadElement[]>('/workload-elements/apply');
};

export const updateWorkloadElement = (workloadElement: IWorkloadElement) => {
  return axios.put<boolean>(`/workload-elements/${workloadElement.id}`, workloadElement);
};
