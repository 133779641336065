import { createAsyncThunk } from '@reduxjs/toolkit';
import { applyWorkloadElements } from '../../api/workloadElement.api';
import type { IWorkloadGridRow } from '../../types';
import { mapWorkloadElementToWorkloadGridRow } from '../../core/utilities/workload/mapWorkloadElementToWorkloadGridRow';
import type { IState } from '../store';

export const applyWorkload = createAsyncThunk<IWorkloadGridRow[], undefined, { state: IState }>(
  'workload/apply',
  async () => {
    const { data } = await applyWorkloadElements();

    return data.map(mapWorkloadElementToWorkloadGridRow);
  }
);
