import { type QueryKey, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { type MouseEvent, type ReactNode, forwardRef, useRef, useState } from 'react';
import { getCustomerPortalUrl } from '../../api/customerPortal.api';
import { useReportError } from '../../hooks/useReportError';
import CircularProgressIndicator from './CircularProgressIndicator';

interface IProps {
  className?: string;
  enabled: boolean;
  children: ReactNode;
  tenantId?: string;
}

function getCustomerPortalUrlQueryKey(tenantId: string | undefined): QueryKey {
  return ['customer-portal-url', tenantId];
}

export const CustomerPortalLink = forwardRef<HTMLAnchorElement, IProps>(function CustomerPortalLink(props, ref) {
  const { enabled, className, children, tenantId } = props;
  const reportError = useReportError();

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const [isClicked, setIsClicked] = useState(false);

  const query = useQuery({
    queryKey: getCustomerPortalUrlQueryKey(tenantId),
    queryFn: async () => {
      try {
        const { data } = await getCustomerPortalUrl(tenantId);
        if (isClicked && linkRef.current) {
          linkRef.current.href = data.customerPortalUrl;
          window.location.href = data.customerPortalUrl;
        }
        setIsClicked(false);
        return data ?? null;
      } catch (error) {
        reportError(error);
        return null;
      }
    },

    enabled,
  });
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!query.isSuccess) {
      event.preventDefault();
      setIsClicked(true);
    }
  };

  return (
    <a
      className={className}
      href={query.data?.customerPortalUrl ?? ''}
      onClick={handleClick}
      target="StripePortalWindow"
      ref={(node) => {
        linkRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
    >
      <div className="relative">
        {query.isError && <strong className="text-app-red-primary">We can’t load customer portal URL</strong>}
        {!query.isError && <span className={clsx({ invisible: isClicked })}>{children}</span>}
        {isClicked && (
          <CircularProgressIndicator
            size="xs"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        )}
      </div>
    </a>
  );
});
