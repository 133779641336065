import axios from 'axios';
import type { IBudgetFilterSetFields, IPeriodicProjectBudgetData, IProjectBudget } from '@app/types';

export const getProjectBudgets = (params: IBudgetFilterSetFields) => {
  const { startDate, endDate, programIds, projectIds, projectManagerIds, projectStatusIds, projectSubStatusIds } =
    params;

  return axios.post<IProjectBudget[]>('/project-budgets', {
    startDate,
    endDate,
    programIds,
    projectIds,
    projectManagerIds,
    projectStatusIds,
    projectSubStatusIds,
  });
};

export function bulkUpdateProjectBudgets(data: IPeriodicProjectBudgetData[]) {
  return axios.put('/bulk-update-project-budgets', data);
}
