import { parseServerDate } from '@app/core/utilities/date';
import { add, isAfter, isSameDay, startOfDay } from 'date-fns';

/**
 * Determines if a to-do alert should be active on a scope element
 * based on the scheduled end date and the provided days treshold
 *
 * @param endDate Date | string | null | undefined
 * @param daysThreshold number
 * @returns boolean
 */
export function isTodoAlertActive(endDate: Date | string | null | undefined, daysThreshold: number) {
  const parsed = parseServerDate(endDate);

  if (!parsed) {
    return false;
  }
  const now = new Date();
  const startOfTodayInUTC = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

  const threshold = startOfDay(add(parsed, { days: daysThreshold }));

  return isSameDay(startOfTodayInUTC, threshold) || isAfter(startOfTodayInUTC, threshold);
}
