import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import invariant from '@app/core/utilities/invariant';
import { updateCurrentEmployee } from '../../api/employee.api';
import CircularProgressIndicator from '../../core/components/CircularProgressIndicator';
import { showErrorToast, showLoadingToast, showSuccessToast } from '../../core/utilities/toasts';
import { EmployeeForm, type IEmployeeFormValues } from '../admin/employee/components/EmployeeForm';
import { useReportError } from '../../hooks/useReportError';
import {
  getCurrentEmployeeQueryKey,
  getEmployeeQueryKey,
  useCurrentEmployeeQuery,
} from '../../queries/employee.queries';
import { useAppDispatch, useAppSelector } from '../../store';
import { setUser } from '../../store/auth';
import type { IDiversity } from '../../types';

export const UserProfileForm = () => {
  const reportError = useReportError();

  const { isLoading, isError, data: employee } = useCurrentEmployeeQuery();

  const queryKey = getCurrentEmployeeQueryKey();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const onSubmit = useCallback(
    async (data: IEmployeeFormValues) => {
      invariant(employee, 'Employee not found');
      invariant(user, 'User not found');

      const employeeQueryKey = getEmployeeQueryKey(employee?.person.personId);

      const toastId = showLoadingToast({ message: 'Saving profile' });
      try {
        await updateCurrentEmployee({
          diversity: { ...employee.diversity, ...data.diversity } as any as IDiversity,
          address: { ...employee.address, ...data.address },
          person: { ...employee.person, ...data.person },
        });
        showSuccessToast({ message: 'Your profile was saved successfully' }, { id: toastId });

        dispatch(setUser({ ...user, firstName: data.person.firstName, lastName: data.person.lastName }));

        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({ queryKey: employeeQueryKey });
      } catch (error) {
        reportError(error);
        showErrorToast({ message: 'Error saving profile' }, { id: toastId });
      }
    },
    [employee, queryClient, queryKey, reportError, user, dispatch]
  );

  if (isLoading) {
    return (
      <div className="flex h-20 items-center justify-center">
        <CircularProgressIndicator />
      </div>
    );
  }

  if (isError) {
    return <div>We couldn’t load your profile information</div>;
  }
  if (employee) {
    return (
      <EmployeeForm
        employee={employee}
        onSubmit={onSubmit}
        autoComplete
        submitLabel="Save profile"
        includeDiversityFields
        protectProfileData
      />
    );
  }

  return null;
};
