import { clsx } from 'clsx';
import * as React from 'react';

type IFormFileUploadProps = {
  id: string;
  btnText?: string;
  selectedFileName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FormFileUpload = React.forwardRef(function FormFileUpload(
  props: IFormFileUploadProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { id, className, btnText, selectedFileName, ...rest } = props;
  return (
    <div className={clsx('relative flex items-center gap-2 overflow-hidden', className)}>
      <label htmlFor={id}>
        <button type="button" className="btn-blue">
          {btnText ?? 'Upload'}
        </button>
      </label>
      {selectedFileName && <span className="text-xs">{selectedFileName}</span>}
      <input id={id} type="file" className="absolute inset-0 text-6xl opacity-0" ref={ref} {...rest} />
    </div>
  );
});
