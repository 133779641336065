import { clsx } from 'clsx';
import * as React from 'react';
import { FaSearch } from 'react-icons/fa';
import { FORM_INPUT_BASE_CLASS } from './constants';
import { getFontSizeClass } from './getFontSizeClass';

type IFormInputProps = {
  prefix?: React.ReactNode | string;
  suffix?: React.ReactNode | string;
  textSize?: 'xs' | 'sm' | 'base' | 'lg';
  search?: boolean;
  inputClassName?: string;
  iconClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FormInput = React.forwardRef(function FormInput(
  props: IFormInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    className,
    type = 'text',
    prefix,
    suffix,
    textSize = 'base',
    search = false,
    inputClassName,
    iconClassName,
    ...rest
  } = props;
  return (
    <div className={clsx('relative grid', className)}>
      <input
        type={type}
        className={clsx(
          FORM_INPUT_BASE_CLASS,
          getFontSizeClass(textSize),
          'col-start-1 row-start-1 appearance-none read-only:opacity-50',
          {
            'pl-4': !prefix,
            'pr-4': !suffix,
            'pl-9': !!prefix,
            'pr-9': !!suffix,
          },
          inputClassName
        )}
        ref={ref}
        {...rest}
      />
      {search && (
        <div className="absolute right-1 flex h-full items-center p-2">
          <FaSearch className={iconClassName} />
        </div>
      )}
      {!!prefix && (
        <div className="pointer-events-none col-start-1 row-start-1 self-center pl-4">
          <div className="flex w-2 justify-center text-sm">{prefix}</div>
        </div>
      )}
      {!!suffix && (
        <div className="pointer-events-none col-start-1 row-start-1 self-center justify-self-end pr-4">
          <div className="flex w-2 justify-center text-sm">{suffix}</div>
        </div>
      )}
    </div>
  );
});
