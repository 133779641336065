import invariant from '@app/core/utilities/invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateScopeElement } from '../../api/scopeElement.api';
import { awaitUntil } from '../../core/utilities/awaitUntil';
import { mapScopeElementToProjectGridRow } from '../../core/utilities/projectGrid/mapScopeElementToProjectGridRow';
import type { IProjectGridRow } from '../../types';
import {
  projectGridRowsSelectors,
  updateProjectGridRowsTreeVersion,
  upsertOneProjectGridRow,
} from '../projectGridRows';
import type { IState } from '../store';
import { prepareScopeElementUpdate } from './prepareScopeElementUpdate';
import { updateFundDataTree } from './updateFundDataTree';

export const addProjectGridRowChild = createAsyncThunk<
  void,
  { projectGridRow: IProjectGridRow; id: string },
  { state: IState }
>('projectGridRows/addProjectGridRowChild', async (props, { getState, dispatch }) => {
  const { id } = props;

  // get the updated version from state rather the one passed to the thunk
  const projectGridRow = projectGridRowsSelectors.selectById(getState().projectGridRows, props.projectGridRow.id);

  invariant(projectGridRow, 'projectGridRow not found');

  await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

  const { data } = await updateScopeElement({
    ...prepareScopeElementUpdate(projectGridRow),
    parentScopeElementId: id,
  });

  const nextProjectGridRow = mapScopeElementToProjectGridRow({
    scopeElement: data,
    indexArr: projectGridRow.wbsFullLabel,
    path: projectGridRow.path,
    children: [],
    fundData: projectGridRow.fundData,
  });

  // Insert the new element into the tree
  dispatch(upsertOneProjectGridRow({ ...nextProjectGridRow, isProcessing: false, newRow: false }));
  // Update the tree version
  dispatch(updateProjectGridRowsTreeVersion({ projectId: projectGridRow.projectId }));

  // update fund parent/child relationships
  dispatch(updateFundDataTree({ projectGridRowId: data.id }));
});
