import type { AnyAction, Draft, ThunkDispatch } from '@reduxjs/toolkit';
import { uniqWith } from 'lodash-es';
import invariant from '@app/core/utilities/invariant';
import { type IProjectGridRowsSliceState, projectGridRowsSelectors, updateOneProjectGridRow } from '.';
import type { IProject, IProjectGridRow } from '../../types';
import type { IState } from '../store';
import { calculateWorkPercentComplete } from './calculateWorkPercentComplete';

interface IRowDepth {
  row: IProjectGridRow;
  depth: number;
}

function revertDepth(items: IRowDepth[], maxDepth: number) {
  return items.map((item) => ({ ...item, depth: maxDepth - item.depth }));
}

function getAncestorTree(
  state: IProjectGridRowsSliceState,
  row: IProjectGridRow,
  acc: IRowDepth[] = [],
  currentDepth = 0
): IRowDepth[] {
  if (row.parentScopeElementId === null) {
    const nextAcc = [{ row, depth: currentDepth }, ...acc];
    return revertDepth(nextAcc, currentDepth);
  }

  const parent = state.entities[row.parentScopeElementId];
  if (!parent) {
    throw new Error('Parent not found');
  }

  return getAncestorTree(state, parent, [{ row, depth: currentDepth }, ...acc], currentDepth + 1);
}

function getAffectedRows(state: Draft<IProjectGridRowsSliceState>, id: string): IRowDepth[] {
  const row = state.entities[id];
  if (!row || !row.parentScopeElementId) {
    return [];
  }

  if (!row.parentScopeElementId) {
    return [{ row, depth: 0 }];
  }

  return getAncestorTree(state, row);
}

/*
  @desc

  1. Ids represent rows for which the percent complete has changed
  2. We need to fetch all parents and calculate the depth they are at
  3. Remove duplicates
  4. Sort by depth, descending - lowest children first
  5. Calculate work % complete from children, going up the tree
*/
interface IRecalculatePercentCompleteProps {
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, AnyAction>;
  ids: string[];
  project: IProject;
}

export function recalculatePercentComplete({
  getState,
  dispatch,
  ids,
  project,
}: IRecalculatePercentCompleteProps): IProjectGridRow[] {
  const affectedRows = uniqWith(
    ids.flatMap((id) => getAffectedRows(getState().projectGridRows, id)),
    (a, b) => a.row.id === b.row.id
  );

  affectedRows.sort((a, b) => b.depth - a.depth);

  return affectedRows.map((item) => {
    const children = item.row.children
      .map((id) => projectGridRowsSelectors.selectById(getState().projectGridRows, id))
      .filter((row): row is IProjectGridRow => !!row);

    if (children.length === 0) {
      const cur = projectGridRowsSelectors.selectById(getState().projectGridRows, item.row.id);
      invariant(cur);
      return cur;
    }
    const percentComplete = calculateWorkPercentComplete(children, project);

    dispatch(updateOneProjectGridRow({ id: item.row.id, changes: { percentComplete } }));
    const cur = projectGridRowsSelectors.selectById(getState().projectGridRows, item.row.id);
    invariant(cur);
    return cur;
  });
}
