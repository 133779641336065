import { bulkUpsertSubcontractScopes } from '@app/api/subcontract.api';
import { mapSubcontractGridRowToSubcontractScope } from '@app/pages/subs/components/subcontractGridHelpers';
import type { ISubcontractGridRow } from '@app/types';
import { type AnyAction, type ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import {
  type ISubcontractGridRowUpdateQueueEntry,
  addOneSubcontractGridRowUpdateEntry,
  removeSubcontractGridRowUpdateQueueEntries,
  setIsProcessingSubcontractGridRowUpdateQueue,
} from '.';
import type { IState } from '../store';

// get a list of all first occurrences of row.id from the current queue
function getNextBatchOfRowUpdates(entries: ISubcontractGridRowUpdateQueueEntry[]) {
  const ids = new Set<string>();
  return entries.reduce((acc, cur) => {
    if (!ids.has(cur.row.id)) {
      ids.add(cur.row.id);
      return acc.concat(cur);
    }
    return acc;
  }, [] as ISubcontractGridRowUpdateQueueEntry[]);
}

interface IProcessQueueParams {
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, AnyAction>;
}
// Iterate over the queue as long as there are any items in it
async function processQueue(params: IProcessQueueParams): Promise<void> {
  const { getState, dispatch } = params;

  const queue = getState().subcontractGridRows.updateQueue;

  if (queue.length > 0) {
    const entries = getNextBatchOfRowUpdates(queue);

    const scopes = entries.map((entry) => mapSubcontractGridRowToSubcontractScope(entry.row));

    await bulkUpsertSubcontractScopes(scopes);

    dispatch(removeSubcontractGridRowUpdateQueueEntries(entries.map((entry) => entry.id)));

    return processQueue(params);
  }
}

// Queue a list of changes to the scope element
export const queueSubcontractGridRowUpdate = createAsyncThunk<void, ISubcontractGridRow, { state: IState }>(
  'subcontractGridRow/queueSubcontractGridRowUpdate',
  async (row, { getState, dispatch }) => {
    dispatch(addOneSubcontractGridRowUpdateEntry({ id: crypto.randomUUID(), row }));

    const isProcessingQueue = getState().subcontractGridRows.isProcessingQueue;
    if (isProcessingQueue) {
      return;
    }

    dispatch(setIsProcessingSubcontractGridRowUpdateQueue(true));

    await processQueue({
      getState,
      dispatch,
    });
  }
);
