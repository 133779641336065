import invariant from '@app/core/utilities/invariant';
import {
  type IMapScopeElementToProjectGridRowParams,
  mapScopeElementToProjectGridRow,
} from '@app/core/utilities/projectGrid/mapScopeElementToProjectGridRow';
import { type ThunkDispatch, type UnknownAction, createAsyncThunk } from '@reduxjs/toolkit';

import { bulkUpdateScopeElements } from '@app/api/scopeElement.api';
import type { IProjectGridRow, IScopeElement } from '@app/types';
import {
  type IUpdateQueueEntry,
  clearProjectGridRowUpdateQueue,
  fulfillProjectGridRowTransaction,
  queueProjectGridRowsUpdate,
  setIsProcessingProjectGridRowUpdateQueue,
} from '../projectGridRows';
import type { IState } from '../store';

export interface IProjectGridRowUpdatePayload {
  scopeElement: IScopeElement;
  overrides: () => Omit<IMapScopeElementToProjectGridRowParams, 'scopeElement'>;
}

interface IUpdateScopeElementFromQueueParams {
  currentResult?: IProjectGridRowUpdatePayload[];
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, UnknownAction>;
}

function reduceUpdatedQueue(entries: IUpdateQueueEntry[]): IProjectGridRowUpdatePayload[] {
  const mergedQueue = entries.reduce(
    (acc, cur) => {
      const { data } = cur;

      return data.reduce((nextAcc, updatePayload) => {
        acc[updatePayload.scopeElement.id] = updatePayload;
        return acc;
      }, acc);
    },
    {} as { [id: string]: IProjectGridRowUpdatePayload }
  );

  return Object.values(mergedQueue);
}

// Iterate over the queue as long as there are any items in it
async function processQueue(params: IUpdateScopeElementFromQueueParams): Promise<IProjectGridRowUpdatePayload[]> {
  const { getState, dispatch, currentResult = [] } = params;

  const queue = getState().projectGridRows.updateQueue;

  if (queue.length > 0) {
    // clear the queue
    dispatch(clearProjectGridRowUpdateQueue());

    // get a list of transactions from the current queue
    const transactionIds = queue.map((item) => item.transactionId);

    // de-duplicate the queue entries. Newer entries override older ones
    const reducedQueue = reduceUpdatedQueue(queue);

    const nextData = reducedQueue.map((item) => ({ ...item.scopeElement, children: [] }));
    const { data } = await bulkUpdateScopeElements(nextData);

    const result = data.map((item) => {
      const payload = reducedQueue.find((queueItem) => queueItem.scopeElement.id === item.id);
      invariant(payload, 'Payload not found in processQueue result');
      return {
        scopeElement: item,
        overrides: payload.overrides,
      };
    });
    dispatch(fulfillProjectGridRowTransaction(transactionIds));

    // merge the current result with the new result
    const currentResultAsHash = currentResult.reduce(
      (acc, cur) => {
        acc[cur.scopeElement.id] = cur;
        return acc;
      },
      {} as { [id: string]: IProjectGridRowUpdatePayload }
    );

    const nextResultHash = result.reduce((acc, cur) => {
      acc[cur.scopeElement.id] = cur;
      return acc;
    }, currentResultAsHash);

    const nextResult = Object.values(nextResultHash);

    return processQueue({ currentResult: nextResult, getState, dispatch });
  }

  return currentResult;
}

// Queue a list of changes to scope element
export const queueScopeElementsUpdate = createAsyncThunk<
  IProjectGridRow[],
  { data: IProjectGridRowUpdatePayload[]; transactionId?: string },
  { state: IState }
>('projectGridRows/queueScopeElementsUpdate', async (payload, { getState, dispatch }) => {
  const transactionId = payload.transactionId ?? crypto.randomUUID();

  dispatch(queueProjectGridRowsUpdate({ data: payload.data, transactionId }));

  const isProcessingQueue = getState().projectGridRows.isProcessingQueue;
  if (isProcessingQueue) {
    return [];
  }
  dispatch(setIsProcessingProjectGridRowUpdateQueue(true));

  const newData = await processQueue({
    getState,
    dispatch,
  });

  return newData.map(({ scopeElement, overrides }) =>
    mapScopeElementToProjectGridRow({ scopeElement, ...overrides() })
  );
});
