export const PERMISSION_TYPE_HIDE = 'Hide' as const;
export const PERMISSION_TYPE_VIEW = 'View' as const;
export const PERMISSION_TYPE_CREATE = 'Create' as const;
export const PERMISSION_TYPE_EDIT = 'Edit' as const;
export const PERMISSION_TYPE_DELETE = 'Delete' as const;

// SPECIFIC PERMISSIONS BASED ON DATA FROM 2022-09-12

export const PERMISSION_EXPENSES_VIEW = '5f555bab-b376-40a9-8a25-73b27ab99eb9' as const;
export const PERMISSION_ALL_EXPENSE_APPROVALS_EDIT = 'e639e1b5-5c2f-4999-a475-5d378c7245b3' as const;
export const PERMISSION_ALL_EXPENSE_MEMOS_EDIT = '20cd1544-5dab-4526-b034-5bb5f525c0f1' as const;
export const PERMISSION_ALL_EXPENSES_DELETE = '662489f3-1c8b-42c5-b38a-675a18fc3eae' as const;
export const PERMISSION_ALL_EXPENSES_EDIT = '9672be1d-3562-49d2-9627-3c7f8c71c841' as const;
export const PERMISSION_ALL_EXPENSES_VIEW = '664a8573-224d-4a9c-8aae-0851aaceaa37' as const;
export const PERMISSION_ALL_SYSTEM_FIELDS_CREATE = 'bd6d010d-c5fa-475c-bb26-2ee88c91afbf' as const;
export const PERMISSION_ALL_SYSTEM_FIELDS_DELETE = 'e6c517c0-09bc-4adf-96df-5ecebb0acbb1' as const;
export const PERMISSION_ALL_SYSTEM_FIELDS_EDIT = 'c2835376-05ab-4811-9cd0-cf0a0a462e54' as const;
export const PERMISSION_ALL_SYSTEM_FIELDS_VIEW = '2933e556-e27a-461a-bcbc-10b7a6edab4d' as const;
export const PERMISSION_ALL_TIMESHEET_LOCKING_EDIT = '00dc28c6-a760-494e-b89d-717458ad577d' as const;
export const PERMISSION_ALL_TIMESHEET_MEMOS_EDIT = '18675df2-4c62-4516-9dba-e2bf40c82865' as const;
export const PERMISSION_ALL_TIMESHEETS_EDIT = '4315f45d-874e-47ad-b721-65e489f39484' as const;
export const PERMISSION_ALL_TIMESHEETS_VIEW = '8447770d-6925-499f-9aff-a0db297552b1' as const;
export const PERMISSION_ANY_OFFICE_EXPENSE_APPROVALS_EDIT = '3285dfc9-b10b-48b2-b3a5-23fec6043c88' as const;
export const PERMISSION_ANY_OFFICE_EXPENSE_MEMOS_EDIT = '20bc48b7-9223-4948-8023-df7ba365dbe8' as const;
export const PERMISSION_ANY_OFFICE_EXPENSE_MEMOS_VIEW = 'cd52b670-d84c-457b-8068-5c55497cda0b' as const;
export const PERMISSION_ANY_OFFICE_EXPENSES_EDIT = 'd20125c9-39d9-4bf3-a98c-4adc0e1632d1' as const;
export const PERMISSION_ANY_OFFICE_EXPENSES_VIEW = 'e0741795-f0cf-423b-9316-30cffd4b5b4a' as const;
export const PERMISSION_TIMESHEETS_VIEW = '7d9d8d23-8d2c-49b5-92e4-f6d1e9975119' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEET_LOCKING_EDIT = '4b721532-6580-48b0-80fe-d4238de2e1c9' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEET_MEMOS_EDIT = 'f384a069-870e-41d3-b429-bd73948e397b' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEETS_CREATE = 'b9b6cd63-45a2-47d2-b57c-23ba13d26a22' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEETS_DELETE = '453e7397-e4dc-4487-a16c-1ce7f1346ba2' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEETS_EDIT = 'dc4ec850-6a66-434c-a798-68d302c5aa5b' as const;
export const PERMISSION_ANY_OFFICE_TIMESHEETS_VIEW = '9323652e-42fa-466b-8c4f-e4a72792c3ed' as const;
export const PERMISSION_ANY_PROGRAM_EXPENSE_APPROVALS_EDIT = 'efd7b68a-79bd-4faf-baef-ef503055513a' as const;
export const PERMISSION_ANY_PROGRAM_EXPENSE_MEMOS_EDIT = '8f057702-44df-4e55-b521-2cdd4199f979' as const;
export const PERMISSION_ANY_PROGRAM_EXPENSE_MEMOS_VIEW = '62bb7e4f-23fb-4c64-a812-79c4d8833c2c' as const;
export const PERMISSION_ANY_PROGRAM_EXPENSES_EDIT = 'bdbffa2f-1863-430f-af91-b737893b75b2' as const;
export const PERMISSION_ANY_PROGRAM_EXPENSES_VIEW = 'a15d1da7-b47d-4818-b73f-07575cbff7cd' as const;
export const PERMISSION_ANY_PROGRAM_TIMESHEET_LOCKING_EDIT = '4672a2f1-3e82-43a2-9038-cbfcd376b1c0' as const;
export const PERMISSION_ANY_PROGRAM_TIMESHEET_MEMOS_EDIT = '509cf012-eb1b-4c79-b57b-f97431fbd812' as const;
export const PERMISSION_ANY_PROGRAM_TIMESHEETS_EDIT = '107080b6-f268-4956-9955-57d4e61364a7' as const;
export const PERMISSION_ANY_PROGRAM_TIMESHEETS_VIEW = '8a649b11-76fa-4f6b-8005-1361c38e1c91' as const;
export const PERMISSION_TODOS_CREATE = 'f1b3b862-2b85-4369-b011-641c0751968f' as const;
export const PERMISSION_TODOS_DELETE = '054b3577-9b18-4664-8260-39edd94c1c48' as const;
export const PERMISSION_TODOS_EDIT = '8b1beb44-a374-48ba-8199-c707c5f7147a' as const;
export const PERMISSION_TODOS_VIEW = 'f0beb90f-773d-461e-9fe2-127d7ffe5a7a' as const;
export const PERMISSION_COPY_PROJECT_CREATE = '5cb15f9c-56ce-4839-af78-728fab0f95ec' as const;
export const PERMISSION_FINANCE_ACTUAL_VIEW = '95231650-4363-4b54-8d05-e2053d3b9ae9' as const;
export const PERMISSION_FINANCE_BUDGET_CREATE = 'e36ae005-ea3e-4f89-b1a4-f91af4227b2c' as const;
export const PERMISSION_FINANCE_BUDGET_DELETE = '6fc16bd2-b3f2-455b-8945-822e61e43787' as const;
export const PERMISSION_FINANCE_BUDGET_EDIT = 'eab227bd-d534-4067-bb4d-a55ae7429291' as const;
export const PERMISSION_FINANCE_BUDGET_VIEW = 'cb3c959e-5cd9-4835-90b3-e6d0b4081a0e' as const;
export const PERMISSION_FINANCE_FORECAST_CREATE = 'e93e4f7e-89d3-4c49-9ea8-879aadf07431' as const;
export const PERMISSION_FINANCE_FORECAST_DELETE = '5fa91d5f-9965-40f8-9ac4-a26bd826bcc3' as const;
export const PERMISSION_FINANCE_FORECAST_EDIT = '2eb239a4-cbdd-412c-afbf-a08ccf44df60' as const;
export const PERMISSION_FINANCE_FORECAST_VIEW = '5ae23059-7e80-4319-b2b4-cf267b14a388' as const;
export const PERMISSION_FINANCE_REMAINING_VIEW = 'a83da03d-46e7-4159-90d8-bf95cae05b73' as const;
export const PERMISSION_PROJECT_CLIENT_FIELDS_CREATE = '8fbfbdd1-5025-48dd-9821-9e61a2777911' as const;
export const PERMISSION_PROJECT_CLIENT_FIELDS_EDIT = 'b7819cf6-f051-4555-a414-6e7b8c861042' as const;
export const PERMISSION_PROJECT_CLIENT_FIELDS_VIEW = '7d4f9394-39b4-437a-85ac-9713bf0a2d53' as const;
export const PERMISSION_PROJECT_INFO_FIELDS_EDIT = 'fb572141-270a-454e-80a3-c7b8de9cc940' as const;
export const PERMISSION_PROJECT_INFO_FIELDS_VIEW = 'a7512088-ea84-43c8-a02b-ebe4cd667132' as const;
export const PERMISSION_PROJECT_INVOICE_FIELDS_EDIT = '842f20d5-9e1b-402d-9a98-b7f65a038b34' as const;
export const PERMISSION_PROJECT_INVOICE_FIELDS_VIEW = '1a3bf821-dcf4-4fd4-b85f-8bd4ea447c6a' as const;
export const PERMISSION_PROJECT_PERSONNEL_FIELDS_EDIT = '8f49d935-e595-43e6-9601-f1911930fa06' as const;
export const PERMISSION_PROJECT_PERSONNEL_FIELDS_VIEW = '74cfd42a-0d0d-480d-9708-57ae220279f0' as const;
export const PERMISSION_PROJECT_RATE_TABLE_FIELDS_CREATE = 'e5126458-f399-46f5-8084-879622cce1c0' as const;
export const PERMISSION_PROJECT_RATE_TABLE_FIELDS_DELETE = '41a422fb-7d07-4382-a43e-c65b89901a5f' as const;
export const PERMISSION_PROJECT_RATE_TABLE_FIELDS_EDIT = 'fec31b24-b7be-4875-87b8-d718b15920d3' as const;
export const PERMISSION_PROJECT_RATE_TABLE_FIELDS_VIEW = 'a15ad6bb-630e-4244-90a1-a5ad9da4ff3c' as const;
export const PERMISSION_PROJECT_STATUS_EDIT = 'ca56813b-91e6-4d66-a348-18c52e8fe65f' as const;
export const PERMISSION_PROJECT_SUB_STATUS_CREATE = '718d0f68-48c8-42ee-b2b3-786a85f6dd27' as const;
export const PERMISSION_PROJECT_SUB_STATUS_DELETE = 'c63c30a0-a091-4c0e-b644-474365d32ce4' as const;
export const PERMISSION_PROJECT_SUB_STATUS_EDIT = '87736848-d218-4149-ba12-012699ddfbe9' as const;
export const PERMISSION_PROJECT_SUB_STATUS_VIEW = '781d9023-3c7d-442b-952f-a78c3f681b0b' as const;

export const PERMISSION_PROJECT_STATUS_REPORT_VIEW = '795ca887-bd2d-44dc-8764-181ea25ae7e4' as const;
export const PERMISSION_PROJECT_STATUS_REPORT_EDIT = 'baf5183f-e663-4b8b-979f-395d658f79e6' as const;

export const PERMISSION_PROJECT_DOCUMENTS_CREATE = '673dd9aa-ba2b-4152-9d48-f61fa6d1f288' as const;
export const PERMISSION_PROJECT_DOCUMENTS_DELETE = '7b2cf681-554e-42cc-8536-cb1e58e55a71' as const;
export const PERMISSION_PROJECT_DOCUMENTS_EDIT = 'c98dc37b-3588-41cf-86a7-a1c0b044f524' as const;
export const PERMISSION_PROJECT_DOCUMENTS_VIEW = 'eb4a4f20-5721-43e9-a379-d3d247f3dc0e' as const;
export const PERMISSION_PROJECT_CREATE = 'e6388d38-fa20-40b5-8423-1ebc6f4753b1' as const;
export const PERMISSION_PROJECT_DELETE = '2194de38-e4cc-4a95-8432-288c2f3bb4a8' as const;
export const PERMISSION_PROJECT_EDIT = 'ede53ae8-fc10-4d08-814d-ba4b56e65d12' as const;
export const PERMISSION_PROJECT_VIEW = '8b27fd5c-77f3-4d67-9b50-a9a3e40c9cee' as const;
export const PERMISSION_SCHEDULE_BASELINES_EDIT = '934b3c78-9915-4379-9483-2c63c54a303a' as const;
export const PERMISSION_SCHEDULE_BASELINES_VIEW = '956bc630-8f1d-4968-88d7-e98d4413eba0' as const;
export const PERMISSION_SCHEDULE_EDIT = '92ca898d-2d3b-460a-8f8e-768f35738067' as const;
export const PERMISSION_SCHEDULE_VIEW = 'd324717e-b267-4539-88b3-bab1190bfb2c' as const;
export const PERMISSION_SCOPE_DETAILS_EDIT = '809fdca6-8472-4baa-a75f-46457135f015' as const;
export const PERMISSION_SCOPE_DETAILS_VIEW = 'c019d108-53ad-4a23-ba67-503db13bebe0' as const;
export const PERMISSION_SCOPE_ELEMENTS_CREATE = 'f51e5446-7c5b-4833-8260-5b370226e877' as const;
export const PERMISSION_SCOPE_ELEMENTS_DELETE = '4e849c00-aa93-4d76-aa66-5a4224388813' as const;
export const PERMISSION_SCOPE_ELEMENTS_EDIT = '2266829f-3cbb-43b6-9c50-e65049ff91d1' as const;
export const PERMISSION_SCOPE_NARRATIVES_EDIT = '63d765f9-3494-4963-992d-10b82b771284' as const;
export const PERMISSION_SCOPE_NARRATIVES_VIEW = 'adc35fbb-9144-4f38-9354-630d18c47797' as const;
export const PERMISSION_SUBCONTRACTS_CREATE = 'de2d081d-898c-4f87-9a5e-2c7fad6e651a' as const;
export const PERMISSION_SUBCONTRACTS_DELETE = 'a9e1ac95-dc30-4ea2-abd4-460526cc1d51' as const;
export const PERMISSION_SUBCONTRACTS_EDIT = 'b3274bbe-9574-46c6-9663-428cd9bd7d58' as const;
export const PERMISSION_SUBS_CREATE = 'd404a368-4d6b-435e-aa38-f51ef787a131' as const;
export const PERMISSION_SUBS_DELETE = '02e6e096-84f6-4353-b7ab-2251224ee46e' as const;
export const PERMISSION_SUBS_EDIT = '43870b8d-c5c7-4a50-82ba-940ae27a997f' as const;
export const PERMISSION_SUBS_VIEW = 'b8a4032e-e146-411e-80be-3c1a67ef4773' as const;
export const PERMISSION_WORKLOAD_CREATE = '47ee9c50-b93d-420a-be0c-773f489cccc1' as const;
export const PERMISSION_WORKLOAD_DELETE = '8cfe97a8-c5d7-4760-8029-9b471afd78c3' as const;
export const PERMISSION_WORKLOAD_EDIT = '7769bd54-7746-4355-b7fa-6de45d07d8a8' as const;
export const PERMISSION_WORKLOAD_VIEW = 'b5402d73-603e-49a3-a7c9-db5f017467c1' as const;

export const PERMISSION_FUNDS_CREATE = '95d928b0-ea26-43ee-89b6-b664cc9ec2be' as const;
export const PERMISSION_FUNDS_EDIT = '9b24177f-3dae-4ae6-8a65-639894394ac0' as const;
export const PERMISSION_FUNDS_DELETE = '1bb1ec7d-3aa2-40d3-ba54-6adbd4290073' as const;
export const PERMISSION_FUNDS_VIEW = '1c06132a-29c4-4dd9-b026-771d8360a24f' as const;
