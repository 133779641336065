import { isTodoAlertActive } from '@app/core/utilities/isTodoAlertActive';
import type { EntityState } from '@reduxjs/toolkit';
import type { IProjectGridRow } from '../../types';
import { projectGridRowsSelectors } from '../projectGridRows';
interface IProps {
  projectGridRow: IProjectGridRow;
  state: EntityState<IProjectGridRow, string>;
  milestoneId: string;
  yellowAlertDays: number;
  redAlertDays: number;
}

function getScopeElementTypeId(currentItem: IProjectGridRow, nextItem: IProjectGridRow, milestoneId: string) {
  // if scopeElementTypeId was changed by the user, it takes precedence
  if (currentItem.scopeElementTypeId !== nextItem.scopeElementTypeId) {
    return nextItem.scopeElementTypeId;
  }
  // if duration has changed and current duration is 0, change scope element type to milestone
  return currentItem.durationValue !== nextItem.durationValue && nextItem.durationValue === 0
    ? milestoneId
    : currentItem.scopeElementTypeId;
}

export function prepareProjectGridRowUpdate(props: IProps): IProjectGridRow {
  const { projectGridRow, state, milestoneId, yellowAlertDays, redAlertDays } = props;

  const currentItem = projectGridRowsSelectors.selectById(state, projectGridRow.id);

  const redAlert = isTodoAlertActive(projectGridRow.scheduledEndDate, redAlertDays);
  const yellowAlert = isTodoAlertActive(projectGridRow.scheduledEndDate, yellowAlertDays);
  let nextItem = { ...projectGridRow, isProcessing: true, redAlert, yellowAlert };

  if (currentItem) {
    const scopeElementTypeId = getScopeElementTypeId(currentItem, nextItem, milestoneId);

    // set percent complete to 100% if the actualEndDate has been set
    const percentComplete = !currentItem.actualEndDate && nextItem.actualEndDate ? 100 : nextItem.percentComplete;

    nextItem = { ...nextItem, scopeElementTypeId, percentComplete };
  }
  // this `null` property is added inside of the Gantt component, make sure to strip it out
  (nextItem as any).null = undefined;

  return nextItem;
}
