import { getCurrentUser } from '@app/api/user.api';
import { queryOptions } from '@tanstack/react-query';

export function getCurrentUserQuery() {
  return queryOptions({
    queryKey: ['current-user'],
    queryFn: async () => (await getCurrentUser()).data,
    refetchOnWindowFocus: 'always',
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
    gcTime: 0,
  });
}
