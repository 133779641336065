import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit';
import type { IWorkloadGridRow } from '../types';
import { calculateWorkloadDistribution } from '../core/utilities/workload/distribution/calculateWorkloadDistribution';
import type { IState } from './store';
import { applyWorkload, refreshWorkload } from './thunks';

const adapter = createEntityAdapter<IWorkloadGridRow>();

export const workloadGridRowSelectors = adapter.getSelectors();

const slice = createSlice({
  name: 'workload',
  initialState: adapter.getInitialState({
    applying: false,
    refreshing: false,
    applyError: null as SerializedError | null,
    refreshError: null as SerializedError | null,
    applySuccess: null as boolean | null,
    refreshSuccess: null as boolean | null,
  }),
  reducers: {
    addManyWorkloadGridRows: (state, action: PayloadAction<IWorkloadGridRow[]>) => {
      return adapter.addMany(
        state,
        action.payload.map((row) => ({
          ...row,
          ...calculateWorkloadDistribution(row),
        }))
      );
    },
    removeAllWorkloadGridRows: (state) => {
      return adapter.removeAll(state);
    },
    updateOneWorkloadGridRow: adapter.updateOne,
    resetWorkloadSyncResult: (state) => {
      state.applyError = null;
      state.applySuccess = null;
      state.refreshError = null;
      state.refreshSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyWorkload.pending, (state) => {
      state = adapter.updateMany(
        state,
        workloadGridRowSelectors.selectAll(state).map((row) => ({
          id: row.id,
          changes: {
            hours: row.draftHours,
          },
        }))
      );
      state.applying = true;
      state.applyError = null;
      state.applySuccess = null;

      return state;
    });

    builder.addCase(applyWorkload.rejected, (state, { error }) => {
      state.applying = false;
      state.applyError = error;
    });

    builder.addCase(applyWorkload.fulfilled, (state) => {
      state.applying = false;
      state.applySuccess = true;
    });

    builder.addCase(refreshWorkload.pending, (state) => {
      state = adapter.updateMany(
        state,
        workloadGridRowSelectors.selectAll(state).map((row) => ({
          id: row.id,
          changes: {
            draftHours: row.hours,
          },
        }))
      );

      state.refreshing = true;
      state.refreshError = null;
      state.refreshSuccess = null;
    });

    builder.addCase(refreshWorkload.fulfilled, (state) => {
      state.refreshing = false;
      state.refreshSuccess = true;
    });

    builder.addCase(refreshWorkload.rejected, (state, { error }) => {
      state.refreshing = false;
      state.refreshError = error;
    });
  },
});

export const { addManyWorkloadGridRows, removeAllWorkloadGridRows, updateOneWorkloadGridRow, resetWorkloadSyncResult } =
  slice.actions;

export const selectWorkloadGroupDictionary = createSelector(
  [(state: IState) => workloadGridRowSelectors.selectAll(state.workload)],
  (rows) => {
    return rows.reduce(
      (acc, row) => {
        acc[row.groupId] = row.groupName;
        acc[row.projectId] = `[${row.projectCode}] ${row.projectName}`;
        return acc;
      },
      {} as { [key: string]: string }
    );
  }
);

/**
 * Determines whether workload hours have been updated from the original value.
 */
export const selectIsWorkloadHoursDirty = createSelector(
  [(state: IState) => workloadGridRowSelectors.selectAll(state.workload)],
  (rows) => rows.some((row) => row.hours !== row.draftHours)
);

export default slice.reducer;
