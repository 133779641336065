import invariant from '@app/core/utilities/invariant';
import type { IFundData } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateOneProjectGridRow } from '../projectGridRows';
import type { IState } from '../store';
import { getProjectGridRowTotalCostValueForFundCalculation } from './getProjectGridRowTotalCostValueForFundCalculation';
import { queueFundDataUpdate } from './queueProjectFundUpdate';

function getNextNonVariableFundDataValues(fundData: IFundData, total: number) {
  switch (fundData.fundMethod) {
    case 'amount':
      return {
        amount: fundData.amount,
        percentage: (fundData.amount / total) * 100,
      };
    case 'percentage':
      return {
        percentage: fundData.percentage,
        amount: (fundData.percentage * total) / 100,
      };
    default:
      throw new Error('Invalid fund method');
  }
}

/**
 * This thunk should be executed each time we are updating fields that affect total cost values
 * of the project grid row. It recalculates fund data amounts based on the new total value.
 */
export const recalculateFundDataAmounts = createAsyncThunk<void, { projectGridRowId: string }, { state: IState }>(
  'projectGridRows/recalculateFundDataAmounts',
  async (props, { getState, dispatch }) => {
    const { projectGridRowId } = props;

    const projectGridRow = getState().projectGridRows.entities[projectGridRowId];
    invariant(projectGridRow);

    // no fund data on the row, skip it
    if (projectGridRow.fundData.length === 0) {
      return;
    }

    const fundDataSample = projectGridRow.fundData[0];

    const prevTotalValue =
      fundDataSample.percentage > 0 ? (fundDataSample.amount / fundDataSample.percentage) * 100 : 0;
    const totalValue = getProjectGridRowTotalCostValueForFundCalculation(fundDataSample.inherent, projectGridRow);

    // Nothing changed, skip it
    if (prevTotalValue === totalValue) {
      return;
    }

    const nextFundData: IFundData[] = [];
    // First, update all nonVariable fund data
    const nonVariableFundData = projectGridRow.fundData.filter((fd) => fd.fundMethod !== 'variable');
    for (const fd of nonVariableFundData) {
      const { amount, percentage } = getNextNonVariableFundDataValues(fd, totalValue);
      nextFundData.push({ ...fd, amount, percentage });
    }

    // Calculate the total sum of non variable, so we can calculate total variable amount left
    const totalSumOfNonVariableFundData = nextFundData.reduce((acc, fd) => acc + fd.amount, 0);

    const nextVariableFundDataSum = totalValue - totalSumOfNonVariableFundData;
    const variableFundData = projectGridRow.fundData.filter((fd) => fd.fundMethod === 'variable');
    const prevVariableFundDataSum = variableFundData.reduce((acc, fd) => acc + fd.amount, 0);

    for (const fd of variableFundData) {
      // new amount is the the previous ratio of fundData amount to totalValue, multiplied by the new totalValue
      const amount =
        prevVariableFundDataSum === 0 ? 0 : (fd.amount / prevVariableFundDataSum) * nextVariableFundDataSum;

      // new percentage is the new amount divided by the new totalValue
      const percentage = totalValue === 0 ? 0 : (amount / totalValue) * 100;
      nextFundData.push({ ...fd, amount, percentage });
    }

    dispatch(
      updateOneProjectGridRow({
        id: projectGridRow.id,
        changes: {
          fundData: nextFundData,
        },
      })
    );
    for (const fd of nextFundData) {
      dispatch(queueFundDataUpdate({ data: fd, type: 'update', transactionId: 'recalculate fund data amounts' }));
    }
  }
);
