import { shortISODateFormat } from '@app/config';
import { format } from 'date-fns';
import type { ICalculateWorkloadDistributionProps } from './ICalculateWorkloadDistributionProps';

/*
  Hours are distributed from the start of the interval, filling in all the available hours per day in project
*/
export function calculateFrontDistribution(props: ICalculateWorkloadDistributionProps) {
  const { days, activeDays, hours, hoursPerDay } = props;

  let hoursLeft = hours;

  return days.reduce(
    (acc, cur) => {
      const key = format(cur, shortISODateFormat);

      if (activeDays.includes(cur) === false) {
        acc[key] = 0;
        return acc;
      }

      const value = hoursLeft > hoursPerDay ? hoursPerDay : hoursLeft;
      hoursLeft = hoursLeft > hoursPerDay ? hoursLeft - hoursPerDay : 0;

      acc[key] = value;
      return acc;
    },
    {} as { [date: string]: number }
  );
}
