import { type ThunkDispatch, type UnknownAction, createAsyncThunk } from '@reduxjs/toolkit';

import type { IFundData } from '@app/types';
import { bulkDeleteFundData, bulkUpdateFundData } from '../../api/fundData.api';
import {
  type IFundDataUpdateQueueEntry,
  clearProjectGridRowFundDataUpdateQueue,
  queueProjectGridRowsFundDataUpdate,
  setIsProcessingProjectGridRowFundDataUpdateQueue,
} from '../projectGridRows';
import type { IState } from '../store';

interface IUpdateFundDataFromQueueParams {
  updatedFundData?: IFundData[];
  removedFundData?: IFundData[];
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, UnknownAction>;
}

function reduceUpdatedQueue(entries: IFundDataUpdateQueueEntry[]): IFundDataUpdateQueueEntry[] {
  // We have to account for the following scenarios:
  // 1. Multiple updates to the same fund data entry - we replace the old entry with the new one
  // 2. Create transaction followed by any chain of updates but ending in delete - we remove all transactions .
  //    Otherwise we would try to delete a non-existing database entry

  const createdIds = new Set<string>();

  const mergedQueue = entries.reduce(
    (acc, cur) => {
      if (cur.type === 'create') {
        createdIds.add(cur.data.id);
      }
      if (cur.type === 'delete' && createdIds.has(cur.data.id)) {
        delete acc[cur.data.id];
      } else {
        acc[cur.data.id] = cur;
      }

      return acc;
    },
    {} as Record<string, IFundDataUpdateQueueEntry>
  );

  return Object.values(mergedQueue);
}

// Iterate over the queue as long as there are any items in it
async function processQueue(params: IUpdateFundDataFromQueueParams) {
  const { getState, dispatch, updatedFundData = [], removedFundData = [] } = params;

  const queue = getState().projectGridRows.fundDataUpdateQueue;

  if (queue.length > 0) {
    // clear the queue
    dispatch(clearProjectGridRowFundDataUpdateQueue());

    // de-duplicate the queue entries. Newer entries override older ones
    const reducedQueue = reduceUpdatedQueue(queue);

    const groupedQueueEntries = reducedQueue.reduce(
      (acc, cur) => {
        if (cur.type === 'delete') {
          acc.delete.push(cur);
        } else {
          acc.update.push(cur);
        }
        return acc;
      },
      { delete: [], update: [] } as { delete: IFundDataUpdateQueueEntry[]; update: IFundDataUpdateQueueEntry[] }
    );

    const serverResponseData: IFundData[] = [];

    if (groupedQueueEntries.delete.length > 0) {
      const deleteFundDataIds = groupedQueueEntries.delete.map((entry) => entry.data.id);
      await bulkDeleteFundData(deleteFundDataIds);
      removedFundData.push(...groupedQueueEntries.delete.map((entry) => entry.data));
    }
    if (groupedQueueEntries.update.length > 0) {
      const updateFundDataEntries = groupedQueueEntries.update.map((entry) => entry.data);
      await bulkUpdateFundData(updateFundDataEntries);
      serverResponseData.push(...updateFundDataEntries);
    }

    // merge the current result with the new result
    const currentFundDataAsHash = updatedFundData.reduce(
      (acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      },
      {} as Record<string, IFundData>
    );

    const nextFundDataAsHash = serverResponseData.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, currentFundDataAsHash);

    const nextFundData = Object.values(nextFundDataAsHash);

    return processQueue({ updatedFundData: nextFundData, removedFundData, getState, dispatch });
  }
  return { updatedFundData, removedFundData };
}

// Queue a list of changes to scope element
export const queueFundDataUpdate = createAsyncThunk<
  { updatedFundData: IFundData[]; removedFundData: IFundData[] },
  { data: IFundData; transactionId?: string; type: 'create' | 'update' | 'delete' },
  { state: IState }
>('projectGridRows/queueProjectFundUpdate', async (payload, { getState, dispatch }) => {
  const transactionId = payload.transactionId ?? crypto.randomUUID();

  dispatch(queueProjectGridRowsFundDataUpdate({ data: payload.data, transactionId, type: payload.type }));

  const isProcessingQueue = getState().projectGridRows.isProcessingFundDataQueue;
  if (isProcessingQueue) {
    return { updatedFundData: [], removedFundData: [] };
  }
  dispatch(setIsProcessingProjectGridRowFundDataUpdateQueue(true));

  return await processQueue({
    getState,
    dispatch,
  });
});
