import { useLogoutAction } from '@app/hooks/useLogoutAction';
import { ErrorLayout } from './ErrorLayout';

export function AppErrorInactiveUser() {
  const logoutAction = useLogoutAction();

  return (
    <ErrorLayout title="Inactive User">
      <p className="text-2xl font-bold">Your user account has been deactivated.</p>

      <p>Please contact the account owner to regain access.</p>

      <p>
        <button type="button" className="btn-green btn-lg" onClick={logoutAction}>
          Sign Out
        </button>
      </p>
    </ErrorLayout>
  );
}
