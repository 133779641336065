import { clsx } from 'clsx';

interface IProps {
  /**
   * Size of the spinner.
   * @default 'md'.
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Class name to apply to the spinner.
   * @default 'text-app-blue-primary'.
   */
  className?: string;
}
export default function CircularProgressIndicator(props: IProps) {
  const { size = 'md', className = 'text-app-blue-primary' } = props;

  const circleClassName = clsx({
    'border-4': ['md', 'lg', 'xl'].includes(size),
    'border-2': ['xs', 'sm'].includes(size),
  });

  return (
    <div
      className={clsx('lds-ring', className, {
        'size-4': size === 'xs',
        'size-6': size === 'sm',
        'size-8': size === 'md',
        'size-10': size === 'lg',
        'h-12 w-12': size === 'xl',
      })}
    >
      <div className={circleClassName} />
      <div className={circleClassName} />
      <div className={circleClassName} />
      <div className={circleClassName} />
    </div>
  );
}
