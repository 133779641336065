import type { IFundData, IProjectGridRow } from '@app/types';
import { recalculateValues } from './recalculateValues';

/**
 * Change the fund data to inherent=false and recalculate the values
 *
 * @param fundData IFundData
 * @param descendants IProjectGridRow[] - all descendants of the current row
 * @param projectGridRow IProjectGridRow
 * @returns IFundData
 */
export function combineDescendantFundData(
  fundData: IFundData,
  descendants: IProjectGridRow[],
  projectGridRow: IProjectGridRow
) {
  const clone = structuredClone(fundData);
  clone.inherent = false;

  const nextAmount = descendants.reduce((acc, cur) => {
    const descendantFundData = cur.fundData.find((fd) => fd.fundId === clone.fundId);
    return acc + (descendantFundData?.amount ?? 0);
  }, clone.amount); // start from the current row data

  const combinedFundData = recalculateValues({
    fundData: { ...clone, amount: nextAmount },
    projectGridRow,
    field: 'amount',
  });

  return combinedFundData;
}
