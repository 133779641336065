import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { ITimesheetEntry } from '../../types';
import type { IState } from '../store';
import { queueTimesheetEntryTransaction } from '../timesheet/queueTimesheetEntryTransaction';
import {
  addOneTimesheetRow,
  removeOneTimesheetRow,
  timesheetRowSelectors,
  updateOneTimesheetRow,
} from '../timesheetRows';
import { generateNewTimesheetRowFromEntry } from './generateNewTimesheetRowFromEntry';
import { updateOneTimesheetEntry } from './index';

function shouldReorder(entry: ITimesheetEntry, changes: Partial<ITimesheetEntry>) {
  const newEntry = { ...entry, ...changes };
  const keys = ['projectId', 'activityCodeId', 'scopeElementId', 'resourceCategoryId', 'personId'] as const;
  return keys.some((key) => entry[key] !== newEntry[key]);
}

export const updateTimesheetEntry = createAsyncThunk<
  void,
  { id: string; changes: Partial<ITimesheetEntry> },
  { state: IState }
>('timesheetEntries/updateTimesheetEntry', async (props, { getState, dispatch }) => {
  const { id, changes } = props;

  const entry = getState().timesheetEntries.entities[id];
  invariant(entry, 'Entry not found');

  let nextEntry = { ...entry, ...changes, isNew: false };

  const prevRow = getState().timesheetRows.entities[entry.rowGroup];
  invariant(prevRow, 'Prev row not found');
  const allRows = timesheetRowSelectors.selectAll(getState().timesheetRows);

  // In case we are just adjusting the duration in table view, then
  // we do not want to redo row grouping

  if (shouldReorder(entry, changes)) {
    // find the row matching the new entry properties
    const rowMatch = allRows.find((row) => {
      if (
        nextEntry.projectId === row.projectId &&
        nextEntry.activityCodeId === row.activityCodeId &&
        nextEntry.scopeElementId === row.scopeElementId &&
        nextEntry.resourceCategoryId === row.resourceCategoryId &&
        nextEntry.personId === row.personId
      ) {
        return row;
      }
    });

    // If the row doesn't match, we need to add a new one, and update rowGroup on the entry
    if (rowMatch?.rowGroup !== prevRow.rowGroup) {
      const timesheetEntryIds = prevRow.timesheetEntryIds.filter((entryId) => entryId !== nextEntry.id);

      // If there are no entries left in the row, we need to remove it
      if (timesheetEntryIds.length === 0) {
        dispatch(removeOneTimesheetRow(prevRow.rowGroup));
      } else {
        // Remove entry from the old row
        dispatch(updateOneTimesheetRow({ id: prevRow.rowGroup, changes: { timesheetEntryIds } }));
      }

      // Add new row to the store
      const newRow = generateNewTimesheetRowFromEntry(nextEntry);
      dispatch(addOneTimesheetRow(newRow));

      nextEntry = { ...nextEntry, rowGroup: newRow.rowGroup };
    }
  }

  dispatch(updateOneTimesheetEntry({ id, changes: nextEntry }));
  dispatch(queueTimesheetEntryTransaction({ id, type: 'update' }));
});
