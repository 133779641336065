/**
 * This is a modified version of the tiny-invariant function from React.
 * I do not want to strip the actual error messages
 */

const defaultMessage = 'Invariant failed';

export default function invariant(condition: unknown, message?: string): asserts condition {
  if (condition) {
    return;
  }

  throw new Error(message ?? defaultMessage);
}
