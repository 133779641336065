import { isAxiosError } from 'axios';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { AppErrorFallbackUI } from './core/components/AppErrorFallbackUI';
import { AppErrorInactiveTenant } from './core/components/AppErrorInactiveTenant';
import { AppErrorInactiveUser } from './core/components/AppErrorInactiveUser';
import { AppErrorUnauthorized } from './core/components/AppErrorUnauthorized';
import { NotFound } from './core/components/NotFound';
import { useReportError } from './hooks/useReportError';
import { AppErrorMaintenance } from './core/components/AppErrorMaintenance';

function getStatus(error: unknown) {
  if (error instanceof Error && error.message.includes('503')) {
    return 503;
  }
  if (isAxiosError(error)) {
    return error.response?.status ?? 500;
  }
  if (isRouteErrorResponse(error)) {
    return error.status;
  }
  return 500;
}
function getStatusText(error: unknown) {
  if (isAxiosError(error)) {
    return error.response?.statusText ?? 'Internal Server Error';
  }
  if (isRouteErrorResponse(error)) {
    return error.statusText;
  }
  return 500;
}

export default function ErrorPage() {
  const error = useRouteError();

  const reportError = useReportError();

  const status = getStatus(error);
  const statusText = getStatusText(error);

  if (status === 404) {
    return <NotFound />;
  }
  if (status === 403 && statusText === 'Inactive User') {
    return <AppErrorInactiveUser />;
  }
  if (status === 403 && statusText === 'Subscription Expired') {
    return <AppErrorInactiveTenant />;
  }
  if (status === 403) {
    return <AppErrorUnauthorized />;
  }
  if (status === 503) {
    return <AppErrorMaintenance />;
  }
  reportError(error);

  return <AppErrorFallbackUI />;
}
