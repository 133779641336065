import { createAsyncThunk } from '@reduxjs/toolkit';
import { awaitUntil } from '../../core/utilities/awaitUntil';
import type { IProjectGridRow } from '../../types';
import { projectGridRowsSelectors } from '../projectGridRows';
import type { IState } from '../store';
import { moveScopeElementsUp } from '../../api/scopeElement.api';

export const moveProjectGridRowsUp = createAsyncThunk<
  IProjectGridRow[],
  { projectId: string; ids: string[] },
  { state: IState }
>('projectGridRows/moveProjectGridRowsUp', async (props, { getState }) => {
  // get the updated version from state rather the one passed to the thunk
  const projectGridRows = props.ids
    .map((id) => projectGridRowsSelectors.selectById(getState().projectGridRows, id))
    .filter((x): x is IProjectGridRow => !!x);

  const ids = projectGridRows.map((x) => x.id);

  await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

  await moveScopeElementsUp({ ids });

  return projectGridRows;
});
