import axios from 'axios';
import type { IEmployee, IPerson, IAddress } from '../types';

export const getEmployees = () => {
  return axios.get<IEmployee[]>('/employees');
};

export const getCurrentEmployee = () => {
  return axios.get<IEmployee>('/employee/current');
};

export const getEmployee = (personId: string) => {
  return axios.get<IEmployee>(`/employee/${personId}`);
};

export const updateEmployeeRoles = (userId: string, roles: string[]) => {
  return axios.put(`/employee-roles/${userId}`, roles);
};

export const updateEmployee = (personId: string, employee: IEmployee) => {
  return axios.put<IEmployee>(`/employee/${personId}`, employee);
};

export const updateCurrentEmployee = (employee: IEmployee) => {
  return axios.put<IEmployee>('/employee/current', employee);
};

export const createEmployee = async (employee: {
  person: Omit<IPerson, 'userId' | 'personId' | 'roles'>;
  address: Omit<IAddress, 'id'>;
}) => {
  return axios.post<IEmployee>('/employee', employee);
};

export const deactivateEmployee = async (employee: IEmployee) => {
  return axios.put(`/employee-deactivate/${employee.person.personId}`);
};

export const reactivateEmployee = async (employee: IEmployee) => {
  return axios.put(`/employee-reactivate/${employee.person.personId}`);
};
