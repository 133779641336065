import { calculateProjectGridRowAggregates } from '@app/core/utilities/projectGrid/calculateProjectGridRowAggregates';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { projectGridRowsSelectors, updateOneProjectGridRow } from '../projectGridRows';
import type { IState } from '../store';
import { selectSubcontractGridRowsByScopeElementId } from '../subcontractGridRows/subcontractGridRowsSelectors';
import { recalculateFundDataAmounts } from './recalculateFundDataAmounts';

export const calculateProjectGridRowAggThunk = createAsyncThunk<void, string, { state: IState }>(
  'projectGridRows/calculateProjectGridRowAggThunk',
  async (rowId, { getState, dispatch }) => {
    // find the previous element in the list
    const projectGridRow = projectGridRowsSelectors.selectById(getState().projectGridRows, rowId);
    invariant(projectGridRow, 'projectGridRow not found');

    const subcontractGridRows = selectSubcontractGridRowsByScopeElementId(getState(), rowId);

    const { agg } = calculateProjectGridRowAggregates(projectGridRow, subcontractGridRows);
    dispatch(updateOneProjectGridRow({ id: rowId, changes: { agg } }));
    dispatch(recalculateFundDataAmounts({ projectGridRowId: rowId }));
  }
);
