import type { IProjectGridRow } from '../../types';

export function getAllChildrenIds(allRows: IProjectGridRow[], rowId: string, acc: string[] = []): string[] {
  const row = allRows.find((r) => r.id === rowId);
  if (!row) {
    return acc;
  }

  const childrenIds = row.children.flatMap((id) => [id, ...getAllChildrenIds(allRows, id, acc)]);

  return [...acc, ...childrenIds];
}
