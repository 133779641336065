import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IProjectFilterSet, IProjectFilterSetFields } from '@app/types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectsOverviewFilters = createAsyncThunk<
  void,
  { changes: Partial<IProjectFilterSetFields>; appliedFilterSet?: IProjectFilterSet | null },
  { state: IState }
>('uiView/setProjectsOverviewFilters', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
