import invariant from '@app/core/utilities/invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateOneProjectGridRow } from '../projectGridRows';
import { selectAllProjectGridRowAscendants } from '../projectGridRows/selectors/selectAllProjectGridRowAscendants';
import { selectAllProjectGridRowDescendants } from '../projectGridRows/selectors/selectAllProjectGridRowDescendants';
import type { IState } from '../store';
import { queueFundDataUpdate } from './queueProjectFundUpdate';

export const updateFundDataTree = createAsyncThunk<
  void,
  { projectGridRowId: string; omitSelf?: boolean },
  { state: IState }
>('projectGridRows/updateFundDataTree', async (props, { getState, dispatch }) => {
  const { projectGridRowId } = props;

  const projectGridRow = getState().projectGridRows.entities[projectGridRowId];
  invariant(projectGridRow);

  for (const fd of projectGridRow.fundData) {
    const { inherent } = fd;

    /**
     * When the project grid row has any children, and the fund method is set to "parent"
     * the fundData should be removed for all of the children rows for this fund.
     */
    if (inherent === false) {
      const descendants = selectAllProjectGridRowDescendants(getState(), projectGridRowId);

      for (const descendant of descendants) {
        for (const descendantFundData of descendant.fundData) {
          dispatch(queueFundDataUpdate({ data: descendantFundData, type: 'delete' }));
        }
        dispatch(
          updateOneProjectGridRow({
            id: descendant.id,
            changes: { fundData: [] },
          })
        );
      }
    }

    /**
     * When the project grid row has any parents, and we’ve changed one of the values for a child fund,
     * the method on the parent fundData objects has to change to inherent: true
     */
    const ascendants = selectAllProjectGridRowAscendants(getState(), projectGridRowId);

    for (const ascendant of ascendants) {
      const nextAscendantFundData = ascendant.fundData.map((cur) => {
        const nextData = { ...cur, inherent: true };
        dispatch(queueFundDataUpdate({ data: nextData, type: 'update' }));
        return nextData;
      });

      dispatch(
        updateOneProjectGridRow({
          id: ascendant.id,
          changes: {
            fundData: nextAscendantFundData,
          },
        })
      );
    }
  }
});
