// Create a redux selector that will return all descendants of a project grid row

import { type Draft, createSelector } from '@reduxjs/toolkit';
import type { IProjectGridRow } from '@app/types';
import type { IState } from '@app/store';

// Recursive function to get all descendants of a project grid row
export const getAllProjectGridRowDescendants = (
  rows: Draft<Record<string, IProjectGridRow>>,
  scopeElementId: string
): IProjectGridRow[] => {
  const descendants: IProjectGridRow[] = [];
  const row = rows[scopeElementId];
  if (!row) {
    return descendants;
  }

  for (const childId of row.children) {
    const child = rows[childId];
    descendants.push(child);
    descendants.push(...getAllProjectGridRowDescendants(rows, childId));
  }

  return descendants;
};

// Redux selector to select all descendants of a project grid row
export const selectAllProjectGridRowDescendants = createSelector(
  (state: IState) => state.projectGridRows.entities,
  (_state: IState, scopeElementId: string) => scopeElementId,
  (rows, scopeElementId) => {
    return getAllProjectGridRowDescendants(rows, scopeElementId);
  }
);
