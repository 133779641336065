import { ErrorMessage, type FieldValuesFromFieldErrors } from '@hookform/error-message';
import { clsx } from 'clsx';
import type { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

interface IFormErrorProps<T extends FieldValues = FieldValues> {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>;
  errors: FieldErrors<T>;
  className?: string;
}

export function FormError<T extends FieldValues = FieldValues>(props: IFormErrorProps<T>) {
  const { className = 'text-red-600 block text-xs mt-2', ...rest } = props;
  return <ErrorMessage {...rest} render={({ message }) => <strong className={clsx(className)}>{message}</strong>} />;
}
