import invariant from '@app/core/utilities/invariant';
import type { IBudgetGridRow } from '@app/types';
import { type PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { recalculateBudgetOverallTotals } from './recalculateBudgetOverallTotals';
import { recalculateBudgetProgramTotals } from './recalculateBudgetProgramTotals';
import { recalculateBudgetProjectTotals } from './recalculateBudgetProjectTotals';
import { updateBudgetGridRow } from './updateBudgetGridRow';

const adapter = createEntityAdapter<IBudgetGridRow>();

const budgetGridRowSelectors = adapter.getSelectors();
export const selectAllBudgetGridRows = createSelector([(state: IState) => state.budgetGridRows], (state) => {
  const all = budgetGridRowSelectors.selectAll(state);
  return [all.filter((row) => row.level === 'total'), all.filter((row) => row.level !== 'total')] as const;
});

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'budgetGridRows',
  initialState,
  reducers: {
    setAllBudgetGridRows: adapter.setAll,
    updateOneBudgetGridRow: adapter.updateOne,
    recalculateBudgetTotals: (state, action: PayloadAction<{ rowId: string; fundId?: string | null }>) => {
      const item = state.entities[action.payload.rowId];
      invariant(item, `Budget grid row not found for id ${action.payload.rowId}`);

      if (item.level !== 'project') {
        throw new Error('Only project level rows can be recalculated');
      }

      recalculateBudgetProjectTotals(state, item.projectId, action.payload.fundId);
      recalculateBudgetProgramTotals(state, item.programId, action.payload.fundId);
      recalculateBudgetOverallTotals(state, action.payload.fundId);
    },
    calculateInitialBudgetGridTotals: (state, action: PayloadAction<string[]>) => {
      const allRows: IBudgetGridRow[] = Object.values(state.entities);
      const fundIds = action.payload;

      // First, calculate budget and variance columns at the project level
      const projectIds = allRows
        .map((row) => (row.financeType === 'baseBudget' ? row.projectId : null))
        .filter(Boolean);
      const uniqueProjectIds = Array.from(new Set(projectIds));

      for (const projectId of uniqueProjectIds) {
        recalculateBudgetProjectTotals(state, projectId);
        for (const fundId of fundIds) {
          recalculateBudgetProjectTotals(state, projectId, fundId);
        }
      }

      // Then Calculate totals for at the program level
      const allRowsWithPrograms = Object.values(state.entities);

      const programIds = allRowsWithPrograms
        .map((row) => (row.financeType === 'baseBudget' ? row.programId : null))
        .filter(Boolean);

      const uniqueProgramIds = Array.from(new Set(programIds));

      for (const programId of uniqueProgramIds) {
        recalculateBudgetProgramTotals(state, programId);
        for (const fundId of fundIds) {
          recalculateBudgetProgramTotals(state, programId, fundId);
        }
      }

      // finally, calculate totals at the top level
      recalculateBudgetOverallTotals(state);
      for (const fundId of fundIds) {
        recalculateBudgetOverallTotals(state, fundId);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(updateBudgetGridRow.rejected, (state, error) => {
      console.error('Error updating budget grid row', error);
    });
  },
});

export const {
  setAllBudgetGridRows,
  updateOneBudgetGridRow,
  calculateInitialBudgetGridTotals,
  recalculateBudgetTotals,
} = slice.actions;

export default slice.reducer;
