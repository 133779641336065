import { type UnknownAction, combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import budgetGridData from './budgetGridData';
import budgetGridRows from './budgetGridRows';
import expenseReportOverview from './expenseReportOverview';
import financeElementTypes from './financeElementTypes';
import projectClassifications from './projectClassifications';
import projectGridRows from './projectGridRows';
import projectPreferences from './projectPreferences';
import projectStatuses from './projectStatuses';
import projectSubStatuses from './projectSubStatuses';
import resourceTypes from './resourceTypes';
import scopeElementItems from './scopeElementItems';
import scopeElementTypes from './scopeElementTypes';
import subcontract from './subcontract';
import subcontractGridRows from './subcontractGridRows';
import subcontractorsList from './subcontractorsList';
import tags from './tags';
import tenantTypes from './tenantTypes';
import timesheet from './timesheet';
import timesheetEntries from './timesheetEntries';
import timesheetRows from './timesheetRows';
import uiView from './uiView';
import unitsOfMeasure from './unitsOfMeasure';
import userAuthorizationResults from './userAuthorizationResults';
import workload from './workload';

const appReducer = combineReducers({
  auth,
  budgetGridData,
  budgetGridRows,
  expenseReportOverview,
  financeElementTypes,
  projectClassifications,
  projectGridRows,
  projectPreferences,
  projectStatuses,
  projectSubStatuses,
  resourceTypes,
  scopeElementItems,
  scopeElementTypes,
  subcontract,
  subcontractGridRows,
  subcontractorsList,
  tags,
  tenantTypes,
  timesheet,
  timesheetEntries,
  timesheetRows,
  uiView,
  unitsOfMeasure,
  userAuthorizationResults,
  workload,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
