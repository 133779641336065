import { projectGridRowsSelectors } from '..';
import { createSelector } from '@reduxjs/toolkit';

import type { IState } from '../../store';
import type { IProjectGridRow, ISubcontractGridRow } from '@app/types';

export const selectAllProjectGridRowsByProjectId = createSelector(
  [
    (state: IState) => projectGridRowsSelectors.selectAll(state.projectGridRows),
    (state: IState) => state.subcontractGridRows.entities,
    (_state: IState, projectId: string) => projectId,
  ],
  (projectGridRows, subcontractGridRows, projectId) => {
    return projectGridRows.reduce((acc, cur) => {
      if (cur.projectId !== projectId) {
        return acc;
      }
      // Make sure to fetch the lastest version of the subcontractGridRows from the store
      const nextItem = {
        ...cur,
        subcontractGridRows: cur.subcontractGridRowIds
          .map((id) => subcontractGridRows[id])
          .filter((item): item is ISubcontractGridRow => !!item),
      };
      acc.push(nextItem);
      return acc;
    }, [] as IProjectGridRow[]);
  }
);
