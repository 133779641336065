import invariant from '@app/core/utilities/invariant';
import type { IProjectGridRow } from '../../types';
import { getTopLevelRows } from './getTopLevelRows';
import { type IProjectGridRowsSliceState, projectGridRowsSelectors, adapter } from './index';
import { recalculateRowTreeReducer } from './recalculateRowTreeReducer';

function splitArray<T = unknown>(items: T[], index: number): { before: T[]; after: T[] } {
  const before = items.slice(0, index);
  const after = items.slice(index);

  return {
    before,
    after,
  };
}

export function insertProjectGridRowReducer(state: IProjectGridRowsSliceState, nextRow: IProjectGridRow) {
  const { projectId } = nextRow;
  const allRows = projectGridRowsSelectors.selectAll(state).filter((r) => r.projectId === projectId);

  const nextWBS = nextRow.wbsFullLabel;

  const nextWBSNumeric = nextWBS.split('.').map((s) => Number.parseInt(s, 10));
  const parentWBS = nextWBSNumeric.slice(0, -1).join('.');
  const insertIndex = nextWBSNumeric[nextWBSNumeric.length - 1] - 1;

  let topLevelRows: Array<IProjectGridRow | undefined>;
  if (parentWBS) {
    const parentRow = allRows.find((r) => r.wbsFullLabel === parentWBS);
    invariant(parentRow, `Could not find parent row with WBS ${parentWBS}`);

    const { before, after } = splitArray(parentRow.children, insertIndex);

    state = adapter.updateOne(state, {
      id: parentRow.id,
      changes: { children: [...before, nextRow.id, ...after] },
    });
    topLevelRows = getTopLevelRows(state, projectId);
  } else {
    const { before, after } = splitArray(getTopLevelRows(state, projectId), insertIndex);
    // Leave an empty space for where the new row will be inserted
    topLevelRows = [...before, nextRow, ...after];
  }

  state = adapter.addOne(state, nextRow);
  state = recalculateRowTreeReducer({ state, rows: topLevelRows });

  return state;
}
