export const raceList = [
  { value: 'HispanicLatino', label: 'American Indian or Alaska Native' },
  { value: 'NotHispanicLatino ', label: 'Asian' },
  { value: 'AmericanIndian ', label: 'Black or African American' },
  { value: 'Asian ', label: 'Native Hawaiian or Other Pacific Islander' },
  { value: 'White ', label: 'White' },
  { value: 'DeclineToAnswer ', label: 'Decline To Answer' },
];

export const ethnicityList = [
  { value: 'HispanicLatino', label: 'Hispanic or Latino' },
  { value: 'NotHispanicLatino ', label: 'Not Hispanic or Latino' },
  { value: 'DeclineToAnswer ', label: 'Decline To Answer' },
];

export const genderList = [
  { value: 'Female', label: 'Female' },
  { value: 'Male ', label: 'Male' },
  { value: 'DeclineToAnswer ', label: 'Decline To Answer' },
];

export const disabilityList = [
  { value: 'No', label: 'No' },
  { value: 'Yes ', label: 'Yes' },
  { value: 'DeclineToAnswer ', label: 'Decline To Answer' },
];

export const veteranList = [
  { value: 'No', label: 'No' },
  { value: 'Yes ', label: 'Yes' },
  { value: 'DeclineToAnswer ', label: 'Decline To Answer' },
];
