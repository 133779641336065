import { clsx } from 'clsx';
import * as React from 'react';

type IProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

export const FormRadio = React.forwardRef(function FormCheckbox(
  props: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { className, size = 'md', disabled, ...rest } = props;

  return (
    <div
      className={clsx(
        className,
        'relative inline-grid place-items-center rounded-full border border-app-gray-line bg-white focus-within:ring-2',
        {
          'opacity-50': disabled,
          'size-3': size === 'xs',
          'size-4': size === 'sm',
          'size-5': size === 'md',
          'size-6': size === 'lg',
        }
      )}
    >
      <input
        type="radio"
        {...rest}
        disabled={disabled}
        ref={ref}
        className="peer absolute inset-0 z-10 size-full opacity-0"
      />
      <div
        className={clsx(
          'pointer-events-none size-2 scale-0 rounded-full bg-app-blue-primary transition-transform duration-100 ease-in-out peer-checked:scale-100'
        )}
      />
    </div>
  );
});
