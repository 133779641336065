import { format } from 'date-fns';
import type { ICalculateWorkloadDistributionProps } from './ICalculateWorkloadDistributionProps';
import { shortISODateFormat } from '@app/config';

/*
  Hours are distributed evenly across all active days in the interval
*/
export function calculateUniformDistribution(props: ICalculateWorkloadDistributionProps) {
  const { days, activeDays, hours } = props;
  const hoursPerDay = hours / activeDays.length;

  return days.reduce(
    (acc, cur) => {
      const key = format(cur, shortISODateFormat);
      acc[key] = activeDays.includes(cur) ? hoursPerDay : 0;
      return acc;
    },
    {} as { [date: string]: number }
  );
}
