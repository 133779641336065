// Each time preferences structure changes, we will either have to migrate to either drop then or
// migrate to a new version of preferences. Initial version will reset all preferences to default.
export const PROJECT_PREFERENCE_VERSION_4 = '4.0.0';
export const PROJECT_PREFERENCE_VERSION_5 = '5';
export const PROJECT_PREFERENCE_VERSION_6 = '6';
export const PROJECT_PREFERENCE_VERSION_7 = '7';
export const PROJECT_PREFERENCE_VERSION_8 = '8';
export const PROJECT_PREFERENCE_VERSION_9 = '9';
export const PROJECT_PREFERENCE_VERSION_10 = '10';
export const CURRENT_PROJECT_PREFERENCE_VERSION = PROJECT_PREFERENCE_VERSION_10;
