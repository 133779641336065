import { useAppSelector } from '@app/store';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useTenantTypeString } from '../../hooks/useTenantTypeString';

export const InlineManual = () => {
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();
  const createdRef = useRef(false);

  const tenantType = useTenantTypeString('vendor', 'owner');

  useEffect(() => {
    if (!user || !tenantType) {
      return;
    }

    (window as any).inlineManualTracking = {
      uid: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      tenant: user.currentTenant?.name,
      tenanttype: tenantType,
    };

    if (createdRef.current === false) {
      if ((window as any).createInlineManualPlayer) {
        (window as any).createInlineManualPlayer((window as any).inlineManualPlayerData);
      }
    }
    createdRef.current = true;
  }, [user, tenantType]);

  useEffect(() => {
    if (!user) {
      return;
    }

    (window as any).inline_manual_player?.update();
  }, [location, user]);

  // inline manual skip function
  useEffect(() => {
    (window as any).imSkipElement = (
      player: any,
      _topic_id: string,
      _step_id: string,
      custom_data: { element: string; op: string; step: string }
    ) => {
      try {
        const el = document.querySelector(custom_data.element);
        const step = Number.parseInt(custom_data.step, 10);
        if (Number.isNaN(step)) {
          throw new Error(`Invalid step value: ${custom_data.step}`);
        }
        if ((el && custom_data.op === 'visible') || (!el && custom_data.op === 'hidden')) {
          player.goToStep(step);
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    };
  }, []);

  return null;
};
