import axios from 'axios';
import type { IFundData } from '@app/types';

export const getFundData = async (projectId: string) => {
  return axios.get<IFundData[]>(`/fundDatas/${projectId}`);
};

export const bulkUpdateFundData = async (fundData: IFundData[]) => {
  const data = fundData.map((data) => ({
    ...data,
    amount: data.amount ?? 0,
    percentage: data.percentage ?? 0,
  }));
  return axios.put<IFundData[]>('/fundDatas/bulk-update', data);
};

export const bulkDeleteFundData = async (ids: string[]) => {
  return axios.delete('/fundDatas/bulk-delete', { data: { ids } });
};
