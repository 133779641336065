import { object, string } from 'yup';

export function getAddressFormSchema(required?: boolean) {
  if (required) {
    return object({
      address1: string().required('Enter street address'),
      address2: string().defined().nullable(),
      city: string().required('Enter city'),
      region: string().required('Select state'),
      postalCode: string().required('Enter zip code'),
    });
  }

  return object({
    address1: string().defined().nullable(),
    address2: string().defined().nullable(),
    city: string().defined().nullable(),
    region: string().defined().nullable(),
    postalCode: string().defined().nullable(),
  });
}
