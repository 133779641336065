import invariant from '@app/core/utilities/invariant';
import type { IWorkloadElement, IWorkloadGridRow } from '@app/types';

export function mapWorkloadElementToWorkloadGridRow(element: IWorkloadElement): IWorkloadGridRow {
  const groupId = element.resourceId || element.resourceCategoryId;
  const groupType = element.resourceId ? 'resource' : 'resourceCategory';
  const groupName = groupType === 'resource' ? element.resourceName : element.resourceCategoryName;
  invariant(groupId, 'Workload element requires either resourceId or resourceCategoryId');
  invariant(groupName, 'Workload element requires either resourceName or resourceCategoryName');
  return {
    ...element,
    name: `${element.wbsFullLabel} - ${element.scopeElementName}`,
    groupId,
    groupType,
    groupName,
    days: {},
    allocation: 0,
  };
}
