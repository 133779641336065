import type { Configuration } from 'rollbar';
import { getEnvironment } from '../core/utilities/getEnvironment';

const code_version = document.querySelector('meta[name="app-version"]')?.getAttribute('content') ?? 'unknown';

export const rollbarConfig: Configuration = {
  accessToken: '154739d04d43448e9ea4c804dc217687',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: getEnvironment().type,
    client: {
      javascript: {
        source_map_enabled: true, // false by default

        // -- Add this into your configuration ---
        code_version,
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
  addErrorContext: true,
  enabled: import.meta.env.MODE !== 'development',
};
