import { type IEnvironmentType, ENVIRONMENTS, ENVIRONMENT_UNKNOWN } from '../../config';

function isMatchingHost(needle: string, haystack: string | RegExp): boolean {
  if (typeof haystack === 'string') {
    return needle === haystack;
  }
  return haystack.test(needle);
}

function getEnvironmentType(host: string): IEnvironmentType {
  const environment = ENVIRONMENTS.find((environment) => isMatchingHost(host, environment.host));
  return environment?.type ?? ENVIRONMENT_UNKNOWN;
}

export function getEnvironment(): { type: IEnvironmentType; host: string } {
  const host = window.location.hostname;
  const type = getEnvironmentType(host);
  return { type, host };
}
