import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from '../types';

interface IAuthState {
  user: IUser | null;
}

const authInitialState: IAuthState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },

    setCurrentTenantName(state, action: PayloadAction<string>) {
      if (state.user?.currentTenant) {
        state.user.currentTenant.name = action.payload;
      }
    },
  },
});

export const { setUser, setCurrentTenantName } = authSlice.actions;

export default authSlice.reducer;
