import { isValid, parseISO } from 'date-fns';
import * as React from 'react';
import DatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { FormDatePickerWrapper } from './FormDatePickerWrapper';
import { FORM_INPUT_BASE_CLASS } from '../constants';
import clsx from 'clsx';
import { getFontSizeClass } from '../getFontSizeClass';

type IFormDatePickerProps = {
  name: string;
  datePickerProps?: Omit<ReactDatePickerProps, 'onChange'>;
  textSize?: 'xs' | 'sm' | 'base' | 'lg';
} & React.InputHTMLAttributes<HTMLInputElement>;

function getDatePickerValue(value: unknown): Date | null {
  if (typeof value === 'string') {
    return parseISO(value);
  }
  if (isValid(value)) {
    return value as Date;
  }
  return null;
}

export const FormDatePicker = React.forwardRef<DatePicker, IFormDatePickerProps>(function FormDatePicker(props, ref) {
  const { name, datePickerProps, textSize = 'base', ...inputProps } = props;

  const { control } = useFormContext();

  return (
    <FormDatePickerWrapper>
      <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, onChange, value } }) => {
          return (
            <DatePicker
              {...datePickerProps}
              selected={getDatePickerValue(value as unknown)}
              className={clsx(FORM_INPUT_BASE_CLASS, getFontSizeClass(textSize), 'px-4')}
              wrapperClassName="w-full"
              onChange={onChange}
              id={inputProps.id}
              autoComplete={inputProps.autoComplete}
              disabled={inputProps.disabled}
              onBlur={onBlur}
              ref={ref}
              name={name}
            />
          );
        }}
      />
    </FormDatePickerWrapper>
  );
});
