import clsx from 'clsx';
import * as React from 'react';
import CircularProgressIndicator from './CircularProgressIndicator';

type IProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Whether the button is in a processing state.
   * If true, the button will be disabled and a circular progress indicator will be displayed.
   * @default false
   */
  isProcessing: boolean;
  children: React.ReactNode | React.ReactNode[] | string | null;
};

export const LoadingButton = React.forwardRef<HTMLButtonElement, IProps>(function LoadingButton(props, ref) {
  const { children, isProcessing, className, disabled, type = 'submit', ...rest } = props;
  return (
    <button
      className={clsx(className, 'relative transition-all', { 'disabled:opacity-100': isProcessing })}
      type={type}
      {...rest}
      disabled={disabled || isProcessing}
      ref={ref}
    >
      {isProcessing && (
        <CircularProgressIndicator
          size="xs"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-current"
        />
      )}

      <span className={clsx('inline-flex items-center justify-center', { invisible: isProcessing })}>{children}</span>
    </button>
  );
});
