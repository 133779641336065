import type { ISubcontractGridRow, ISubcontractLimitValue } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IState } from '../store';
import { upsertSubcontractGridRow } from './upsertSubcontractGridRow';

interface IProps {
  id: string;
  subcontractLimitId: string;
  value: number;
  updateForecast: boolean;
}

function getNextRowLimitValues(row: ISubcontractGridRow, nextLimit: ISubcontractLimitValue) {
  const nextValues = structuredClone(row.limitValues);

  if (!nextValues.find((limit) => limit.id === nextLimit.id)) {
    nextValues.push(nextLimit);
    return nextValues;
  }
  return nextValues.map((limit) => (limit.id === nextLimit.id ? nextLimit : limit));
}

export const setSubcontractGridRowLimitValue = createAsyncThunk<void, IProps, { state: IState }>(
  'subcontractGridRows/setLimitValue',
  async (props, { getState, dispatch }) => {
    const { id, subcontractLimitId, value, updateForecast } = props;
    const row = getState().subcontractGridRows.entities[id];
    invariant(row);

    const currentLimit = row.limitValues.find((limitValue) => limitValue.subcontractLimitId === subcontractLimitId);
    const delta = currentLimit ? value - currentLimit.value : value;
    const nextLimit = currentLimit
      ? { ...currentLimit, value }
      : {
          id: crypto.randomUUID(),
          subcontractLimitId,
          subcontractScopeElementId: row.id,
          value,
          projectScopeId: row.projectScopeId,
        };

    const nextForecastValue = updateForecast ? row.forecastValue + delta : row.forecastValue;
    const nextRow = { ...row, limitValues: getNextRowLimitValues(row, nextLimit), forecastValue: nextForecastValue };

    dispatch(upsertSubcontractGridRow(nextRow));
  }
);
