import { ERROR_BULK_UPDATE_SCOPE_ELEMENTS } from '@app/config';
import type { IServerSideGetRowsRequest } from 'ag-grid-community';
import axios from 'axios';
import type { IProjectFilterSetFields, IScopeElement, IScopeElementType } from '../types';

type IGetScopeElementsByProjectRequestParams = Partial<IServerSideGetRowsRequest> & {
  projectId: string;
  parentNodeId?: string;
};

export const getScopeElementsByProject = (params: IGetScopeElementsByProjectRequestParams) => {
  const { projectId, startRow, endRow, parentNodeId } = params;

  const urlParams = new URLSearchParams();
  if (typeof startRow !== 'undefined') {
    urlParams.append('startRow', startRow.toString());
  }
  if (typeof endRow !== 'undefined') {
    urlParams.append('endRow', endRow.toString());
  }
  if (typeof parentNodeId !== 'undefined') {
    urlParams.append('parentScopeElementId', parentNodeId);
  }

  return axios.get<IScopeElement[]>(`/scope-elements-by-project/${projectId}?${urlParams.toString()}`);
};

export const getScopeElementsByProjectWithFilter = (
  projectId: string,
  filterSetOptions: Partial<IProjectFilterSetFields> = {}
) => {
  return axios.post<IScopeElement[]>(`/scope-elements-by-project/filter/${projectId}`, filterSetOptions);
};

type IScopeElementChangeRequest = IScopeElement & {
  insertType?: 'before' | 'after';
  targetScopeElementId?: string | null;
};

export const updateScopeElement = async (scopeElement: IScopeElementChangeRequest) => {
  return axios.put<IScopeElement>('/scope-element/create-update', {
    ...scopeElement,
  });
};

export const deleteScopeElement = (scopeElementIds: string[]) => {
  return axios.delete('/scope-element', { data: scopeElementIds });
};

export const bulkUpdateScopeElements = async (scopeElements: IScopeElement[]) => {
  try {
    return await axios.put<IScopeElement[]>('/scope-element/bulk-update', scopeElements);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 412) {
        throw new Error(ERROR_BULK_UPDATE_SCOPE_ELEMENTS, { cause: error });
      }
    }
    throw error;
  }
};

export const getScopeElementTypes = () => {
  return axios.get<IScopeElementType[]>('/scope-element-types');
};

export const indentScopeElement = (params: { ids: string[] }) => {
  const { ids } = params;
  return axios.post('/scope-element-indent', ids);
};

export const outdentScopeElement = (params: { ids: string[] }) => {
  const { ids } = params;
  return axios.post('/scope-element-outdent', ids);
};

export const moveScopeElementsUp = (params: { ids: string[] }) => {
  const { ids } = params;
  return axios.post('/scope-element-move-up', ids);
};

export const moveScopeElementsDown = (params: { ids: string[] }) => {
  const { ids } = params;
  return axios.post('/scope-element-move-down', ids);
};
