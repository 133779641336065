import { useNavigation } from 'react-router';
import CircularProgressIndicator from './CircularProgressIndicator';
import clsx from 'clsx';

export function RouteLoaderOverlay() {
  const navigation = useNavigation();
  const isLoading = navigation.state === 'loading';

  return (
    <div
      className={clsx(
        'fixed inset-0 z-50 flex size-full items-center justify-center bg-white/75 p-8 transition-opacity',
        {
          'pointer-events-none opacity-0': !isLoading,
          'opacity-100': isLoading,
        }
      )}
    >
      <CircularProgressIndicator />
    </div>
  );
}
