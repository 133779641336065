import {
  CURRENT_UI_VIEW_STATE_VERSION,
  defaultProjectsOverviewColumnGroupShowConfig,
  shortISODateFormat,
} from '@app/config';
import type {
  IBudgetFilterSetFields,
  IGridColDefMeta,
  IPrioritizeFilterSetFields,
  IProjectFilterSetFields,
  IProjectStatusReportScopeElementFilters,
  IProjectStatusReportScopeElementItemFilters,
  ITimelineZoomLevel,
  IToggleableProjectGridSectionId,
  IUiViewState,
  IUserPreferences,
  IWorkloadFilterSetFields,
} from '@app/types';
import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { add, endOfMonth, format, startOfMonth, sub } from 'date-fns';
import { getDefaultCostDistributionSettings } from '../../core/utilities/costDistribution/getDefaultCostDistributionDateRange';
import type { IState } from '../store';
import { clearBudgetFilters } from './clearBudgetFilters';
import { clearPrioritizeLandingFilters } from './clearPrioritizeLandingFilters';
import { clearProjectsOverviewFilters } from './clearProjectsOverviewFilters';
import { clearWorkloadFilters } from './clearWorkloadFilters';
import { setBudgetFilters } from './setBudgetFilters';
import { setInitialUserPreferences } from './setInitialUserPreferences';
import { setPrioritizeLandingFilters } from './setPrioritizeLandingFilters';
import { setProjectStatusReportOverviewGridUIState } from './setProjectStatusReportOverviewGridUIState';
import { setProjectStatusReportScopeElementFilters } from './setProjectStatusReportScopeElementFilters';
import { setProjectStatusReportScopeElementItemFilters } from './setProjectStatusReportScopeElementItemFilters';
import { setProjectStatusReportScopeElementItemPeriod } from './setProjectStatusReportScopeElementItemPeriod';
import { setProjectStatusReportScopeElementPeriod } from './setProjectStatusReportScopeElementPeriod';
import { setProjectsOverviewAppliedFilterSet } from './setProjectsOverviewAppliedFilterSet';
import { setProjectsOverviewColumnGroupShowConfig } from './setProjectsOverviewColumnGroupShowConfig';
import { setProjectsOverviewCostDistributionSettings } from './setProjectsOverviewCostDistributionSettings';
import { setProjectsOverviewFilters } from './setProjectsOverviewFilters';
import { setProjectsOverviewGridUIState } from './setProjectsOverviewGridUIState';
import { setWorkloadFilters } from './setWorkloadFilters';
import { setWorkloadTimelineSettings } from './setWorkloadTimelineSettings';
import { toggleShowBaselineInWorkload } from './toggleShowBaselineInWorkload';
import { toggleShowScheduleInWorkload } from './toggleShowScheduleInWorkload';

const defaultCostDistributionSettings = getDefaultCostDistributionSettings('Vendor');

const defaultWorkloadZoomLevel: ITimelineZoomLevel = 'day';
const defaultRememberWorkloadPeriod = false;
export const defaultWorkloadStaticRangeId = null;
export const defaultWorkloadStartDate = format(startOfMonth(sub(new Date(), { months: 2 })), shortISODateFormat);
export const defaultWorkloadEndDate = format(endOfMonth(add(new Date(), { months: 6 })), shortISODateFormat);

const defaultProjectStatusReportScopeElementRememberPeriod = false;
export const defaultProjectStatusReportScopeElementStaticRangeId = null;
export const defaultProjectStatusReportScopeElementStartDate = format(new Date(), shortISODateFormat);
export const defaultProjectStatusReportScopeElementEndDate = format(add(new Date(), { days: 30 }), shortISODateFormat);

const defaultProjectStatusReportScopeElementItemRememberPeriod = false;
export const defaultProjectStatusReportScopeElementItemStaticRangeId = null;
export const defaultProjectStatusReportScopeElementItemStartDate = format(new Date(), shortISODateFormat);
export const defaultProjectStatusReportScopeElementItemEndDate = format(
  add(new Date(), { days: 30 }),
  shortISODateFormat
);

const defaultBudgetStartDate = format(startOfMonth(new Date()), shortISODateFormat);
const defaultBudgetEndDate = format(endOfMonth(add(new Date(), { years: 1 })), shortISODateFormat);

const initialWorkloadFilterSetFields: IWorkloadFilterSetFields = {
  startDate: defaultWorkloadStartDate,
  endDate: defaultWorkloadEndDate,
  programIds: [],
  projectIds: [],
  projectStatusIds: [],
  resourceIds: [],
  resourceCategoryIds: [],
  scopeElementStatusIds: [],
  scopeElementTagIds: [],
  scopeElementTypeIds: [],
};

const initialProjectsOverviewFilterSetFields: IProjectFilterSetFields = {
  projectStatusIds: [],
  projectSubStatusIds: [],
  officeIds: [],
  programIds: [],
  projectManagerIds: [],
  projectIds: [],
  scopeElementStatusIds: [],
  scopeElementTagIds: [],
  scopeElementTypeIds: [],
  pursuitProbability: false,
};

const initialPrioritizeLandingFilterSetFields: IPrioritizeFilterSetFields = {
  programIds: [],
  employeeIds: [],
  projectIds: [],
  scorecardFactorTemplateIds: [],
};

const initialBudgetFilterSetFields: IBudgetFilterSetFields = {
  programIds: [],
  projectManagerIds: [],
  projectIds: [],
  projectStatusIds: [],
  projectSubStatusIds: [],
  startDate: defaultBudgetStartDate,
  endDate: defaultBudgetEndDate,
  zoom: 'year',
};

const initialProjectStatusReportScopeElementFilters: IProjectStatusReportScopeElementFilters = {
  scheduledStartDate: defaultProjectStatusReportScopeElementStartDate,
  scheduledEndDate: defaultProjectStatusReportScopeElementEndDate,
  includeScopeElementsCompleted: false,
  includeScopeElementsWithoutDate: false,
};

const initialProjectStatusReportScopeElementItemFilters: IProjectStatusReportScopeElementItemFilters = {
  scheduledStartDate: defaultProjectStatusReportScopeElementItemStartDate,
  scheduledEndDate: defaultProjectStatusReportScopeElementItemEndDate,
  includeToDosCompleted: false,
  includeToDosWithoutDate: false,
};

type IUiViewSliceState = IUiViewState & {
  userPreferences: IUserPreferences | null;

  /**
   * Transient values are not persisted to server storage
   */
  transientValues: {
    projectsOverview: {
      filterSetVersion: number | null;
    };
    projectStatusReport: {
      scopeElements: {
        filterSetVersion: number | null;
      };
      scopeElementItems: {
        filterSetVersion: number | null;
      };
    };
  };
};

export const getInitialUiViewState = (): IUiViewSliceState => ({
  userPreferences: null as IUserPreferences | null,
  workload: {
    showBaseline: false,
    showSchedule: false,
    filterSetFields: { ...initialWorkloadFilterSetFields },
    zoomLevel: defaultWorkloadZoomLevel,
    rememberPeriod: defaultRememberWorkloadPeriod,
    staticRangeId: defaultWorkloadStaticRangeId,
  },
  budget: {
    filterSetFields: { ...initialBudgetFilterSetFields },
  },
  prioritizeLanding: {
    filterSetFields: { ...initialPrioritizeLandingFilterSetFields },
  },
  projectsOverview: {
    gridUIState: {
      columnState: [],
      columnGroupState: [],
      filterModel: {},
    },
    columnGroupShowConfig: defaultProjectsOverviewColumnGroupShowConfig,
    filterSetFields: { ...initialProjectsOverviewFilterSetFields },
    costDistributionZoomLevel: defaultCostDistributionSettings.zoomLevel,
    costDistributionStartDate: defaultCostDistributionSettings.startDate,
    costDistributionEndDate: defaultCostDistributionSettings.endDate,
    rememberCostDistributionPeriod: defaultCostDistributionSettings.rememberPeriod,
    costDistributionStaticRangeId: defaultCostDistributionSettings.staticRangeId,
    appliedFilterSet: null,
  },
  projectStatusReport: {
    scopeElements: {
      gridUIState: {
        columnState: [],
        columnGroupState: [],
        filterModel: {},
      },
      filterSetFields: initialProjectStatusReportScopeElementFilters,
      rememberPeriod: defaultProjectStatusReportScopeElementRememberPeriod,
      staticRangeId: defaultProjectStatusReportScopeElementStaticRangeId,
    },
    scopeElementItems: {
      filterSetFields: initialProjectStatusReportScopeElementItemFilters,
      rememberPeriod: defaultProjectStatusReportScopeElementItemRememberPeriod,
      staticRangeId: defaultProjectStatusReportScopeElementItemStaticRangeId,
    },
  },
  transientValues: {
    projectsOverview: {
      filterSetVersion: null,
    },
    projectStatusReport: {
      scopeElements: {
        filterSetVersion: null,
      },
      scopeElementItems: {
        filterSetVersion: null,
      },
    },
  },
  version: CURRENT_UI_VIEW_STATE_VERSION,
});

const slice = createSlice({
  name: 'uiView',
  initialState: getInitialUiViewState(),
  reducers: {
    setUIViewState(state, action: PayloadAction<IUiViewState>) {
      return { ...state, ...action.payload };
    },
    setUserPreferences(state, action: PayloadAction<IUserPreferences | null>) {
      state.userPreferences = action.payload;
    },

    clearProjectsOverviewFiltersSetVersion(state) {
      state.transientValues.projectsOverview.filterSetVersion = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toggleShowBaselineInWorkload.pending, (state) => {
      state.workload.showBaseline = !state.workload.showBaseline;
    });
    builder.addCase(toggleShowScheduleInWorkload.pending, (state) => {
      state.workload.showSchedule = !state.workload.showSchedule;
    });

    builder.addCase(setInitialUserPreferences.pending, (state, action) => {
      state.projectsOverview.filterSetFields.projectStatusIds = action.meta.arg.projectStatusIds;
      state.projectsOverview.filterSetFields.projectSubStatusIds = action.meta.arg.projectSubStatusIds;

      if (action.meta.arg.tenantTypeName === 'Owner') {
        state.projectsOverview.costDistributionZoomLevel = 'year';
      }

      state.transientValues.projectsOverview.filterSetVersion = Date.now();
    });

    builder.addCase(setProjectsOverviewFilters.pending, (state, action) => {
      state.projectsOverview.filterSetFields = {
        ...state.projectsOverview.filterSetFields,
        ...action.meta.arg.changes,
      };
      if (typeof action.meta.arg.appliedFilterSet !== 'undefined') {
        state.projectsOverview.appliedFilterSet = action.meta.arg.appliedFilterSet;
      }
      state.transientValues.projectsOverview.filterSetVersion = Date.now();
    });

    builder.addCase(clearProjectsOverviewFilters.pending, (state) => {
      state.projectsOverview.filterSetFields = { ...initialProjectsOverviewFilterSetFields };
      state.projectsOverview.appliedFilterSet = null;
      state.transientValues.projectsOverview.filterSetVersion = Date.now();
    });

    builder.addCase(setProjectsOverviewAppliedFilterSet.pending, (state, action) => {
      state.projectsOverview.appliedFilterSet = action.meta.arg.filterSet;
      if (action.meta.arg.updateFilters) {
        state.projectsOverview.filterSetFields = {
          ...state.projectsOverview.filterSetFields,
          ...action.meta.arg.filterSet,
        };
        state.transientValues.projectsOverview.filterSetVersion = Date.now();
      }
    });

    builder.addCase(setProjectsOverviewGridUIState.pending, (state, action) => {
      state.projectsOverview.gridUIState = action.meta.arg.gridUIState;
    });
    builder.addCase(setProjectsOverviewColumnGroupShowConfig.pending, (state, action) => {
      state.projectsOverview.columnGroupShowConfig = action.meta.arg.columnGroupShowConfig;
    });
    builder.addCase(setWorkloadFilters.pending, (state, action) => {
      state.workload.filterSetFields = { ...state.workload.filterSetFields, ...action.meta.arg };
    });
    builder.addCase(clearWorkloadFilters.pending, (state) => {
      state.workload.filterSetFields = {
        ...initialWorkloadFilterSetFields,
        startDate: state.workload.filterSetFields.startDate,
        endDate: state.workload.filterSetFields.endDate,
      };
    });
    builder.addCase(setPrioritizeLandingFilters.pending, (state, action) => {
      state.prioritizeLanding.filterSetFields = { ...state.prioritizeLanding.filterSetFields, ...action.meta.arg };
    });
    builder.addCase(clearPrioritizeLandingFilters.pending, (state) => {
      state.prioritizeLanding.filterSetFields = structuredClone(initialPrioritizeLandingFilterSetFields);
    });

    builder.addCase(setBudgetFilters.pending, (state, action) => {
      state.budget.filterSetFields = { ...state.budget.filterSetFields, ...action.meta.arg };
    });
    builder.addCase(clearBudgetFilters.pending, (state) => {
      state.budget.filterSetFields = {
        ...initialBudgetFilterSetFields,
        startDate: state.budget.filterSetFields.startDate,
        endDate: state.budget.filterSetFields.endDate,
        zoom: state.budget.filterSetFields.zoom,
      };
    });

    builder.addCase(setProjectsOverviewCostDistributionSettings.pending, (state, action) => {
      const {
        costDistributionZoomLevel,
        costDistributionStartDate,
        costDistributionEndDate,
        rememberCostDistributionPeriod,
        costDistributionStaticRangeId,
      } = action.meta.arg;

      if (costDistributionStartDate) {
        state.projectsOverview.costDistributionStartDate = format(costDistributionStartDate, shortISODateFormat);
      }
      if (costDistributionEndDate) {
        state.projectsOverview.costDistributionEndDate = format(costDistributionEndDate, shortISODateFormat);
      }
      if (typeof rememberCostDistributionPeriod === 'boolean') {
        state.projectsOverview.rememberCostDistributionPeriod = rememberCostDistributionPeriod;
      }
      if (costDistributionZoomLevel) {
        state.projectsOverview.costDistributionZoomLevel = costDistributionZoomLevel;
      }
      if (typeof costDistributionStaticRangeId !== 'undefined') {
        state.projectsOverview.costDistributionStaticRangeId = costDistributionStaticRangeId;
      }
    });

    builder.addCase(setWorkloadTimelineSettings.pending, (state, action) => {
      const { zoomLevel, startDate, endDate, rememberPeriod, staticRangeId } = action.meta.arg;

      if (startDate) {
        state.workload.filterSetFields.startDate = format(startDate, shortISODateFormat);
      }
      if (endDate) {
        state.workload.filterSetFields.endDate = format(endDate, shortISODateFormat);
      }
      if (typeof rememberPeriod === 'boolean') {
        state.workload.rememberPeriod = rememberPeriod;
      }
      if (zoomLevel) {
        state.workload.zoomLevel = zoomLevel;
      }
      if (typeof staticRangeId !== 'undefined') {
        state.workload.staticRangeId = staticRangeId;
      }
    });

    builder.addCase(setProjectStatusReportOverviewGridUIState.pending, (state, action) => {
      state.projectStatusReport.scopeElements.gridUIState = action.meta.arg.gridUIState;
    });

    builder.addCase(setProjectStatusReportScopeElementPeriod.pending, (state, action) => {
      const { startDate, endDate, rememberPeriod, staticRangeId } = action.meta.arg;

      if (startDate) {
        state.projectStatusReport.scopeElements.filterSetFields.scheduledStartDate = format(
          startDate,
          shortISODateFormat
        );
      }
      if (endDate) {
        state.projectStatusReport.scopeElements.filterSetFields.scheduledEndDate = format(endDate, shortISODateFormat);
      }
      if (typeof rememberPeriod === 'boolean') {
        state.projectStatusReport.scopeElements.rememberPeriod = rememberPeriod;
      }
      if (typeof staticRangeId !== 'undefined') {
        state.projectStatusReport.scopeElements.staticRangeId = staticRangeId;
      }
    });

    builder.addCase(setProjectStatusReportScopeElementFilters.pending, (state, action) => {
      state.projectStatusReport.scopeElements.filterSetFields = {
        ...state.projectStatusReport.scopeElements.filterSetFields,
        ...action.meta.arg.changes,
      };

      state.transientValues.projectStatusReport.scopeElements.filterSetVersion = Date.now();
    });

    builder.addCase(setProjectStatusReportScopeElementItemPeriod.pending, (state, action) => {
      const { startDate, endDate, rememberPeriod, staticRangeId } = action.meta.arg;

      if (startDate) {
        state.projectStatusReport.scopeElementItems.filterSetFields.scheduledStartDate = format(
          startDate,
          shortISODateFormat
        );
      }
      if (endDate) {
        state.projectStatusReport.scopeElementItems.filterSetFields.scheduledEndDate = format(
          endDate,
          shortISODateFormat
        );
      }
      if (typeof rememberPeriod === 'boolean') {
        state.projectStatusReport.scopeElementItems.rememberPeriod = rememberPeriod;
      }
      if (typeof staticRangeId !== 'undefined') {
        state.projectStatusReport.scopeElementItems.staticRangeId = staticRangeId;
      }
    });

    builder.addCase(setProjectStatusReportScopeElementItemFilters.pending, (state, action) => {
      state.projectStatusReport.scopeElementItems.filterSetFields = {
        ...state.projectStatusReport.scopeElementItems.filterSetFields,
        ...action.meta.arg.changes,
      };

      state.transientValues.projectStatusReport.scopeElementItems.filterSetVersion = Date.now();
    });
  },
});

export const { setUIViewState, setUserPreferences, clearProjectsOverviewFiltersSetVersion } = slice.actions;

export const selectProjectsOverviewVisibleSections = createSelector(
  [(state: IState) => state.uiView, (_state: IState, columnsMeta: IGridColDefMeta[]) => columnsMeta],
  (state, columnsMeta) => {
    const columnState = state.projectsOverview.gridUIState.columnState;
    if (!columnState) {
      return [];
    }
    const outset = columnState.reduce((acc, cur) => {
      if (cur.hide) {
        return acc;
      }

      const sectionId = columnsMeta.find((def) => def.colId === cur.colId)?.sectionId;
      if (sectionId) {
        acc.add(sectionId as IToggleableProjectGridSectionId);
      }

      return acc;
    }, new Set() as Set<IToggleableProjectGridSectionId>);

    return Array.from(outset);
  }
);

export default slice.reducer;
