import type { IProjectGridRowAggregateData } from '@app/types';

export function getEmptyProjectGridRowAggregateData(): IProjectGridRowAggregateData {
  return {
    budget: {
      total: 0,
      subcontract: 0,
      personnel: 0,
      equipment: 0,
      expense: 0,
    },
    actual: {
      total: 0,
      subcontract: 0,
      personnel: 0,
      equipment: 0,
      expense: 0,
    },
    remaining: {
      total: 0,
      subcontract: 0,
      personnel: 0,
      equipment: 0,
      expense: 0,
    },
    forecast: {
      total: 0,
      subcontract: 0,
      personnel: 0,
      equipment: 0,
      expense: 0,
    },
    totalCost: 0,
  };
}
