import type { IOptionType, ITimelineZoomLevel } from '@app/types';
import { add, endOfDay, endOfYear, startOfDay, startOfMonth, startOfYear, sub } from 'date-fns';
import type { IStaticRangeConfig } from '../core/utilities/createDateRangeStaticRanges';

export const workloadZoomLevelOptions: IOptionType<ITimelineZoomLevel>[] = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

const today = startOfDay(new Date());
export const workloadStaticRangeConfig: IStaticRangeConfig[] = [
  {
    id: 'workload-default',
    label: 'Default',
    startDate: startOfMonth(sub(today, { months: 2 })),
    endDate: endOfDay(add(today, { months: 6 })),
  },
  { id: 'next-3-months', label: 'Next 3 months', startDate: today, endDate: endOfDay(add(today, { months: 3 })) },
  { id: 'current-year', label: 'Current Year', startDate: startOfYear(today), endDate: endOfYear(today) },
  {
    id: 'last-year',
    label: 'Last Year',
    startDate: startOfYear(sub(today, { years: 1 })),
    endDate: endOfYear(sub(today, { years: 1 })),
  },
];

export const defaultWorkloadZoomLevel: ITimelineZoomLevel = 'month';
