import { DEFAULT_SCHEDULE_SETTINGS } from '../../config';
import type { IProject, IProjectGridRow } from '../../types';

interface IConvertDurationToDays {
  projectGridRow: IProjectGridRow;
  project: IProject;
}
function convertDurationToDays(props: IConvertDurationToDays): number {
  const {
    projectGridRow: { durationUnit, durationValue },
    project: { hoursPerDay },
  } = props;
  if (!durationUnit || !durationValue) {
    return 0;
  }
  if (durationUnit === 'day') {
    return durationValue;
  }

  const durationInHours = durationUnit === 'minute' ? durationValue / 60 : durationValue;
  return durationInHours / (hoursPerDay ?? DEFAULT_SCHEDULE_SETTINGS.HOURS_PER_DAY);
}

// Project total progress is based on these articles
//
// https://www.syncfusion.com/forums/147764/empty-progress-without-dates
// https://advisicon.com/percent-complete-microsoft-project/
//
// > In Gantt, we are calculated parent progress based on MS Project behavior.
// > Progress of parent task was calculated based on all scheduled child task's
// > progress and duration value. In Gantt, scheduled tasks are task which has
// > value for start date and end date or start date and duration or end date
// > and duration. Parent task progress was calculated as sum of all scheduled
// > task's progress divided by sum of all scheduled tasks duration value.
export function calculateWorkPercentComplete(projectGridRows: IProjectGridRow[], project: IProject): number {
  const { taskDurationValue, completedDuration } = projectGridRows.reduce(
    (acc, projectGridRow) => {
      const taskDurationValue = convertDurationToDays({ projectGridRow, project });
      const percentComplete = projectGridRow.percentComplete ?? 0;
      if (taskDurationValue > 0) {
        return {
          taskDurationValue: acc.taskDurationValue + taskDurationValue,
          completedDuration: acc.completedDuration + (taskDurationValue * percentComplete) / 100,
        };
      }

      return acc;
    },
    { taskDurationValue: 0, completedDuration: 0 }
  );

  return taskDurationValue ? Math.floor((completedDuration / taskDurationValue) * 100) : 0;
}
