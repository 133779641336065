import { useLogoutAction } from '../../hooks/useLogoutAction';
import { ErrorLayout } from './ErrorLayout';

export function AppErrorInactiveTenant() {
  const logoutAction = useLogoutAction();

  return (
    <ErrorLayout title="Inactive Company">
      <p className="text-2xl font-bold ">This company account is currently inactive.</p>

      <p>Please contact the account owner.</p>

      <p>
        <button type="button" className="btn-green btn-lg" onClick={logoutAction}>
          Sign Out
        </button>
      </p>
    </ErrorLayout>
  );
}
