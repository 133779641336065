import invariant from '@app/core/utilities/invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateOneProjectGridRow } from '../../projectGridRows';
import { selectAllProjectGridRowDescendants } from '../../projectGridRows/selectors/selectAllProjectGridRowDescendants';
import type { IState } from '../../store';
import { queueFundDataUpdate } from '../queueProjectFundUpdate';
import { recalculateFundDataAmounts } from '../recalculateFundDataAmounts';
import { updateFundDataTree } from '../updateFundDataTree';
import { changeFundDataToInherent } from './changeFundDataToInherent';
import { combineDescendantFundData } from './combineDescendantFundData';
import { getNextFundData } from './getNextFundData';
import { hasRowChanged } from './hasRowChanged';
import { isParentFundDataMethod } from './isParentFundDataMethod';
import { recalculateValues } from './recalculateValues';
import type { IUpdateProjectGridRowFundDataProps } from './thunkTypes';

/**
 * When we change the fund method on a row, the following things are to be done:
 * - Update all fund data in the same row to be either inherent or parent
 * - If the new method is a parent method, we need to:
 *   - get the values of all children and sum them up
 *   - do the same for all other funds in the same row
 * - If there is no method, we remove this fund data from the row
 * - Update the existing fund data or create a new one
 */
export const updateProjectGridRowFundData = createAsyncThunk<
  void,
  IUpdateProjectGridRowFundDataProps,
  { state: IState }
>('projectGridRows/updateProjectGridRowFundData', async (props, { getState, dispatch }) => {
  const { projectGridRowId, fund, field, value } = props;

  const projectGridRow = getState().projectGridRows.entities[projectGridRowId];
  invariant(projectGridRow);

  // Currently updated fund data.
  const existingFundData = projectGridRow.fundData.find((fundData) => fundData.fundId === fund.id);

  const nextFundData = getNextFundData({ ...props, projectGridRow, existingFundData });

  // Remove fund data when fund method is cleared
  if (field === 'fundMethod' && !value && existingFundData) {
    dispatch(
      updateOneProjectGridRow({
        id: projectGridRow.id,
        changes: { fundData: projectGridRow.fundData.filter((fd) => fd.id !== existingFundData.id) },
      })
    );
    dispatch(queueFundDataUpdate({ data: existingFundData, type: 'delete', transactionId: 'delete' }));
  } else if (field === 'fundMethod') {
    const descendants = selectAllProjectGridRowDescendants(getState(), projectGridRowId);

    // The new method is a parent method, we need to sum up all the values from the children
    if (isParentFundDataMethod(field, value)) {
      const nextProjectGridRowFundData = projectGridRow.fundData.map((cur) => {
        const fd = cur.id === nextFundData.id ? nextFundData : cur;

        const next = combineDescendantFundData(fd, descendants, projectGridRow);

        if (hasRowChanged(cur, next)) {
          dispatch(
            queueFundDataUpdate({ data: next, type: 'update', transactionId: 'update after parent method change' })
          );
        }

        return next;
      });

      // if we don't have the fund data, we need to create a new one
      if (!existingFundData) {
        const combined = combineDescendantFundData(nextFundData, descendants, projectGridRow);
        dispatch(
          queueFundDataUpdate({ data: combined, type: 'create', transactionId: 'create after parent method change' })
        );
        nextProjectGridRowFundData.push(combined);
      }

      dispatch(
        updateOneProjectGridRow({
          id: projectGridRow.id,
          changes: {
            fundData: nextProjectGridRowFundData,
          },
        })
      );
      // We switched from parent method to inherent method,
      // update all of the fund data rows on the same row to use inherent method as well
    } else if (value) {
      const nextProjectGridRowFundData = projectGridRow.fundData.map((cur) => {
        const fd = cur.id === nextFundData.id ? nextFundData : cur;
        const next = changeFundDataToInherent(fd, projectGridRow);
        if (hasRowChanged(cur, next)) {
          dispatch(queueFundDataUpdate({ data: next, type: 'update', transactionId: 'update after method change' }));
        }

        return next;
      });

      if (!existingFundData) {
        // if we don't have the fund data, we need to create a new one
        const nextData = getNextFundData({ ...props, projectGridRow });
        nextData.inherent = true;
        const updatedFundData = recalculateValues({
          fundData: nextData,
          projectGridRow,
          field: 'fundMethod',
        });
        nextProjectGridRowFundData.push(updatedFundData);
        dispatch(
          queueFundDataUpdate({ data: updatedFundData, type: 'create', transactionId: 'create after method change' })
        );
      }

      dispatch(
        updateOneProjectGridRow({
          id: projectGridRow.id,
          changes: {
            fundData: nextProjectGridRowFundData,
          },
        })
      );
    }
  } else {
    const nextData = getNextFundData({ ...props, projectGridRow, existingFundData });
    if (existingFundData) {
      // update the existing object
      dispatch(
        updateOneProjectGridRow({
          id: projectGridRow.id,
          changes: {
            fundData: projectGridRow.fundData.map((fundData) =>
              fundData.id === existingFundData.id ? nextData : fundData
            ),
          },
        })
      );
      dispatch(queueFundDataUpdate({ data: nextData, type: 'update', transactionId: 'other update' }));
    } else {
      // add a new one
      dispatch(
        updateOneProjectGridRow({
          id: projectGridRow.id,
          changes: { fundData: [...projectGridRow.fundData, nextData] },
        })
      );
      dispatch(queueFundDataUpdate({ data: nextData, type: 'create', transactionId: 'other create' }));
    }
  }

  dispatch(updateFundDataTree({ projectGridRowId }));
  dispatch(recalculateFundDataAmounts({ projectGridRowId })); // remove this line
});
