import type { IProjectGridRow } from '../../types';

export function findPreviousElementIdForIndent(
  elIndex: number,
  siblings: IProjectGridRow[],
  excludeIds: string[]
): string | null {
  const prevIndex = elIndex - 1;
  const previousElement = siblings[prevIndex];
  if (!previousElement) {
    return null;
  }
  if (excludeIds.includes(previousElement.id)) {
    return findPreviousElementIdForIndent(prevIndex, siblings, excludeIds);
  }
  return previousElement.id;
}
