import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IPrioritizeFilterSetFields } from '@app/types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setPrioritizeLandingFilters = createAsyncThunk<
  void,
  Partial<IPrioritizeFilterSetFields>,
  { state: IState }
>('uiView/setPrioritizeLandingFilters', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
