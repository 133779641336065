import { clsx } from 'clsx';
import type * as React from 'react';
import { FaCalendarDay } from 'react-icons/fa';

interface FormDatePickerWrapperProps {
  children: React.ReactNode | string | null;
  className?: string;
}

export const FormDatePickerWrapper = ({ className, children }: FormDatePickerWrapperProps) => {
  return (
    <div className={clsx('relative', className)}>
      {children}
      <FaCalendarDay className="pointer-events-none absolute right-0 top-1/2 mr-2 size-4 -translate-y-1/2 text-gray-300" />
    </div>
  );
};
