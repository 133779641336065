import type {
  IAggregateScopeElementProjectResource,
  IScopeElementProjectResource,
  ISingleScopeElementProjectResource,
} from '@app/types';

export function isAggregateScopeElementProjectResource(
  resource: IScopeElementProjectResource
): resource is IAggregateScopeElementProjectResource {
  return !(resource as ISingleScopeElementProjectResource).projectResourceId;
}
