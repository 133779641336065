import { type QueryKey, queryOptions, useQuery } from '@tanstack/react-query';
import { getCurrentEmployee, getEmployee, getEmployees } from '../api/employee.api';
import { useBaseQuery } from '../hooks/useBaseQuery';
import type { IEmployee } from '../types';

export function getEmployeesQueryKey(): QueryKey {
  return ['employees'];
}
export function useEmployeesQuery() {
  return useBaseQuery(getEmployeesQueryKey(), getEmployees, {
    staleTime: 1000 * 60 * 5,
  });
}

export function getEmployeesQuery() {
  return queryOptions({
    queryKey: getEmployeesQueryKey(),
    queryFn: async () => (await getEmployees()).data,
    staleTime: 1000 * 60 * 5,
  });
}

export function getEmployeeQueryKey(personId: string) {
  return [...getEmployeesQueryKey(), personId];
}

export function getCurrentEmployeeQueryKey(): QueryKey {
  return ['current-employee'];
}

export function useEmployeeQuery(personId: string, options: { onSuccess?: (employee: IEmployee) => void } = {}) {
  return useQuery({
    queryKey: getEmployeeQueryKey(personId),
    queryFn: async () => {
      const { data } = await getEmployee(personId);
      options.onSuccess?.(data);
      return data;
    },
  });
}

export function useCurrentEmployeeQuery() {
  return useBaseQuery(getCurrentEmployeeQueryKey(), getCurrentEmployee);
}
