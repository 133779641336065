import { useAppSelector } from '@app/store';
import { tenantTypeSelectors } from '../store/tenantTypes';

export function useTenantTypeString(vendorString: string, ownerString: string) {
  const tenantTypeId = useAppSelector((state) => state.auth.user?.currentTenant?.tenantTypeId);
  const currentUserTenantType = useAppSelector((state) =>
    tenantTypeSelectors.selectById(state.tenantTypes, tenantTypeId ?? '')
  );

  if (currentUserTenantType?.name === 'Vendor') {
    return vendorString;
  }
  if (currentUserTenantType?.name === 'Owner') {
    return ownerString;
  }
  return '';
}
