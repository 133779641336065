import type {
  IProjectGridRow,
  ISubcontractBillValue,
  ISubcontractDetails,
  ISubcontractGridRow,
  ISubcontractLimitValue,
  ISubcontractScope,
  ISubcontractScopeRequest,
} from '@app/types';
import type { RowHeightParams } from 'ag-grid-community';
import { uniqBy } from 'lodash-es';

export const getRowHeight = (params: RowHeightParams<ISubcontractGridRow>) => {
  const childrenCount = params.node.allChildrenCount ?? 0;
  return childrenCount > 0 ? 35 : 25;
};

interface IMapScopeElementToRowParams {
  subcontractScope: ISubcontractScope;
  indexArr?: number[] | string;
  wbsFullLabel?: string;
  path?: string[];
  rowId?: number;
  children?: string[];
  dependencyString?: string;
  subcontract: ISubcontractDetails;
}

export const mapSubcontractScopeToSubcontractGridRow = (params: IMapScopeElementToRowParams): ISubcontractGridRow => {
  const { subcontractScope, path = [], dependencyString = '', rowId, children = [], subcontract } = params;

  const limitValues = subcontract.subcontractLimits.reduce((acc, cur) => {
    const values = (cur.limitValues ?? []).filter((item) => item.subcontractScopeElementId === subcontractScope.id);
    acc.push(
      ...values.map((value) => ({
        ...value,
        subcontractLimitId: cur.id,
        projectScopeId: subcontractScope.projectScopeId,
      }))
    );
    return acc;
  }, [] as ISubcontractLimitValue[]);

  const billValues = subcontract.subcontractBills.reduce((acc, cur) => {
    const values = (cur.billValues ?? []).filter((item) => item.subcontractScopeElementId === subcontractScope.id);
    acc.push(
      ...values.map((value) => ({
        ...value,
        subcontractBillId: cur.id,
        projectScopeId: subcontractScope.projectScopeId,
      }))
    );
    return acc;
  }, [] as ISubcontractBillValue[]);

  const out = {
    ...subcontractScope,
    wbsFullLabel: subcontractScope.wbsFullLabel,
    path,
    children,
    dependencyString,
    limitValues,
    billValues,
  };
  return rowId ? { ...out, rowId } : out;
};

interface IFlattenScopeElementChildrenParams {
  scopeElement: ISubcontractScope;
  indexArr: number[];
  parentPath?: string[];
  rowIdMap: Map<'currentIndex', number>;
  subcontract: ISubcontractDetails;
}

const flattenScopeElementChildren = (params: IFlattenScopeElementChildrenParams): ISubcontractGridRow[] => {
  const { scopeElement, indexArr, parentPath = [], rowIdMap, subcontract } = params;
  const rowId = (rowIdMap.get('currentIndex') ?? 0) + 1;
  rowIdMap.set('currentIndex', rowId);

  const path = [...parentPath, scopeElement.id];
  const children = (scopeElement.children ?? []).reduce((acc, cur, index) => {
    const childIndexArr = [...indexArr, index];
    return acc.concat(
      flattenScopeElementChildren({
        scopeElement: cur,
        indexArr: childIndexArr,
        parentPath: path,
        rowIdMap,
        subcontract,
      })
    );
  }, [] as ISubcontractGridRow[]);

  const nextScopeElement = mapSubcontractScopeToSubcontractGridRow({
    subcontractScope: scopeElement,
    indexArr,
    path,
    rowId,
    children: scopeElement.children?.map(({ id }) => id) ?? [],
    subcontract,
  });

  return [nextScopeElement, ...children];
};

export const mapSubcontractDetailsToSubcontractGridRows = (subcontract: ISubcontractDetails): ISubcontractGridRow[] => {
  const { subcontractScopes: scopes, id: subcontractId } = subcontract;
  const rowIdMap = new Map<'currentIndex', number>();
  const projects = uniqBy(
    scopes.map((scope) => scope.project),
    'id'
  );

  const rows = [] as ISubcontractGridRow[];

  for (const project of projects.filter(x => x != null)) {
    const rowId = (rowIdMap.get('currentIndex') ?? 0) + 1;
    rowIdMap.set('currentIndex', rowId);
    
    const projectScopes = scopes.filter((scope) => scope.project != null && scope.project.id === project.id);
    // Parent project row
    rows.push({
      id: project.id,
      rowId,
      wbsFullLabel: project.projectCode,
      name: project.name,
      children: projectScopes.map((scope) => scope.id),
      path: [project.id],
      forecastValue: 0,
      parentScopeId: null,
      hasChildren: false,
      wbsLabel: '',
      dependencyString: '',
      subcontractId,
      authorizedManualValue: 0,
      projectScopeId: '',
      isTotalRow: true,
      project,
      limitValues: [],
      billValues: [],
    });

    projectScopes.sort((a, b) => {
      const aNum = a.wbsFullLabel.split('.').map(Number.parseFloat);
      const bNum = b.wbsFullLabel.split('.').map(Number.parseFloat);
      const length = Math.max(aNum.length, bNum.length);

      for (let i = 0; i < length; i++) {
        if (!aNum[i]) {
          return -1;
        }
        if (!bNum[i]) {
          return 1;
        }
        if (aNum[i] < bNum[i]) {
          return -1;
        }
        if (aNum[i] > bNum[i]) {
          return 1;
        }
      }
      return 0;
    });

    rows.push(
      ...projectScopes.reduce((acc, scopeElement, index) => {
        return acc.concat(
          flattenScopeElementChildren({
            scopeElement,
            indexArr: [index],
            rowIdMap,
            parentPath: [project?.id ?? ''],
            subcontract,
          })
        );
      }, [] as ISubcontractGridRow[])
    );
  }
  return rows;
};

export const mapProjectGridRowToSubcontractScopeRequest = (
  selectedScope: IProjectGridRow,
  subcontractId: string,
  newForecastValue?: number
): ISubcontractScopeRequest => {
  const subcontractScope: ISubcontractScopeRequest = {
    id: undefined,
    subcontractId,
    project: {
      id: selectedScope.projectId,
      name: '',
      projectCode: '',
      projectStatusId: '',
    },
    name: selectedScope.name,
    parentScopeId: null,
    projectScopeId: selectedScope.id,
    authorizedManualValue: 0,
    forecastValue: newForecastValue ?? 0,
    children: [],
    hasChildren: false,
    wbsFullLabel: selectedScope.wbsFullLabel,
    wbsLabel: '',
  };

  return subcontractScope;
};

export const mapSubcontractGridRowToSubcontractScope = (row: ISubcontractGridRow): ISubcontractScope => {
  return { ...row, children: [] };
};
