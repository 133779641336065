import { createSlice, type PayloadAction, type SerializedError } from '@reduxjs/toolkit';
import { addDays, endOfDay, formatISO, startOfWeek } from 'date-fns';
import type { ITimesheetReportingFilterSetFields } from '@app/types';
import { addTimesheetEntry } from '../timesheetEntries/addTimesheetEntry';
import { removeTimesheetEntry } from '../timesheetEntries/removeTimesheetEntry';
import { unlockTimesheetEntry } from '../timesheetEntries/unlockTimesheetEntry';
import { updateTimesheetEntry } from '../timesheetEntries/updateTimesheetEntry';
import { deleteTimesheetRow } from '../timesheetRows/deleteTimesheetRow';
import { updateTimesheetRow } from '../timesheetRows/updateTimesheetRow';
import { queueTimesheetEntryTransaction } from './queueTimesheetEntryTransaction';

const initialFilterSetFields: ITimesheetReportingFilterSetFields = {
  reportStartDate: formatISO(startOfWeek(new Date())),
  reportEndDate: formatISO(endOfDay(addDays(startOfWeek(new Date()), 6))),
  employeeIds: [],
  projectIds: [],
  resourceCategoryIds: [],
  activityCodeIds: [],
  programIds: [],
  projectStatusIds: [],
};

interface ITimesheetEntryUpdateTransaction {
  id: string;
  type: 'update';
}

interface ITimesheetEntryRemoveTransaction {
  id: string;
  type: 'remove';
}

export type ITimesheetEntryTransaction = ITimesheetEntryUpdateTransaction | ITimesheetEntryRemoveTransaction;

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState: {
    filterSetFields: { ...initialFilterSetFields },
    selectedTimesheetEntryId: null as string | null,
    showParent: false,
    unrecoverableError: null as { message: string; error: SerializedError } | null,
    isBlockingActionInProgress: false,
    entryTransactionQueue: [] as ITimesheetEntryTransaction[],
    isProcessingTransactionQueue: false,
  },
  reducers: {
    setTimesheetFilters(state, action: PayloadAction<Partial<ITimesheetReportingFilterSetFields>>) {
      state.filterSetFields = { ...state.filterSetFields, ...action.payload };
    },
    clearTimesheetFilters(state) {
      state.filterSetFields = { ...initialFilterSetFields };
    },
    setSelectedTimesheetEntryId(state, action: PayloadAction<string>) {
      state.selectedTimesheetEntryId = action.payload;
    },
    clearSelectedTimesheetEntryId(state) {
      state.selectedTimesheetEntryId = null;
    },
    toggleShowParent(state) {
      state.showParent = !state.showParent;
    },
    addTimesheetEntryTransaction(state, action: PayloadAction<ITimesheetEntryTransaction>) {
      state.entryTransactionQueue.push(action.payload);
    },
    removeTimesheetEntryTransactionForEntryId(state, action: PayloadAction<string>) {
      state.entryTransactionQueue = state.entryTransactionQueue.filter((t) => t.id !== action.payload);
    },
    emptyTimesheetEntryTransactionQueue(state) {
      state.entryTransactionQueue = [];
    },
    setIsProcessingTimesheetQueue(state, action: PayloadAction<boolean>) {
      state.isProcessingTransactionQueue = action.payload;
    },
    clearTimesheetUnrecoverableError(state) {
      state.unrecoverableError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTimesheetEntry.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t update this timesheet entry', error };
    });
    builder.addCase(addTimesheetEntry.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t add this timesheet entry', error };
    });
    builder.addCase(removeTimesheetEntry.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t remove this timesheet entry', error };
    });
    builder.addCase(unlockTimesheetEntry.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t unlock this timesheet entry', error };
    });
    builder.addCase(deleteTimesheetRow.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t remove this timesheet row', error };
    });
    builder.addCase(updateTimesheetRow.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t update this timesheet row', error };
    });
    builder.addCase(queueTimesheetEntryTransaction.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t update server data', error };
      state.isProcessingTransactionQueue = false;
    });
  },
});

export const {
  setTimesheetFilters,
  clearTimesheetFilters,
  setSelectedTimesheetEntryId,
  clearSelectedTimesheetEntryId,
  toggleShowParent,
  addTimesheetEntryTransaction,
  removeTimesheetEntryTransactionForEntryId,
  emptyTimesheetEntryTransactionQueue,
  setIsProcessingTimesheetQueue,
  clearTimesheetUnrecoverableError,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
