import type { IProjectResource, IScopeElementProjectResource } from '@app/types';
import { isAggregateScopeElementProjectResource } from './isAggregateScopeElementProjectResource';

export function findScopeElementProjectResource(
  resources: IScopeElementProjectResource[],
  projectResource: IProjectResource
) {
  return resources.find((resource) => {
    if (isAggregateScopeElementProjectResource(resource)) {
      return (
        resource.resourceCategoryId === projectResource.resourceCategoryId &&
        resource.resourceTypeId === projectResource.resourceTypeId &&
        resource.unitOfMeasureId === projectResource.unitOfMeasureId &&
        resource.financeElementTypeId === projectResource.financeElementTypeId
      );
    }
    return (
      resource.projectResourceId === projectResource.id &&
      resource.financeElementTypeId === projectResource.financeElementTypeId
    );
  });
}
