import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IColumnGroupShowConfig } from '../../types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectsOverviewColumnGroupShowConfig = createAsyncThunk<
  void,
  { columnGroupShowConfig: IColumnGroupShowConfig },
  { state: IState }
>('uiView/setProjectsOverviewColumnGroupShowConfig', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
