import { createSelector } from '@reduxjs/toolkit';

import type { IState } from '@app/store';
import { financeElementTypeSelectors } from '../financeElementTypes';
import { resourceTypeSelectors } from '../resourceTypes';
import {
  FINANCE_ELEMENT_TYPE_ACTUAL,
  FINANCE_ELEMENT_TYPE_BUDGET,
  FINANCE_ELEMENT_TYPE_FORECAST,
  FINANCE_ELEMENT_TYPE_REMAINING,
  FINANCE_SECTION_INPUT_MODES,
  RESOURCE_TYPE_EQUIPMENT,
  RESOURCE_TYPE_EXPENSE,
  RESOURCE_TYPE_PERSONNEL,
  RESOURCE_TYPE_SUBCONTRACT,
} from '@app/config';
import invariant from '@app/core/utilities/invariant';

import type { IFinanceSectionInputModeByGuidConfig } from '@app/types';

function getElement<T extends { name: string }>(items: T[], needle: string): T {
  const el = items.find((item) => item.name === needle);
  invariant(el, `Element ${needle} not found`);
  return el;
}

export const selectFinanceSectionInputModesByGuid = createSelector(
  [
    (state: IState) => financeElementTypeSelectors.selectAll(state.financeElementTypes),
    (state: IState) => resourceTypeSelectors.selectAll(state.resourceTypes),
  ],
  (financeElementTypes, resourceTypes) => {
    const budget = getElement(financeElementTypes, FINANCE_ELEMENT_TYPE_BUDGET);
    const actual = getElement(financeElementTypes, FINANCE_ELEMENT_TYPE_ACTUAL);
    const remaining = getElement(financeElementTypes, FINANCE_ELEMENT_TYPE_REMAINING);
    const forecast = getElement(financeElementTypes, FINANCE_ELEMENT_TYPE_FORECAST);

    const personnel = getElement(resourceTypes, RESOURCE_TYPE_PERSONNEL);
    const equipment = getElement(resourceTypes, RESOURCE_TYPE_EQUIPMENT);
    const expense = getElement(resourceTypes, RESOURCE_TYPE_EXPENSE);
    const subcontract = getElement(resourceTypes, RESOURCE_TYPE_SUBCONTRACT);

    return {
      [budget.id]: {
        [personnel.id]: FINANCE_SECTION_INPUT_MODES.budget.personnel,
        [equipment.id]: FINANCE_SECTION_INPUT_MODES.budget.equipment,
        [expense.id]: FINANCE_SECTION_INPUT_MODES.budget.expense,
        [subcontract.id]: FINANCE_SECTION_INPUT_MODES.budget.subcontract,
      },
      [actual.id]: {
        [personnel.id]: FINANCE_SECTION_INPUT_MODES.actual.personnel,
        [equipment.id]: FINANCE_SECTION_INPUT_MODES.actual.equipment,
        [expense.id]: FINANCE_SECTION_INPUT_MODES.actual.expense,
        [subcontract.id]: FINANCE_SECTION_INPUT_MODES.actual.subcontract,
      },
      [remaining.id]: {
        [personnel.id]: FINANCE_SECTION_INPUT_MODES.remaining.personnel,
        [equipment.id]: FINANCE_SECTION_INPUT_MODES.remaining.equipment,
        [expense.id]: FINANCE_SECTION_INPUT_MODES.remaining.expense,
        [subcontract.id]: FINANCE_SECTION_INPUT_MODES.remaining.subcontract,
      },
      [forecast.id]: {
        [personnel.id]: FINANCE_SECTION_INPUT_MODES.forecast.personnel,
        [equipment.id]: FINANCE_SECTION_INPUT_MODES.forecast.equipment,
        [expense.id]: FINANCE_SECTION_INPUT_MODES.forecast.expense,
        [subcontract.id]: FINANCE_SECTION_INPUT_MODES.forecast.subcontract,
      },
    } as IFinanceSectionInputModeByGuidConfig;
  }
);
