import { useRollbar } from '@rollbar/react';
import axios from 'axios';
import { useCallback } from 'react';
import type { LogArgument } from 'rollbar';
import invariant from '@app/core/utilities/invariant';

function isLogArgument(arg: unknown): arg is LogArgument {
  return (
    ['string', 'function', 'object'].includes(typeof arg) ||
    arg instanceof Error ||
    arg instanceof Date ||
    Array.isArray(arg)
  );
}

export function useReportError() {
  const rollbar = useRollbar();

  return useCallback(
    (error: unknown, actionPayload?: unknown) => {
      invariant(isLogArgument(error), 'Invalid log argument');
      if (import.meta.env.MODE === 'development') {
        console.error(error); // eslint-disable-line no-console
      } else {
        const payload = axios.isAxiosError(error) ? error.request : actionPayload;
        rollbar.error(error, payload);
      }
    },
    [rollbar]
  );
}
