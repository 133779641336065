import type { IScopeElement, IScopeElementItem, IScopeElementStatus, IServerScopeElementItem } from '@app/types';
import { createEntityAdapter, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { serverScopeElementItemToScopeElementItem } from './serverScopeElementItemToScopeElementItem';

const adapter = createEntityAdapter<IScopeElementItem>();
export const scopeElementItemSelectors = adapter.getSelectors();

const initialState = adapter.getInitialState({
  currentElement: null as IScopeElement | null,
  isEditing: false,
  yellowAlertDays: 0,
  redAlertDays: 0,
  scopeElementStatuses: [] as IScopeElementStatus[],
});

const slice = createSlice({
  name: 'scopeElementItems',
  initialState,
  reducers: {
    setScopeElementItemsConfig(
      state,
      action: PayloadAction<
        Partial<{ scopeElementStatuses: IScopeElementStatus[]; redAlertDays: number; yellowAlertDays: number }>
      >
    ) {
      state.scopeElementStatuses = action.payload.scopeElementStatuses ?? state.scopeElementStatuses;
      state.redAlertDays = action.payload.redAlertDays ?? state.redAlertDays;
      state.yellowAlertDays = action.payload.yellowAlertDays ?? state.yellowAlertDays;
    },

    setScopeElementItemsDrawerCurrentElement(state, action: PayloadAction<IScopeElement>) {
      state.currentElement = action.payload;
    },
    clearScopeElementItemsDrawerCurrentElement(state) {
      state.currentElement = null;
    },
    setIsEditingInScopeElementItemsDrawer(state, action: PayloadAction<boolean>) {
      state.isEditing = action.payload;
    },

    setScopeElementItemsFromServerData(
      state,
      action: PayloadAction<{ serverItems: IServerScopeElementItem[]; scheduledEndDate?: string | null | undefined }>
    ) {
      const scheduledEndDate = action.payload.scheduledEndDate ?? state.currentElement?.scheduledEndDate;

      const scopeElementItems = action.payload.serverItems.map((serverItem) =>
        serverScopeElementItemToScopeElementItem({
          serverItem,
          redAlertDays: state.redAlertDays,
          yellowAlertDays: state.yellowAlertDays,
          scheduledEndDate,
          scopeElementStatuses: state.scopeElementStatuses,
        })
      );

      adapter.setAll(state, scopeElementItems);
    },
    addOneScopeElementItem: adapter.addOne,
    updateOneScopeElementItem: adapter.updateOne,
    setAllScopeElementItems: adapter.setAll,
    removeOneScopeElementItem: adapter.removeOne,
  },
});

export const {
  setScopeElementItemsDrawerCurrentElement,
  clearScopeElementItemsDrawerCurrentElement,
  setIsEditingInScopeElementItemsDrawer,
  setScopeElementItemsFromServerData,
  setScopeElementItemsConfig,
  updateOneScopeElementItem,
  addOneScopeElementItem,
  setAllScopeElementItems,
  removeOneScopeElementItem,
} = slice.actions;

export default slice.reducer;
