import { parseJSON } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FORMAT_DATE_SHORT } from '../../config';

export function formatServerDate(value: Date | string | null | undefined, formatString = FORMAT_DATE_SHORT) {
  if (!value) {
    return '';
  }

  const date = typeof value === 'string' ? parseJSON(`${value}Z`) : value;

  return formatInTimeZone(date, 'UTC', formatString);
}

export function parseServerDate(date: Date | string | null | undefined) {
  if (typeof date === 'string' && date.length > 0) {
    return date[date.length - 1].toUpperCase() === 'Z' ? parseJSON(date) : parseJSON(`${date}Z`);
  }
  if (date instanceof Date) {
    return date;
  }
  return null;
}
