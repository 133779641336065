import type { IStaticRangeConfig } from '@app/core/utilities/createDateRangeStaticRanges';
import { startOfDay, endOfDay, add } from 'date-fns';

export const PROJECT_STATUS_PURSUIT = 'Pursuit';
export const PROJECT_STATUS_ACTIVE = 'Active';
export const PROJECT_STATUS_INACTIVE = 'InActive';
export const PROJECT_STATUS_CLOSED = 'Closed';

const today = startOfDay(new Date());
export const projectStatusStaticRangeConfig: IStaticRangeConfig[] = [
  {
    id: '30-days',
    label: '30 days from today',
    startDate: today,
    endDate: endOfDay(add(today, { days: 30 })),
  },
  {
    id: '90-days',
    label: '90 days from today',
    startDate: today,
    endDate: endOfDay(add(today, { days: 90 })),
  },
  {
    id: '180-days',
    label: '180 days from today',
    startDate: today,
    endDate: endOfDay(add(today, { days: 180 })),
  },
];
