import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { TIMESHEET_DATE_FORMAT } from '../pages/timesheets/entry/config';
import type {
  IScopeElement,
  ITimesheetEntry,
  IProject,
  IResourceCategory,
  ITimesheetReportingFilterSetFields,
} from '../types';

type IServerTimesheetEntry = Omit<ITimesheetEntry, 'shortDate'>;

function processTimesheetEntriesResponse(response: IServerTimesheetEntry[]): ITimesheetEntry[] {
  return response.map((entry) => ({
    ...entry,
    shortDate: format(parseISO(entry.startDateTime), TIMESHEET_DATE_FORMAT),
  }));
}

export const getTimesheetForEmployee = async (personId: string, startDate: string, endDate: string) => {
  const response = await axios.get<IServerTimesheetEntry[]>(`/timesheet/${personId}/${startDate}/${endDate}`);
  return processTimesheetEntriesResponse(response.data);
};

export const getEmployeeProjects = (personId: string) => {
  return axios.get<IProject[]>(`/timesheet-projects/${personId}`);
};

export const getScopeElementsForTimesheet = (projectId: string, signal?: AbortSignal) => {
  return axios.get<IScopeElement[]>(`/scope-elements-by-project-timesheet/${projectId}`, { signal });
};

export const getNonBillableScopeElementsForTimesheet = (projectId: string, scopeElementId: string) => {
  return axios.get<IScopeElement[]>(`/non-billable-by-project-timesheet/${projectId}/${scopeElementId}`);
};

export const getResourceCategoriesForTimesheet = (personId: string, projectId: string, signal?: AbortSignal) => {
  return axios.get<IResourceCategory[]>(`/timesheet-resource-categories/${personId}/${projectId}`, { signal });
};

export const submitTimesheet = (personId: string) => {
  return axios.put(`/timesheet/bulk-submit/${personId}`);
};

export const bulkUpdateTimesheetEntries = (data: ITimesheetEntry[]) => {
  return axios.put('/timesheet/bulk-update', data);
};

export const bulkDeleteTimesheetEntries = (ids: string[]) => {
  return axios.put('/timesheet/bulk-delete', { ids });
};

type IFilteredTimesheetParams = ITimesheetReportingFilterSetFields;

export const getFilteredTimesheetEntries = async (params: IFilteredTimesheetParams) => {
  const response = await axios.post<IServerTimesheetEntry[]>('/timesheet/filter', params);
  return processTimesheetEntriesResponse(response.data);
};

export const unlockTimesheetEntryRequest = async (timesheetEntryId: string) => {
  return axios.put<ITimesheetEntry>(`/timesheet/remove-submit/${timesheetEntryId}`);
};

// Gets the date range of unsubmitted timesheet entries for the personId
export const getUnsubmittedEntrySummary = async (personId: string) => {
  // Not turning this into an interface bc it isn't used anywhere else yet...
  return axios.get<{ startDate: string; endDate: string; unsubmittedEntries: number }>(
    `/timesheet/unsubmitted-summary/${personId}`
  );
};
