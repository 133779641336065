import { selectAllProjectGridRowsByProjectId } from '../projectGridRows/selectors/selectAllProjectGridRowsByProjectId';
import { createAsyncThunk, type EntityState } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { deleteScopeElement } from '../../api/scopeElement.api';
import { awaitUntil } from '../../core/utilities/awaitUntil';
import type { IProject, IProjectGridRow } from '../../types';
import {
  adapter,
  recalculateProjectScopeElementTree,
  removeProjectGridRows,
  updateProjectGridRowsTreeVersion,
} from '../projectGridRows';
import type { IState } from '../store';
import { updateProjectGridRows } from './updateProjectGridRows';
import { updateFundDataTree } from './updateFundDataTree';

function getIdsOfRowDescendants(
  state: EntityState<IProjectGridRow, string>,
  row: IProjectGridRow,
  accChildrenIds = [] as string[]
): string[] {
  const { selectById } = adapter.getSelectors();
  return row.children.reduce((acc, cur) => {
    const child = selectById(state, cur);
    invariant(child, 'Child not found');
    acc.push(child.id);

    return getIdsOfRowDescendants(state, child, acc);
  }, accChildrenIds);
}

export const removeManyProjectGridRows = createAsyncThunk<
  void,
  { ids: string[]; project: IProject; transactionId?: string },
  { state: IState }
>('projectGridRows/removeManyProjectGridRows', async (params, { getState, dispatch }) => {
  await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

  const { project, ids, transactionId } = params;

  const { selectById } = adapter.getSelectors();

  // first find all parent ids of removed rows
  const updatedParentRowIds = ids.reduce((acc, id) => {
    const row = selectById(getState().projectGridRows, id);
    invariant(row, 'Row not found');

    // touch all parent elements
    if (row.parentScopeElementId) {
      const parent = selectById(getState().projectGridRows, row.parentScopeElementId);
      if (parent) {
        acc.push(parent.id);
      }
    }
    return acc;
  }, [] as string[]);

  // next find ids of all removed rows and their descendants
  const allRemovedRowIds = ids.reduce((acc, id) => {
    const row = selectById(getState().projectGridRows, id);
    invariant(row, 'Row not found');

    // find all descendants of the current scope element (including self)
    acc.push(id, ...getIdsOfRowDescendants(getState().projectGridRows, row));
    return acc;
  }, [] as string[]);

  // remove the rows, recalculating the scope element tree
  dispatch(removeProjectGridRows({ projectId: project.id, ids }));
  dispatch(recalculateProjectScopeElementTree({ projectId: project.id }));

  // perform the actual delete operation
  await deleteScopeElement(allRemovedRowIds);

  // find the updated parent rows
  const updatedParents = updatedParentRowIds.reduce((acc, cur) => {
    const row = selectById(getState().projectGridRows, cur);
    if (row) {
      acc.push(row);
    }
    return acc;
  }, [] as IProjectGridRow[]);

  const allProjectGridRows = selectAllProjectGridRowsByProjectId(getState(), project.id);

  // find all rows that have dependencies on the removed rows
  const updatedDependencyRows = allProjectGridRows.reduce((acc, cur) => {
    if (!cur.dependencies) {
      return acc;
    }

    const filteredDependencies = cur.dependencies.filter((d) => allRemovedRowIds.includes(d.scopeElementId) === false);

    // make sure to update the row only if the dependencies have changed
    if (filteredDependencies.length !== cur.dependencies.length) {
      acc.push({ ...cur, dependencies: filteredDependencies });
    }

    return acc;
  }, [] as IProjectGridRow[]);

  // merge the updated rows and remove any duplicates
  const updatedRows = [...updatedParents, ...updatedDependencyRows].reduce((acc, cur) => {
    if (acc.find((r) => r.id === cur.id) === undefined) {
      acc.push(cur);
    }
    return acc;
  }, [] as IProjectGridRow[]);

  dispatch(updateProjectGridRows({ projectGridRows: updatedRows, project, transactionId }));
  dispatch(updateProjectGridRowsTreeVersion({ projectId: project.id }));

  for (const row of updatedRows) {
    dispatch(updateFundDataTree({ projectGridRowId: row.id }));
  }
});
