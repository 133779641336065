import { Dialog } from '@headlessui/react';
import { clsx } from 'clsx';
import type * as React from 'react';
import Draggable from 'react-draggable';
import { FaArrowsAlt, FaTimes } from 'react-icons/fa';

interface IProps {
  /**
   * Whether the dialog is open or not.
   */
  open: boolean;
  /**
   * Callback to close the dialog.
   * @returns
   */
  onRequestClose: () => void;
  /**
   * Dialog title
   */
  title: string;
  /**
   * Dialog content
   */
  children: React.ReactNode;
  /**
   * Dialog size
   * @default 'md'
   */
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | 'full';
  /**
   * Whether the dialog is draggable or not.
   * @default false
   * @type {boolean}
   * @memberof IProps
   */
  draggable?: boolean;

  /**
   * Whether the dialog content should overflow-y or not
   * @default true
   */
  forceOverflow?: boolean;

  /**
   * zIndex class
   */
  zIndex?: 'z-dialog' | 'z-confirm-dialog';
  bodyClassName?: string;
}

interface IDraggableElementProps {
  style: React.CSSProperties;
  className: string;
  onMouseDown: () => void;
  onMouseUp: () => void;
  onTouchStart: () => void;
  onTouchEnd: () => void;
}

const draggableHandleClassName = 'draggable-handle';

const DialogContent = (props: IProps & Partial<IDraggableElementProps>) => {
  const {
    onRequestClose,
    title,
    children,
    size = 'md',
    draggable,
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    forceOverflow = true,
    bodyClassName = 'p-4',
  } = props;
  return (
    <div
      className={clsx(
        'relative z-10 mx-auto max-h-full w-full rounded-md bg-white',
        {
          'max-w-sm': size === 'sm',
          'max-w-md': size === 'md',
          'max-w-lg': size === 'lg',
          'max-w-xl': size === 'xl',
          'max-w-2xl': size === '2xl',
          'max-w-3xl': size === '3xl',
          'max-w-4xl': size === '4xl',
          'max-w-5xl': size === '5xl',
          'max-w-6xl': size === '6xl',
          'max-w-7xl': size === '7xl',
          'max-w-full': size === 'full',
          'overflow-y-auto': forceOverflow,
        },
        className
      )}
      style={style}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <Dialog.Title
        className={clsx(
          'border-b border-b-gray-100 px-4 py-3 text-xl font-bold text-slate-900',
          draggableHandleClassName
        )}
      >
        {title}
      </Dialog.Title>

      <div className={bodyClassName}>{children}</div>

      <div className="absolute right-0 top-0 flex w-20 items-center justify-end">
        {draggable && (
          <button
            type="button"
            className={clsx(
              draggableHandleClassName,
              'flex size-6 cursor-move items-center justify-center rounded-bl-md rounded-tr-md text-app-gray-dark focus:outline-none focus-visible:bg-app-blue-dark focus-visible:text-white'
            )}
            aria-label="Move dialog"
          >
            <FaArrowsAlt className="size-4" />
          </button>
        )}
        <button
          type="button"
          className="flex size-10 items-center justify-center rounded-bl-md rounded-tr-md focus:outline-none focus-visible:bg-app-blue-dark focus-visible:text-white"
          onClick={onRequestClose}
          aria-label="Close dialog"
        >
          <FaTimes className="size-4" />
        </button>
      </div>
    </div>
  );
};

export const AppDialog = (props: IProps) => {
  const { open, onRequestClose, draggable, zIndex = 'z-dialog' } = props;

  return (
    <Dialog open={open} onClose={onRequestClose} className={clsx('fixed inset-0', zIndex)}>
      <div className="flex h-dvh items-center justify-center p-8">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        {draggable && (
          <Draggable handle={`.${draggableHandleClassName}`}>
            <DialogContent {...props} />
          </Draggable>
        )}
        {!draggable && <DialogContent {...props} />}
      </div>
    </Dialog>
  );
};
