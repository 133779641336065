import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these paths in the state
        ignoredPaths: ['projectGridRows.updateQueue', 'projectGridRows.queueProjectGridRowsUpdate'],
      },
    }),
});

export type IState = ReturnType<typeof rootReducer>;
export type IDispatch = typeof store.dispatch;
