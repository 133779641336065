import type { IPeriodicProjectBudgetData } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { upsertManyBudgetDataItems } from '.';
import { bulkUpdateProjectBudgets } from '@app/api/projectBudget.api';
import type { IState } from '../store';

export const updateBudgetGridData = createAsyncThunk<void, IPeriodicProjectBudgetData[], { state: IState }>(
  'budgetGridData/updateBudgetGridDataItem',

  async (data, { dispatch }) => {
    dispatch(upsertManyBudgetDataItems(data));
    bulkUpdateProjectBudgets(data);
  }
);
