import { Helmet } from 'react-helmet';

import { SITE_TITLE } from '../../config';

interface IProps {
  title?: string;
}

export const SiteTitle = (props: IProps) => {
  const { title } = props;
  return <Helmet titleTemplate={`%s - ${SITE_TITLE}`} defaultTitle={SITE_TITLE} title={title} />;
};
