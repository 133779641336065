import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { updateScopeElement } from '@app/api/scopeElement.api';
import { awaitUntil } from '@app/core/utilities/awaitUntil';
import { mapScopeElementToProjectGridRow } from '@app/core/utilities/projectGrid/mapScopeElementToProjectGridRow';
import type { IProjectGridRow } from '@app/types';
import { projectGridRowsSelectors } from '../projectGridRows';
import type { IState } from '../store';
import { prepareScopeElementUpdate } from './prepareScopeElementUpdate';

export const addProjectGridRowBefore = createAsyncThunk<
  IProjectGridRow,
  { projectGridRow: IProjectGridRow; id: string },
  { state: IState }
>('projectGridRows/addProjectGridRowBefore', async (props, { getState }) => {
  const { id } = props;

  // get the updated version from state rather the one passed to the thunk
  const projectGridRow = projectGridRowsSelectors.selectById(getState().projectGridRows, props.projectGridRow.id);

  invariant(projectGridRow, 'projectGridRow not found');

  await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

  const { data } = await updateScopeElement({
    ...prepareScopeElementUpdate(projectGridRow),
    targetScopeElementId: id,
    insertType: 'before',
  });

  return mapScopeElementToProjectGridRow({
    scopeElement: data,
    indexArr: projectGridRow.wbsFullLabel,
    path: projectGridRow.path,
    children: [],
    fundData: projectGridRow.fundData,
  });
});
