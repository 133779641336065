import { ErrorLayout } from './ErrorLayout';

export function AppErrorUnauthorized() {
  return (
    <ErrorLayout title="No Access">
      <p className="text-2xl font-bold">This section requires additional permissions.</p>
      <p>Here is what you can do:</p>

      <ul className="list-disc space-y-4 pl-6">
        <li>Contact the account owner to check your user role in the company settings.</li>
        <li>
          <button className="link" onClick={() => window.history.go(-1)} type="button">
            Go to the previous page
          </button>
        </li>
        <li>
          <a href="/" className="link">
            Go to the home page
          </a>
        </li>
      </ul>
    </ErrorLayout>
  );
}
