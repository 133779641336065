import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { type InferType, lazy, mixed, object, string } from 'yup';
import { useConfirmDialog } from '@app/core/components/ConfirmDialog';
import {
  AddressFormPartial,
  FormActions,
  FormError,
  FormInput,
  FormLabel,
  FormPhoneInput,
  FormSelect,
  getAddressFormSchema,
} from '@app/core/components/form';

import { disabilityList, ethnicityList, genderList, raceList, veteranList } from '@app/config';
import type { IEmployee } from '@app/types';
import { AdminSectionTitle } from '@app/core/components/admin';

function createSchema(includeDiversityFields?: boolean) {
  return object({
    person: object({
      externalId: string().defined().nullable(),
      title: string().defined().nullable(),
      email: string().required('Enter email').email('Invalid email'),
      firstName: string().trim().required('Enter first name'),
      lastName: string().trim().required('Enter last name'),
      middleName: string().defined().nullable(),
      linkedinProfile: string().defined().nullable(),
      personalEmail: string().defined().email('Invalid email').nullable(),
      mobilePhone: string().defined().nullable(),
      homePhone: string().defined().nullable(),
    }).required(),
    address: getAddressFormSchema(),
    diversity: lazy(() => {
      if (includeDiversityFields) {
        return object({
          ethnicity: string().defined().nullable(),
          race: string().defined().nullable(),
          gender: string().defined().nullable(),
          disability: string().defined().nullable(),
          veteran: string().defined().nullable(),
        }).defined();
      }
      return mixed().nullable().defined().default(null);
    }),
  });
}

export type IEmployeeFormValues = InferType<ReturnType<typeof createSchema>>;

function renderOptionsList(list: Array<{ value: string; label: string }>, includeBlank = true) {
  const out = list.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

  if (includeBlank) {
    out.unshift(
      <option value="" key="-1">
        - select -
      </option>
    );
  }
  return out;
}

interface IProps {
  employee?: IEmployee;
  onSubmit: (data: IEmployeeFormValues) => Promise<void>;
  cancelUrl?: string;
  includeDiversityFields?: boolean;
  includeAdminDiversityInfoMessage?: boolean;
  protectProfileData?: boolean;
  submitLabel: string;
  autoComplete?: boolean;
  confirmCreate?: boolean;
}

export const EmployeeForm = (props: IProps) => {
  const {
    employee,
    onSubmit,
    cancelUrl,
    includeDiversityFields,
    includeAdminDiversityInfoMessage,
    submitLabel,
    protectProfileData,
    autoComplete,
    confirmCreate,
  } = props;

  const formSchema = useMemo(() => createSchema(includeDiversityFields), [includeDiversityFields]);

  const methods = useForm<IEmployeeFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: employee,
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = methods;

  const { openConfirmDialog } = useConfirmDialog();

  const handleSubmit = async (data: IEmployeeFormValues) => {
    if (confirmCreate) {
      openConfirmDialog({
        title: 'Confirm Adding a New User',
        message: 'Creating a new user will impact your total subscription price. Are you sure you want to continue?',
        onConfirm: () => onSubmit(data),
        confirmLabel: 'Yes, add a new user',
        confirmColor: 'green',
      });
      return;
    }
    onSubmit(data);
  };

  const isDiversityInfoComplete = employee?.diversity && Object.values(employee.diversity).every((v) => !!v);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className="mb-4 grid grid-cols-3 gap-8">
          <div>
            <FormLabel required htmlFor="firstName">
              First Name
            </FormLabel>
            <FormInput
              {...register('person.firstName')}
              id="firstName"
              autoComplete={autoComplete ? 'given-name' : 'off'}
            />
            <FormError errors={errors} name="person.firstName" />
          </div>
          <div>
            <FormLabel htmlFor="middleName">Middle Name</FormLabel>
            <FormInput
              {...register('person.middleName')}
              id="middleName"
              autoComplete={autoComplete ? 'additional-name' : 'off'}
            />
            <FormError errors={errors} name="person.middleName" />
          </div>
          <div>
            <FormLabel htmlFor="lastName" required>
              Last Name
            </FormLabel>
            <FormInput
              {...register('person.lastName')}
              id="lastName"
              autoComplete={autoComplete ? 'family-name' : 'off'}
            />
            <FormError errors={errors} name="person.lastName" />
          </div>
        </div>

        <div className="mb-4 grid grid-cols-3 gap-8">
          <div>
            <FormLabel htmlFor="externalId">Employee ID</FormLabel>
            <FormInput
              {...register('person.externalId')}
              id="externalId"
              readOnly={protectProfileData}
              autoComplete="off"
            />
            <FormError errors={errors} name="person.externalId" />
          </div>
          <div>
            <FormLabel htmlFor="title">Title</FormLabel>
            <FormInput
              {...register('person.title')}
              id="title"
              readOnly={protectProfileData}
              autoComplete={autoComplete ? 'organization-title' : 'off'}
            />
            <FormError errors={errors} name="person.title" />
          </div>
        </div>

        <div className="mb-4 grid grid-cols-2 gap-8">
          <div>
            <FormLabel required htmlFor="email">
              Email
            </FormLabel>
            <FormInput
              {...register('person.email')}
              id="email"
              readOnly={protectProfileData || !!employee}
              autoComplete={autoComplete ? 'email' : 'off'}
            />
            <FormError errors={errors} name="person.email" />
          </div>
          <div>
            <FormLabel htmlFor="mobilePhone">Mobile Phone</FormLabel>
            <FormPhoneInput
              {...register('person.mobilePhone')}
              id="mobilePhone"
              autoComplete={autoComplete ? 'tel' : 'off'}
            />
            <FormError errors={errors} name="person.mobilePhone" />
          </div>
        </div>
        <div className="mb-4 grid grid-cols-2 gap-8">
          <div>
            <FormLabel htmlFor="personalEmail">Personal Email</FormLabel>
            <FormInput {...register('person.personalEmail')} id="personalEmail" autoComplete="off" />
            <FormError errors={errors} name="person.personalEmail" />
          </div>
          <div>
            <FormLabel htmlFor="homePhone">Home Phone</FormLabel>
            <FormPhoneInput {...register('person.homePhone')} id="homePhone" autoComplete="off" />
            <FormError errors={errors} name="person.homePhone" />
          </div>
        </div>
        <div className="mb-8">
          <div>
            <FormLabel htmlFor="linkedinProfile">LinkedIn Profile</FormLabel>
            <FormInput {...register('person.linkedinProfile')} id="linkedinProfile" autoComplete="off" />
            <FormError errors={errors} name="person.linkedinProfile" />
          </div>
        </div>

        <AdminSectionTitle>Address</AdminSectionTitle>

        <AddressFormPartial fieldName="address" autoComplete={includeDiversityFields} wrapperClassName="mb-6" />

        {includeDiversityFields && (
          <>
            <AdminSectionTitle>Diversity</AdminSectionTitle>

            <div className="mb-8 grid grid-cols-3 gap-4">
              <div>
                <FormLabel htmlFor="diversityEthnicity">Ethnicity</FormLabel>
                <FormSelect {...register('diversity.ethnicity')} id="diversityEthnicity" autoComplete="off">
                  {renderOptionsList(ethnicityList)}
                </FormSelect>
                <FormError errors={errors} name="diversity.ethnicity" />
              </div>
              <div>
                <FormLabel htmlFor="diversityRace">Race</FormLabel>
                <FormSelect {...register('diversity.race')} id="diversityRace" autoComplete="off">
                  {renderOptionsList(raceList)}
                </FormSelect>
                <FormError errors={errors} name="diversity.race" />
              </div>

              <div>
                <FormLabel htmlFor="diversityGender">Gender</FormLabel>
                <FormSelect
                  {...register('diversity.gender')}
                  id="diversityGender"
                  autoComplete={autoComplete ? 'sex' : 'off'}
                >
                  {renderOptionsList(genderList)}
                </FormSelect>
                <FormError errors={errors} name="diversity.gender" />
              </div>

              <div>
                <FormLabel htmlFor="diversityDisability">Disability</FormLabel>
                <FormSelect {...register('diversity.disability')} id="diversityDisability" autoComplete="off">
                  {renderOptionsList(disabilityList)}
                </FormSelect>
                <FormError errors={errors} name="diversity.disability" />
              </div>

              <div>
                <FormLabel htmlFor="diversityVeteran">Veteran</FormLabel>
                <FormSelect {...register('diversity.veteran')} id="diversityVeteran" autoComplete="off">
                  {renderOptionsList(veteranList)}
                </FormSelect>
                <FormError errors={errors} name="diversity.veteran" />
              </div>
            </div>
          </>
        )}

        {includeAdminDiversityInfoMessage && (
          <>
            <AdminSectionTitle>Diversity</AdminSectionTitle>
            <p className="mb-4">
              Employee has {!isDiversityInfoComplete && <strong>NOT</strong>} completed their diversity information
            </p>
          </>
        )}

        <FormActions label={submitLabel} isSubmitting={isSubmitting} cancelUrl={cancelUrl} />
      </form>
    </FormProvider>
  );
};
