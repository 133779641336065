import type { IProjectGridRow } from '@app/types';
import { getProjectGridRowTotalCostsValue } from '../../core/utilities/projectGrid/getProjectGridRowTotalCostsValue';
import { getAllProjectGridRowDescendants } from '../projectGridRows/selectors/selectAllProjectGridRowDescendants';
import { store } from '../store';

export function getProjectGridRowTotalCostValueForFundCalculation(
  inherent: boolean | undefined,
  projectGridRow: IProjectGridRow
) {
  if (inherent) {
    return getProjectGridRowTotalCostsValue(projectGridRow);
  }

  const allRows = [
    ...getAllProjectGridRowDescendants(store.getState().projectGridRows.entities, projectGridRow.id),
    projectGridRow,
  ];

  return allRows.reduce((acc, cur) => acc + getProjectGridRowTotalCostsValue(cur), 0);
}
