import type { IScopeElementItem, IScopeElementStatus, IServerScopeElementItem } from '@app/types';
import { parseServerDate } from '@app/core/utilities/date';
import { isTodoAlertActive } from '@app/core/utilities/isTodoAlertActive';
import { isScopeElementStatusComplete } from './isScopeElementStatusComplete';

interface IProps {
  serverItem: IServerScopeElementItem;
  redAlertDays: number;
  yellowAlertDays: number;
  scheduledEndDate: string | null | undefined;
  scopeElementStatuses: IScopeElementStatus[];
}

export function serverScopeElementItemToScopeElementItem(props: IProps): IScopeElementItem {
  const { serverItem, redAlertDays, yellowAlertDays, scheduledEndDate, scopeElementStatuses } = props;

  const dueDateParsed = serverItem.dueDate ? parseServerDate(serverItem.dueDate) : null;
  const endDate = dueDateParsed ?? scheduledEndDate;

  const isComplete = isScopeElementStatusComplete(serverItem.scopeStatusId, scopeElementStatuses);
  const redAlert = isTodoAlertActive(endDate, redAlertDays);
  const yellowAlert = isTodoAlertActive(endDate, yellowAlertDays);

  return {
    ...serverItem,
    newItem: false,
    isComplete,
    redAlert,
    yellowAlert,
    scheduledEndDate,
  };
}
