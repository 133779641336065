import { Navigate, createBrowserRouter, redirect } from 'react-router-dom';
import AuthRoot from './core/components/AuthRoot';
import Root from './core/components/Root';
import ErrorPage from './error-page';
import * as logoutRoute from './pages/logout';

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        lazy: () => import('@app/core/components/Home'),
      },

      {
        element: <AuthRoot />,
        children: [
          { path: 'account/resetpassword', lazy: () => import('@app/pages/account/resetpassword') },
          { path: 'account/confirm', lazy: () => import('@app/pages/account/confirm') },
          { path: 'account/new-set', lazy: () => import('@app/pages/account/new-set') },
          { path: 'logout', ...logoutRoute },

          { path: 'login', lazy: () => import('@app/pages/login') },
          { path: 'company-selection', lazy: () => import('@app/pages/company-selection') },
          { path: 'register', lazy: () => import('@app/pages/register') },
          { path: 'register/success', lazy: () => import('@app/pages/register/success') },
          { path: 'recover', lazy: () => import('@app/pages/recover') },
          { path: 'new-company', lazy: () => import('@app/pages/new-company') },
          { path: 'new-company/success', lazy: () => import('@app/pages/new-company/success') },
        ],
      },

      {
        element: <Root />,
        children: [
          { path: 'account/owners', lazy: () => import('@app/pages/account/owners') },
          { path: 'projects', lazy: () => import('@app/pages/projects') },
          { path: 'project/new', lazy: () => import('@app/pages/project/new') },
          {
            path: 'project/:projectId',
            lazy: () => import('@app/pages/project/$projectId/components/ProjectLayout'),
            children: [
              { index: true, lazy: () => import('@app/pages/project/$projectId') },
              { path: 'gantt', lazy: () => import('@app/pages/project/$projectId/gantt') },
              {
                path: 'info',
                lazy: () => import('@app/pages/project/$projectId/info/layout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('@app/pages/project/$projectId/info'),
                  },
                  {
                    path: 'client',
                    lazy: () => import('@app/pages/project/$projectId/info/client'),
                  },
                  {
                    path: 'invoice',
                    lazy: () => import('@app/pages/project/$projectId/info/invoice'),
                  },
                  {
                    path: 'ratetable',
                    lazy: () => import('@app/pages/project/$projectId/info/ratetable'),
                  },
                  {
                    path: 'personnel',
                    lazy: () => import('@app/pages/project/$projectId/info/personnel'),
                  },
                  {
                    path: 'documents',
                    lazy: () => import('@app/pages/project/$projectId/info/documents'),
                  },
                  {
                    path: 'scheduling',
                    lazy: () => import('@app/pages/project/$projectId/info/scheduling'),
                  },
                  {
                    path: 'scorecard',
                    lazy: () => import('@app/pages/project/$projectId/info/scorecard'),
                  },
                  {
                    path: 'status-report',
                    lazy: () => import('@app/pages/project/$projectId/info/status-report'),
                  },
                  {
                    path: 'manage',
                    lazy: () => import('@app/pages/project/$projectId/info/manage'),
                  },
                ],
              },
            ],
          },
          {
            path: 'workloads',
            lazy: () => import('@app/pages/workload'),
          },
          {
            path: 'budgets',
            lazy: () => import('@app/pages/budgets'),
          },
          {
            path: 'timesheets',
            children: [
              { index: true, element: <Navigate to="entry" replace /> },
              { path: 'entry', lazy: () => import('@app/pages/timesheets/entry') },
              { path: 'admin', lazy: () => import('@app/pages/timesheets/admin') },
            ],
          },

          {
            path: 'expense-reports',
            children: [
              { index: true, lazy: () => import('@app/pages/expense-reports') },

              {
                path: ':expenseReportId',
                lazy: () => import('@app/pages/expense-reports/$expenseReportId'),
              },
            ],
          },
          {
            path: 'expense-report/:expenseReportId',
            loader: ({ params }) => redirect(`/expense-reports/${params.expenseReportId}`),
          },

          { path: 'profile', lazy: () => import('@app/pages/profile') },
          { path: 'profile/password', lazy: () => import('@app/pages/profile/password') },

          {
            path: 'subs',
            children: [
              { index: true, lazy: () => import('@app/pages/subs') },
              { path: 'estimates', lazy: () => import('@app/pages/subs/estimates') },
              {
                path: ':subcontractId',
                lazy: () => import('@app/pages/subs/$subcontractId'),
              },
            ],
          },
          {
            path: 'vendors',
            children: [
              { index: true, lazy: () => import('@app/pages/subs') },
              { path: 'estimates', lazy: () => import('@app/pages/subs/estimates') },
              {
                path: ':subcontractId',
                lazy: () => import('@app/pages/subs/$subcontractId'),
              },
            ],
          },

          {
            path: 'prioritize',
            children: [
              { index: true, lazy: () => import('@app/pages/prioritize') },
              {
                path: ':id',
                lazy: () => import('@app/pages/prioritize/$id'),
              },
            ],
          },

          /* Admin section */
          {
            path: 'admin',
            lazy: () => import('@app/pages/admin/components/AdminLayout'),
            children: [
              {
                index: true,
                lazy: () => import('./pages/admin'),
              },
              {
                path: 'company',
                children: [
                  { index: true, element: <Navigate replace to="info" /> },
                  { path: 'info', lazy: () => import('./pages/admin/company/components/CompanyInfoPage') },
                  {
                    path: 'offices',
                    children: [
                      { index: true, lazy: () => import('@app/pages/admin/office/components/OfficesPage') },
                      { path: 'new', lazy: () => import('@app/pages/admin/office/components/OfficeEditPage') },
                      { path: ':officeId', lazy: () => import('@app/pages/admin/office/components/OfficeEditPage') },
                    ],
                  },
                  {
                    path: 'clients',
                    children: [
                      {
                        index: true,
                        lazy: () => import('./pages/admin/client/components/ClientsPage'),
                      },
                      {
                        path: 'new',
                        lazy: () => import('./pages/admin/client/components/NewClientPage'),
                      },
                      {
                        path: ':clientId/*',
                        lazy: () => import('./pages/admin/client/components/ClientPage'),
                        children: [
                          { index: true, lazy: () => import('./pages/admin/client/components/Client') },
                          {
                            path: 'contacts',
                            children: [
                              { index: true, lazy: () => import('./pages/admin/client/components/ClientContacts') },
                              { path: 'new', lazy: () => import('./pages/admin/client/components/NewClientContact') },
                              {
                                path: ':contactId',
                                lazy: () => import('./pages/admin/client/components/ClientContact'),
                              },
                            ],
                          },
                          {
                            path: 'departments',
                            children: [
                              { index: true, lazy: () => import('./pages/admin/client/components/ClientDepartments') },
                              {
                                path: 'new',
                                lazy: () => import('./pages/admin/client/components/NewClientDepartment'),
                              },
                              {
                                path: ':departmentId',
                                lazy: () => import('./pages/admin/client/components/ClientDepartment'),
                              },
                            ],
                          },
                          { path: 'manage', lazy: () => import('./pages/admin/client/components/ManageClientPage') },
                        ],
                      },
                    ],
                  },
                  { path: 'scheduling', lazy: () => import('@app/pages/admin/settings/SchedulingPage') },
                  {
                    path: 'units',
                    children: [
                      { index: true, lazy: () => import('@app/pages/admin/unitsOfMeasure/UnitsOfMeasureList') },
                      { path: 'new', lazy: () => import('@app/pages/admin/unitsOfMeasure/NewUnitOfMeasurePage') },
                      { path: ':id', lazy: () => import('@app/pages/admin/unitsOfMeasure/EditUnitOfMeasurePage') },
                    ],
                  },
                  {
                    path: 'programs',
                    children: [
                      { index: true, lazy: () => import('./pages/admin/program/components/Programs') },
                      { path: 'new', lazy: () => import('./pages/admin/program/components/NewProgram') },
                      { path: ':id', lazy: () => import('./pages/admin/program/components/EditProgram') },
                    ],
                  },
                  {
                    path: 'certifications',
                    lazy: () => import('./pages/admin/certification/components/CertificationsPage'),
                  },
                ],
              },
              {
                path: 'tags',
                lazy: () => import('./pages/admin/tags/TagsPage'),
              },
              {
                path: 'funds',
                children: [
                  { index: true, lazy: () => import('./pages/admin/funds') },
                  { path: 'new', lazy: () => import('./pages/admin/funds/new') },
                  { path: ':id', lazy: () => import('./pages/admin/funds/edit') },
                ],
              },
              {
                path: 'ratetable',
                lazy: () => import('./pages/admin/company/components/RateTablePage'),
              },
              {
                path: 'users',
                children: [
                  { index: true, lazy: () => import('./pages/admin/employee/components/EmployeesPage') },
                  { path: 'new', lazy: () => import('./pages/admin/employee/components/NewEmployeePage') },
                  {
                    path: ':personId/*',
                    lazy: () => import('./pages/admin/employee/components/EmployeePage'),
                    children: [
                      { index: true, lazy: () => import('./pages/admin/employee/components/Employee') },
                      { path: 'roles', lazy: () => import('./pages/admin/employee/components/EmployeeRoles') },
                      { path: 'office', lazy: () => import('./pages/admin/employee/components/EmployeeOffice') },
                      { path: 'payroll', lazy: () => import('./pages/admin/employee/components/EmployeePayroll') },
                      { path: 'billing', lazy: () => import('./pages/admin/employee/components/EmployeeBilling') },
                      {
                        path: 'manage',
                        lazy: () => import('./pages/admin/employee/components/EmployeeManage/EmployeeManagePage'),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'roles',
                children: [
                  { index: true, lazy: () => import('./pages/admin/role/components/RolesPage') },
                  { path: 'new', lazy: () => import('./pages/admin/role/components/NewRole') },
                  { path: ':roleId', lazy: () => import('./pages/admin/role/components/RolePage') },
                ],
              },
              {
                path: 'classifications',
                children: [
                  {
                    index: true,
                    lazy: () => import('./pages/admin/projectClassifications/ProjectClassificationsPage'),
                  },
                  {
                    path: 'new',
                    lazy: () => import('./pages/admin/projectClassifications/NewProjectClassificationPage'),
                  },
                  {
                    path: ':id',
                    lazy: () => import('./pages/admin/projectClassifications/EditProjectClassificationPage'),
                  },
                ],
              },
              {
                path: 'sub-statuses',
                children: [
                  { index: true, lazy: () => import('./pages/admin/projectSubStatuses/ProjectSubStatusesPage') },
                  { path: 'new', lazy: () => import('./pages/admin/projectSubStatuses/NewProjectSubStatusPage') },
                  { path: ':id', lazy: () => import('./pages/admin/projectSubStatuses/EditProjectSubStatusPage') },
                ],
              },
              {
                path: 'categories',
                lazy: () => import('./pages/admin/resourceCategories/components/ResourceCategoriesPage'),
              },
              {
                path: 'activity-codes',
                lazy: () => import('@app/pages/admin/activityCodes/ActivityCodesPage'),
                children: [
                  { index: true, lazy: () => import('./pages/admin/activityCodes/ActivityCodesList') },
                  { path: 'new', lazy: () => import('./pages/admin/activityCodes/NewActivityCodePage') },
                  { path: ':id', lazy: () => import('./pages/admin/activityCodes/EditActivityCodePage') },
                ],
              },
              {
                path: 'scope-statuses',
                lazy: () => import('@app/pages/admin/scopeElementStatuses/ScopeElementStatusesPage'),
                children: [
                  { index: true, lazy: () => import('./pages/admin/scopeElementStatuses/ScopeElementStatusesList') },
                  { path: 'new', lazy: () => import('./pages/admin/scopeElementStatuses/NewScopeElementStatusPage') },
                  { path: ':id', lazy: () => import('./pages/admin/scopeElementStatuses/EditScopeElementStatusPage') },
                ],
              },
              {
                path: 'subcontract-statuses',
                lazy: () => import('./pages/admin/subcontractStatuses/SubcontractStatusesPage'),
              },
              {
                path: 'contract-statuses',
                lazy: () => import('./pages/admin/subcontractStatuses/SubcontractStatusesPage'),
              },
              {
                path: 'budget-statuses',
                lazy: () => import('./pages/admin/subcontractLimitStatuses/SubcontractLimitStatusesPage'),
              },
              {
                path: 'limit-statuses',
                lazy: () => import('./pages/admin/subcontractLimitStatuses/SubcontractLimitStatusesPage'),
              },
              {
                path: 'bill-statuses',
                lazy: () => import('./pages/admin/subcontractBillStatuses/SubcontractBillStatusesPage'),
              },
              {
                path: 'agile-scores',
                lazy: () => import('./pages/admin/velocityWeights/VelocityWeightsPage'),
                children: [
                  { index: true, lazy: () => import('./pages/admin/velocityWeights/VelocityWeightsList') },
                  { path: 'new', lazy: () => import('./pages/admin/velocityWeights/NewVelocityWeightPage') },
                  { path: ':id', lazy: () => import('./pages/admin/velocityWeights/EditVelocityWeightPage') },
                ],
              },
              { path: 'alert-thresholds', lazy: () => import('./pages/admin/alertThresholds/AlertThresholdsPage') },
              {
                path: 'scorecards',
                children: [
                  { index: true, lazy: () => import('./pages/admin/scorecard/ScorecardTemplatesPage') },
                  { path: ':id', lazy: () => import('./pages/admin/scorecard/ScorecardTemplatePage') },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
