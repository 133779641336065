import type { ISubcontractGridRow } from '@app/types';
import { type PayloadAction, type SerializedError, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { queueSubcontractGridRowUpdate } from './queueSubcontractGridRowUpdate';
import { setSubcontractGridRowBillValue } from './setSubcontractGridRowBillValue';
import { setSubcontractGridRowLimitValue } from './setSubcontractGridRowLimitValue';
import { upsertSubcontractGridRow } from './upsertSubcontractGridRow';

export const adapter = createEntityAdapter<ISubcontractGridRow>({
  // make sure to show the newRow as last always
  sortComparer: (a, b) => {
    if (!a.rowId) {
      return 1;
    }
    if (!b.rowId) {
      return -1;
    }
    return a.rowId - b.rowId;
  },
});

export interface ISubcontractGridRowUpdateQueueEntry {
  id: string;
  row: ISubcontractGridRow;
}

const initialState = adapter.getInitialState({
  isProcessingQueue: false,
  updateQueue: [] as ISubcontractGridRowUpdateQueueEntry[],
  unrecoverableError: null as { message: string; error: SerializedError } | null,
});

// export type ISubcontractGridRowsSliceState = typeof initialState;

const slice = createSlice({
  name: 'subcontractGridRows',
  initialState,
  reducers: {
    setAllSubcontractGridRows: adapter.setAll,

    upsertOneSubcontractGridRow: (state, action: PayloadAction<ISubcontractGridRow>) => {
      adapter.upsertOne(state, action.payload);
    },

    addOneSubcontractGridRowUpdateEntry: (state, action: PayloadAction<ISubcontractGridRowUpdateQueueEntry>) => {
      state.updateQueue.push(action.payload);
    },

    setIsProcessingSubcontractGridRowUpdateQueue: (state, action: PayloadAction<boolean>) => {
      state.isProcessingQueue = action.payload;
    },

    removeSubcontractGridRowUpdateQueueEntries: (state, action: PayloadAction<string[]>) => {
      state.updateQueue = state.updateQueue.filter((entry) => !action.payload.includes(entry.id));
    },
    clearSubcontractGridUnrecoverableError: (state) => {
      state.unrecoverableError = null;
    },
    setSubcontractGridUnrecoverableError: (
      state,
      action: PayloadAction<{ message: string; error: SerializedError }>
    ) => {
      state.unrecoverableError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queueSubcontractGridRowUpdate.fulfilled, (state) => {
      state.isProcessingQueue = false;
    });

    builder.addCase(queueSubcontractGridRowUpdate.rejected, (state, action) => {
      state.isProcessingQueue = false;

      // Clear the queue so we don't try to process the same updates again
      state.updateQueue = [];

      const errorMessage = 'We couldn’t save your latest changes.';

      state.unrecoverableError = { message: errorMessage, error: action.error };
    });

    builder.addCase(setSubcontractGridRowBillValue.rejected, (state, action) => {
      state.isProcessingQueue = false;

      // Clear the queue so we don't try to process the same updates again
      state.updateQueue = [];

      const errorMessage = 'We couldn’t save your latest changes.';

      state.unrecoverableError = { message: errorMessage, error: action.error };
    });

    builder.addCase(upsertSubcontractGridRow.rejected, (state, action) => {
      state.isProcessingQueue = false;

      // Clear the queue so we don't try to process the same updates again
      state.updateQueue = [];

      const errorMessage = 'We couldn’t save your latest changes.';

      state.unrecoverableError = { message: errorMessage, error: action.error };
    });

    builder.addCase(setSubcontractGridRowLimitValue.rejected, (state, action) => {
      state.isProcessingQueue = false;

      // Clear the queue so we don't try to process the same updates again
      state.updateQueue = [];

      const errorMessage = 'We couldn’t save your latest changes.';

      state.unrecoverableError = { message: errorMessage, error: action.error };
    });
  },
});

export const {
  setAllSubcontractGridRows,
  upsertOneSubcontractGridRow,
  addOneSubcontractGridRowUpdateEntry,
  setIsProcessingSubcontractGridRowUpdateQueue,
  removeSubcontractGridRowUpdateQueueEntries,
  clearSubcontractGridUnrecoverableError,
  setSubcontractGridUnrecoverableError,
} = slice.actions;

export default slice.reducer;
