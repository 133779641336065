/**
 * Creates a promise that resolves once the provided conditionCheck returns true.
 * The conditionCheck is checked every 100ms.
 *
 * @param conditionCheck () => boolean
 * @param intervalDuration number milliseconds between conditionCheck calls
 * @returns Promise<void>
 */
export function awaitUntil(conditionCheck: () => boolean, intervalDuration = 100): Promise<void> {
  if (conditionCheck()) {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (conditionCheck()) {
        clearInterval(interval);
        resolve();
      }
    }, intervalDuration);
  });
}
