import type { IFundData, IProjectGridRow } from '@app/types';
import type { IUpdateProjectGridRowFundDataProps } from './thunkTypes';
import { isParentFundDataMethod } from './isParentFundDataMethod';
import { recalculateValues } from './recalculateValues';

type NextDataProps = IUpdateProjectGridRowFundDataProps & {
  projectGridRow: IProjectGridRow;
  existingFundData?: IFundData;
};

export function getNextFundData(props: NextDataProps): IFundData {
  const { projectGridRow, existingFundData, fund, field, value } = props;

  const base: IFundData = existingFundData ?? {
    id: crypto.randomUUID(),
    fundId: fund.id,
    fundMethod: 'amount',
    amount: 0,
    percentage: 0,
    inherent: true,
    scopeElementId: projectGridRow.id,
  };

  const transformedValue = isParentFundDataMethod(field, value) ? value.replace('-parent', '') : value;

  const nextData = { ...base, [field]: transformedValue };
  if (field === 'fundMethod') {
    nextData.inherent = !isParentFundDataMethod(field, value);
  }

  return recalculateValues({ fundData: nextData, projectGridRow, field });
}
