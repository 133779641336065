import { bulkDeleteTimesheetEntries, bulkUpdateTimesheetEntries } from '@app/api/timesheet.api';
import type { ITimesheetEntry } from '@app/types';
import { type AnyAction, createAsyncThunk, type ThunkDispatch } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import {
  addTimesheetEntryTransaction,
  emptyTimesheetEntryTransactionQueue,
  type ITimesheetEntryTransaction,
  removeTimesheetEntryTransactionForEntryId,
  setIsProcessingTimesheetQueue,
} from '.';
import type { IState } from '../store';
import { updateOneTimesheetEntry } from '../timesheetEntries';

interface IProcessQueueParams {
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, AnyAction>;
}
async function processQueue(params: IProcessQueueParams): Promise<void> {
  const { getState, dispatch } = params;

  const queue = getState().timesheet.entryTransactionQueue;
  if (queue.length === 0) {
    return;
  }
  dispatch(emptyTimesheetEntryTransactionQueue());

  const { removedIds, updated } = queue.reduce(
    (acc, cur) => {
      if (cur.type === 'remove') {
        acc.removedIds.push(cur.id as string);
      } else {
        const entry = getState().timesheetEntries.entities[cur.id];
        invariant(entry);
        acc.updated.push(entry);
      }

      return acc;
    },
    { removedIds: [], updated: [] } as { removedIds: string[]; updated: ITimesheetEntry[] }
  );

  const promises: Promise<any>[] = [];
  if (removedIds.length > 0) {
    promises.push(bulkDeleteTimesheetEntries(removedIds));
  }
  if (updated.length > 0) {
    promises.push(bulkUpdateTimesheetEntries(updated));
  }

  await Promise.all(promises);

  // mark all updated entries as processed
  for (const entry of updated) {
    dispatch(updateOneTimesheetEntry({ id: entry.id, changes: { isPersisted: true } }));
  }

  return processQueue(params);
}

export const queueTimesheetEntryTransaction = createAsyncThunk<void, ITimesheetEntryTransaction, { state: IState }>(
  'timesheet/queueTimesheetEntryTransaction',
  async (nextTransaction, { getState, dispatch }) => {
    const queue = getState().timesheet.entryTransactionQueue;
    const existingTransaction = queue.find((item) => item.id === nextTransaction.id);

    if (existingTransaction?.type === 'remove') {
      throw new Error('This entry is already queued for removal');
    }

    if (nextTransaction.type === 'remove') {
      // If current transaction will remove the entry, we need to remove any existing update transactions
      if (existingTransaction) {
        dispatch(removeTimesheetEntryTransactionForEntryId(nextTransaction.id));
      }
      // We also want to make sure we queue up the remove transaction itself
      dispatch(addTimesheetEntryTransaction(nextTransaction));
    }

    if (nextTransaction.type === 'update' && !existingTransaction) {
      dispatch(addTimesheetEntryTransaction(nextTransaction));
    }

    const isProcessingQueue = getState().timesheet.isProcessingTransactionQueue;
    if (isProcessingQueue) {
      return;
    }

    dispatch(setIsProcessingTimesheetQueue(true));
    await processQueue({
      getState,
      dispatch,
    });
    dispatch(setIsProcessingTimesheetQueue(false));
  }
);
