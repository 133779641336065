import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { TIMESHEET_DATE_FORMAT } from '../../pages/timesheets/entry/config';
import type { ITimesheetEntry } from '../../types';
import type { IState } from '../store';
import { timesheetRowSelectors } from '../timesheetRows';

const adapter = createEntityAdapter<ITimesheetEntry>();

const timesheetEntrySelectors = adapter.getSelectors();

const slice = createSlice({
  name: 'timesheetEntries',
  initialState: adapter.getInitialState(),
  reducers: {
    setAllTimesheetEntries: adapter.setAll,
    removeManyTimesheetEntries: adapter.removeMany,
    updateOneTimesheetEntry: adapter.updateOne,
    removeOneTimesheetEntry: adapter.removeOne,
    addOneTimesheetEntry: adapter.addOne,
  },
});

export const {
  setAllTimesheetEntries,
  removeManyTimesheetEntries,
  updateOneTimesheetEntry,
  removeOneTimesheetEntry,
  addOneTimesheetEntry,
} = slice.actions;

export const selectTimesheetRowEntries = createSelector(
  [
    (state: IState) => state.timesheetRows,
    (state: IState) => state.timesheetEntries,
    (_state: IState, rowGroup: string) => rowGroup,
  ],
  (rows, entries, rowGroup) => {
    const row = timesheetRowSelectors.selectById(rows, rowGroup);
    if (!row) {
      return [];
    }

    return row.timesheetEntryIds
      .map((id) => timesheetEntrySelectors.selectById(entries, id))
      .filter((entry): entry is ITimesheetEntry => !!entry);
  }
);

const selectTimesheetEntriesForDate = createSelector(
  [(state: IState) => state.timesheetEntries, (_state: IState, date: Date) => date],
  (state, date) => {
    const entries = timesheetEntrySelectors.selectAll(state);
    const shortDate = format(date, TIMESHEET_DATE_FORMAT);
    return entries.filter((entry) => entry.shortDate === shortDate);
  }
);

export const selectTimesheetColumnTotal = createSelector(
  [(state: IState) => state, (_state: IState, date: Date) => date],
  (state, date) => {
    const entries = selectTimesheetEntriesForDate(state, date);

    const totalDuration = entries.reduce((total, entry) => total + entry.duration, 0);
    return Math.round(totalDuration * 100) / 100;
  }
);

export const selectTimesheetTotal = createSelector([(state: IState) => state.timesheetEntries], (state) => {
  const entries = timesheetEntrySelectors.selectAll(state);

  const totalDuration = entries.reduce((total, entry) => total + entry.duration, 0);
  return Math.round(totalDuration * 100) / 100;
});

export const selectSelectedTimesheetEntry = createSelector(
  [(state: IState) => state.timesheetEntries, (state: IState) => state.timesheet.selectedTimesheetEntryId],
  (state, selectedTimesheetEntryId) => {
    if (!selectedTimesheetEntryId) {
      return;
    }

    return timesheetEntrySelectors.selectById(state, selectedTimesheetEntryId);
  }
);

export default slice.reducer;
