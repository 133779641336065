import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateOneTimesheetEntry } from '.';
import { unlockTimesheetEntryRequest } from '../../api/timesheet.api';
import type { ITimesheetEntry } from '../../types';
import type { IState } from '../store';

export const unlockTimesheetEntry = createAsyncThunk<void, ITimesheetEntry, { state: IState }>(
  'timesheetEntries/unlockTimesheetEntry',
  async (props, { dispatch }) => {
    const { id } = props;

    dispatch(updateOneTimesheetEntry({ id, changes: { submitted: null } }));
    await unlockTimesheetEntryRequest(id);
  }
);
