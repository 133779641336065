import axios from 'axios';
import type { IUser } from '@app/types';
import { API_PREFIX } from '@app/config';

export async function fetchCurrentUser() {
  const response = await fetch(`${API_PREFIX}/tenant/user/get-current`);
  if (response.status === 401) {
    return null;
  }

  if (response.status === 200) {
    return (await response.json()) as IUser;
  }
  throw new Error(`Unexpected response status: ${response.status}`);
}

export async function getCurrentUser() {
  return axios.get<IUser>('tenant/user/get-current');
}
export async function getUsers() {
  return axios.get<IUser[]>('users');
}
