import clsx from 'clsx';
import type * as React from 'react';
import { toast, type ToastOptions } from 'react-hot-toast';
import {
  FaCheckCircle,
  FaCircleNotch,
  FaExclamationCircle,
  FaInfoCircle,
  FaTimes,
  FaTimesCircle,
} from 'react-icons/fa';

type IToastTheme = 'success' | 'error' | 'warning' | 'info' | 'loading';
interface IToastProps {
  title?: string;
  message: React.ReactNode | string;
}

const DEFAULT_DURATION = 4000;

function getToastTheme(theme: IToastTheme) {
  switch (theme) {
    case 'success':
      return {
        icon: FaCheckCircle,
        containerClassName: 'border-l-app-green-primary bg-[#ECF5F5]',
        iconClassName: 'text-app-green-primary',
      };
    case 'error':
      return {
        icon: FaTimesCircle,
        containerClassName: 'border-l-app-red-primary bg-[#FBEEEE]',
        iconClassName: 'text-app-red-primary',
      };
    case 'info':
      return {
        icon: FaInfoCircle,
        containerClassName: 'border-l-app-blue-highlight bg-[#EBFAFE]',
        iconClassName: 'text-app-blue-highlight',
      };
    case 'warning':
      return {
        icon: FaExclamationCircle,
        containerClassName: 'border-l-app-orange-primary bg-[#FCF6EE]',
        iconClassName: 'text-app-orange-primary',
      };
    case 'loading':
      return {
        icon: FaCircleNotch,
        containerClassName: 'border-l-gray-400 bg-white',
        iconClassName: 'text-gray-400 animate-spin',
      };
  }
}

function showToast(props: Required<IToastProps>, theme: IToastTheme, options?: ToastOptions) {
  const { title, message } = props;

  const themeOptions = getToastTheme(theme);

  const Icon = themeOptions.icon;

  return toast.custom((t) => {
    return (
      <div
        className={clsx(
          'pointer-events-auto flex w-full max-w-md rounded-lg border-l-8 bg-white/95 shadow-[0_25px_35px_0_rgba(0,0,0,0.3)] transition-all',
          themeOptions.containerClassName
        )}
        {...t.ariaProps}
      >
        <div className="w-0 flex-1 p-6">
          <div className="flex items-start">
            <div className="shrink-0 pt-1">
              <Icon className={clsx(themeOptions.iconClassName, 'size-8')} />
            </div>
            <div className="ml-6 flex-1 text-app-gray-text">
              <p className="text-base font-medium">{title}</p>
              <div className="text-sm">{message}</div>
            </div>
          </div>
        </div>
        <div className="flex">
          <button
            onClick={() => toast.remove(t.id)}
            type="button"
            className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-app-gray-medium hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <FaTimes className="size-6" />
          </button>
        </div>
      </div>
    );
  }, options);
}

function prepareToastOptions(options: ToastOptions = {}) {
  return { duration: DEFAULT_DURATION, ...options };
}

export function showSuccessToast(props: IToastProps, options?: ToastOptions) {
  return showToast(
    {
      ...props,
      title: props.title ?? 'Success',
    },
    'success',
    prepareToastOptions(options)
  );
}

export function showErrorToast(props: IToastProps, options?: ToastOptions) {
  return showToast(
    {
      ...props,
      title: props.title ?? 'Error',
    },
    'error',
    prepareToastOptions(options)
  );
}

export function showInfoToast(props: IToastProps, options?: ToastOptions) {
  return showToast(
    {
      ...props,
      title: props.title ?? 'Info',
    },
    'info',
    prepareToastOptions(options)
  );
}

export function showLoadingToast(props: IToastProps, options: ToastOptions = {}) {
  return showToast(
    {
      ...props,
      title: props.title ?? 'Loading...',
    },
    'loading',
    {
      ...options,
      duration: Number.POSITIVE_INFINITY,
    }
  );
}

export function dismissToast(id: string) {
  toast.dismiss(id);
}
