import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';
import type { ITenantTypeName } from '@app/types';

interface Props {
  projectStatusIds: string[];
  projectSubStatusIds: string[];
  tenantTypeName: ITenantTypeName;
}

export const setInitialUserPreferences = createAsyncThunk<void, Props, { state: IState }>(
  'uiView/setInitialUserPreferences',
  (_, { dispatch }) => {
    dispatch(persistUserPreferences());
  }
);
