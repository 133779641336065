import type { IProjectGridRow } from '@app/types';

/**
 * Calculates the total costs value for a given project grid row.
 * We round the final value to 2 decimal places.
 *
 * @param data - The project grid row data.
 * @returns The sum of the actual and forecast total costs.
 */
export function getProjectGridRowTotalCostsValue(data?: IProjectGridRow) {
  if (!data) {
    return 0;
  }
  return Math.round(data.agg.totalCost * 100) / 100;
}
