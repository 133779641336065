import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IGridUIState } from '../../types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectsOverviewGridUIState = createAsyncThunk<void, { gridUIState: IGridUIState }, { state: IState }>(
  'uiView/setProjectsOverviewGridUIState',
  (_, { dispatch }) => {
    dispatch(persistUserPreferences());
  }
);
