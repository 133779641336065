import { findInvariant } from '@app/core/utilities/findInvariant';
import type { IBudgetGridRow, IProjectBudgetBudgetSummaryGridRow, IProjectBudgetCostsSummaryGridRow } from '@app/types';
import type { Draft, EntityState } from '@reduxjs/toolkit';
import invariant from '../../core/utilities/invariant';

export function recalculateBudgetProgramTotals(
  state: Draft<EntityState<IBudgetGridRow, string>>,
  programId: string,
  fundId?: string | null
) {
  const allRows = Object.values(state.entities);

  const programRows = allRows.filter((row) => row.level === 'program' && row.programId === programId);
  const costRow = findInvariant(programRows, (row) => row.financeType === 'costs');
  const budgetRow = findInvariant(programRows, (row) => row.financeType === 'budget');
  const varianceRow = findInvariant(programRows, (row) => row.financeType === 'variance');

  const budgetData: Record<string, number> = {};
  const costData: Record<string, number> = {};

  const projectBudgetRows = allRows.filter(
    (row) => row.financeType === 'budget' && row.level === 'project' && row.programId === programId
  ) as IProjectBudgetBudgetSummaryGridRow[];

  const projectScores: number[] = [];
  const projectBudgetScores: number[] = [];

  for (const projectBudgetRow of projectBudgetRows) {
    const matchingCostRow = findInvariant(
      allRows,
      (row) => {
        return (
          row.financeType === 'costs' &&
          row.level === 'project' &&
          projectBudgetRow.level === 'project' &&
          row.programId === projectBudgetRow.programId &&
          row.projectId === projectBudgetRow.projectId
        );
      },
      `Matching cost row not found for project budget row ${projectBudgetRow.id}`
    ) as IProjectBudgetCostsSummaryGridRow;

    projectBudgetScores.push(projectBudgetRow.score);
    projectScores.push(matchingCostRow.score);

    const budgetBucket = fundId ? projectBudgetRow.fundData[fundId] : projectBudgetRow.budgetData;
    invariant(budgetBucket, `No budget data found for fund ${fundId} in row ${projectBudgetRow.id}`);
    for (const period of Object.keys(budgetBucket)) {
      budgetData[period] = (budgetData[period] ?? 0) + budgetBucket[period];
    }

    const costBucket = fundId ? matchingCostRow.fundData[fundId] : matchingCostRow.budgetData;
    invariant(costBucket, `No budget data found for fund ${fundId} in row ${matchingCostRow.id}`);
    for (const period of Object.keys(costBucket)) {
      costData[period] = (costData[period] ?? 0) + costBucket[period];
    }
  }

  const varianceData: Record<string, number> = structuredClone(budgetData);
  for (const period of Object.keys(costData)) {
    varianceData[period] = (varianceData[period] ?? 0) - costData[period];
  }

  if (fundId) {
    state.entities[budgetRow.id].fundData[fundId] = budgetData;
    state.entities[costRow.id].fundData[fundId] = costData;
    state.entities[varianceRow.id].fundData[fundId] = varianceData;
  } else {
    state.entities[budgetRow.id].budgetData = budgetData;
    state.entities[costRow.id].budgetData = costData;
    state.entities[varianceRow.id].budgetData = varianceData;
  }

  // update the program budget score
  const programScore = projectScores.reduce((acc, cur) => acc + cur, 0);
  const programBudgetScore = projectBudgetScores.reduce((acc, cur) => acc + cur, 0);

  /**
   * Program budget score is a sum of all project budget scores
   * Program costs score is a sum of all project costs scores
   */
  state.entities[budgetRow.id].score = programBudgetScore;
  state.entities[costRow.id].score = programScore;
}
