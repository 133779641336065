import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IResourceType } from '../types';
import type { IState } from './store';

const adapter = createEntityAdapter<IResourceType>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'resourceTypes',
  initialState,
  reducers: {
    setAllResourceTypes: adapter.setAll,
  },
});

export const resourceTypeSelectors = adapter.getSelectors();

export const selectResourceTypeByName = createSelector(
  [(state: IState) => state.resourceTypes, (_state: IState, name: string) => name],
  (state, name) => {
    const item = resourceTypeSelectors.selectAll(state).find((s) => s.name === name);
    invariant(item, `Could not find resource type with name ${name}`);
    return item;
  }
);

export const { setAllResourceTypes } = slice.actions;

export default slice.reducer;
