import { clsx } from 'clsx';
import type * as React from 'react';

type IProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  defaultClassName?: string;
  required?: boolean;
  children: React.ReactNode | string;
};

export const FormLabel = (props: IProps) => {
  const { className, required, children, defaultClassName = 'mb-1 text-gray-900 text-sm block', ...labelProps } = props;
  return (
    <label {...labelProps} className={clsx(defaultClassName, className)}>
      {children}{' '}
      {required && (
        <span className="text-red-700" title="required">
          *
        </span>
      )}
    </label>
  );
};
