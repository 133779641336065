import type { IBudgetGridRow, IProjectBudgetBudgetSummaryGridRow, IProjectBudgetCostsSummaryGridRow } from '@app/types';
import type { Draft, EntityState } from '@reduxjs/toolkit';
import { findInvariant } from '@app/core/utilities/findInvariant';
import invariant from '@app/core/utilities/invariant';

export function recalculateBudgetProjectTotals(
  state: Draft<EntityState<IBudgetGridRow, string>>,
  projectId: string,
  fundId?: string | null
) {
  const allRows = Object.values(state.entities);
  const projectRows = allRows.filter((row) => row.level === 'project' && row.projectId === projectId);
  const adjustmentRows = projectRows.filter((row) => row.financeType === 'adjustment');
  const baseBudgetRow = findInvariant(projectRows, (row) => row.financeType === 'baseBudget');
  const costRow = findInvariant(
    projectRows,
    (row) => row.financeType === 'costs' && row.level === 'project'
  ) as IProjectBudgetCostsSummaryGridRow;
  const budgetRow = findInvariant(
    projectRows,
    (row) => row.financeType === 'budget' && row.level === 'project'
  ) as IProjectBudgetBudgetSummaryGridRow;
  const varianceRow = findInvariant(projectRows, (row) => row.financeType === 'variance' && row.level === 'project');

  const budgetData: Record<string, number> = {};

  const projectBucket = fundId ? baseBudgetRow.fundData[fundId] : baseBudgetRow.budgetData;
  invariant(projectBucket, `No budget data found for fund ${fundId} in project ${projectId}`);

  for (const period of Object.keys(projectBucket)) {
    budgetData[period] = (budgetData[period] ?? 0) + projectBucket[period];
  }

  for (const adjustmentRow of adjustmentRows) {
    const adjustmentBucket = fundId ? adjustmentRow.fundData[fundId] : adjustmentRow.budgetData;
    invariant(adjustmentBucket, `No budget data found for fund ${fundId} in project ${projectId}`);

    for (const period of Object.keys(adjustmentBucket)) {
      budgetData[period] = (budgetData[period] ?? 0) + adjustmentBucket[period];
    }
  }

  const varianceData: Record<string, number> = structuredClone(budgetData);
  const costBucket = fundId ? costRow.fundData[fundId] : costRow.budgetData;
  for (const period of Object.keys(costBucket)) {
    varianceData[period] = (varianceData[period] ?? 0) - costBucket[period];
  }

  if (fundId) {
    state.entities[budgetRow.id].fundData[fundId] = budgetData;
    state.entities[varianceRow.id].fundData[fundId] = varianceData;
  } else {
    state.entities[budgetRow.id].budgetData = budgetData;
    state.entities[varianceRow.id].budgetData = varianceData;
  }

  // update the project budget score
  const totalCosts = Object.values(costRow.budgetData).reduce((a, b) => a + b, 0);
  const totalBudget = Object.values(budgetRow.budgetData).reduce((a, b) => a + b, 0);
  const projectScore = costRow.score;
  const nextBudgetScore = totalCosts > 0 ? Math.round((projectScore * totalBudget) / totalCosts) : 0;
  state.entities[budgetRow.id].score = nextBudgetScore;
}
