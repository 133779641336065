import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { removeOneTimesheetEntry } from '.';
import type { IState } from '../store';
import { queueTimesheetEntryTransaction } from '../timesheet/queueTimesheetEntryTransaction';
import { updateOneTimesheetRow } from '../timesheetRows';

export const removeTimesheetEntry = createAsyncThunk<void, { entryId: string }, { state: IState }>(
  'timesheetEntries/removeTimesheetEntry',
  async (props, { getState, dispatch }) => {
    const { entryId } = props;

    const entry = getState().timesheetEntries.entities[entryId];
    invariant(entry, 'Entry not found');

    const row = getState().timesheetRows.entities[entry.rowGroup];

    invariant(row, 'Row not found');

    dispatch(
      updateOneTimesheetRow({
        id: row.rowGroup,
        changes: { timesheetEntryIds: row.timesheetEntryIds.filter((id) => id !== entryId) },
      })
    );

    dispatch(removeOneTimesheetEntry(entryId));

    // isNew entries are not persisted in the database
    if (entry.isNew) {
      return;
    }
    // If the entry has been saved or at least started processing, we need to queue a transaction
    if (entry.isPersisted || entry.isProcessing) {
      dispatch(queueTimesheetEntryTransaction({ id: entryId, type: 'remove' }));
    }
  }
);
