import axios from 'axios';
import type {
  IProjectSubcontract,
  ISubcontract,
  ISubcontractDetails,
  ISubcontractEstimate,
  ISubcontractFinancialColumnConfig,
  ISubcontractRequest,
  ISubcontractResourceRequest,
  ISubcontractScope,
  ISubcontractScopeRequest,
} from '../types';

export const getSubcontractsBySubcontractorId = (subcontractorId: string) => {
  return axios.get<ISubcontract[]>(`/subcontracts-all/${subcontractorId}`);
};

export const getSubcontractDetails = (subcontractId: string) => {
  return axios.get<ISubcontractDetails>(`/subcontract-detail/${subcontractId}`);
};

export const createSubcontract = (params: ISubcontractRequest) => {
  return axios.post<ISubcontract>('/subcontract', params);
};

export const deleteSubcontract = (params: string) => {
  return axios.delete(`/subcontract/${params}`);
};

export const updateSubcontract = (params: ISubcontractRequest) => {
  return axios.put<ISubcontract>(`/subcontract/${params?.id}`, params);
};

interface IEstimateRequest {
  name: string | null | undefined;
  description: string;
  projectIds?: string[];
  id: string;
}

export function updateEstimate(params: IEstimateRequest) {
  return axios.put(`/subcontract/${params?.id}`, params);
}

export function createEstimate(params: Omit<IEstimateRequest, 'id'>) {
  return axios.post<ISubcontract>('/subcontract', {
    ...params,
    projectIds: [],
  });
}

export const updateSubcontractScopes = (subcontractId: string, scopes: ISubcontractScopeRequest[]) => {
  return axios.put(`/sync-subcontract-scopes/${subcontractId}`, scopes);
};

export const createSubcontractLimitAttachment = (subcontractLimitId: string, fileName: string, formData: FormData) => {
  return axios.post(`/subcontract-limit/${subcontractLimitId}/attachment/${fileName}`, formData);
};
export const deleteSubcontractLimitAttachment = (subcontractLimitAttachmentId: string) => {
  return axios.delete(`/subcontract-limit/attachment/${subcontractLimitAttachmentId}`);
};
export const createSubcontractLimit = (subcontractLimit: ISubcontractResourceRequest) => {
  return axios.post<{ subcontractLimitId: string }>('/subcontract-limit', subcontractLimit);
};
export const updateSubcontractLimit = (subcontractLimit: ISubcontractFinancialColumnConfig) => {
  return axios.put(`/subcontract-limit/${subcontractLimit.id}`, subcontractLimit);
};
export const deleteSubcontractLimit = (subcontractLimit: ISubcontractFinancialColumnConfig) => {
  return axios.delete(`/subcontract-limit/${subcontractLimit.id}`);
};

export const createSubcontractBillAttachment = (subcontractBillId: string, fileName: string, formData: FormData) => {
  return axios.post(`/subcontract-bill/${subcontractBillId}/attachment/${fileName}`, formData);
};
export const deleteSubcontractBillAttachment = (subcontractBillAttachmentId: string) => {
  return axios.delete(`/subcontract-bill/attachment/${subcontractBillAttachmentId}`);
};
export const createSubcontractBill = (subcontractBill: ISubcontractResourceRequest) => {
  return axios.post<{ subcontractBillId: string }>('/subcontract-bill', subcontractBill);
};
export const updateSubcontractBill = (subcontractBill: ISubcontractFinancialColumnConfig) => {
  return axios.put(`/subcontract-bill/${subcontractBill.id}`, subcontractBill);
};
export const deleteSubcontractBill = (subcontractBill: ISubcontractFinancialColumnConfig) => {
  return axios.delete(`/subcontract-bill/${subcontractBill.id}`);
};

export const bulkUpsertSubcontractScopes = (scopes: ISubcontractScope[]) => {
  return axios.post('/subcontract-scope/bulk-update', scopes);
};

export const getSubcontractDetailsByProjectId = (projectId: string) => {
  return axios.get<ISubcontractDetails[]>(`/subcontract-detail-by-project-id/${projectId}`);
};

export const getProjectSubcontracts = () => {
  return axios.get<IProjectSubcontract[]>('project-subcontracts');
};

export const addSubcontractToProject = (
  projectId: string,
  projectSubcontract: { id?: string; subcontractId: string }[]
) => {
  return axios.put(`/project-subcontracts-sync-by-project/${projectId}`, projectSubcontract);
};

export const addProjectToSubcontracts = (
  subcontractId: string,
  projectSubcontract: { id?: string; projectId: string }[]
) => {
  return axios.put(`/project-subcontracts-sync-by-subcontract/${subcontractId}`, projectSubcontract);
};

interface IDeleteSubcontractFromProjectParams {
  projectSubcontract: IProjectSubcontract;
  subcontract: ISubcontractDetails;
}
export const deleteSubcontractFromProject = async (params: IDeleteSubcontractFromProjectParams) => {
  const { subcontract, projectSubcontract } = params;
  await axios.delete(`/project-subcontract/${projectSubcontract.id}`);

  const nextScopes = subcontract.subcontractScopes.filter((scope) => scope.project.id !== projectSubcontract.projectId);

  await updateSubcontractScopes(params.subcontract.id, nextScopes);
};

export const getSubcontractEstimates = (projectIds: string[] = []) => {
  return axios.post<ISubcontractEstimate[]>('/subcontracts-all-filtered', {
    subcontractId: null,
    projectIds,
  });
};
