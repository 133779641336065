import { useEffect } from 'react';
import { Link, Outlet, useNavigation } from 'react-router-dom';
import CircularProgressIndicator from './CircularProgressIndicator';
import { SiteTitle } from './SiteTitle';

export default function AuthRoot() {
  useEffect(() => {
    const bodyClassName = 'auth-layout';
    document.body.classList.add(bodyClassName);

    return () => {
      document.body.classList.remove(bodyClassName);
    };
  }, []);

  const navigation = useNavigation();
  const isIdle = navigation.state === 'idle';

  return (
    <>
      <SiteTitle />

      <header className="mb-8 flex justify-center">
        <Link to="/">
          <img src="/images/logo-inscope.svg" width="250" height="50" alt="InScope" />
        </Link>
      </header>

      {isIdle && <Outlet />}

      {!isIdle && (
        <div className="flex items-center justify-center ">
          <CircularProgressIndicator className="text-white" />
        </div>
      )}
    </>
  );
}
