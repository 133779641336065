import invariant from '@app/core/utilities/invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { outdentScopeElement } from '../../api/scopeElement.api';
import { awaitUntil } from '../../core/utilities/awaitUntil';
import type { IProject } from '../../types';
import {
  insertProjectGridRow,
  projectGridRowsSelectors,
  removeProjectGridRows,
  updateProjectGridRowsTreeVersion,
} from '../projectGridRows';
import { ensureProjectGridRows } from '../projectGridRows/ensureProjectGridRows';
import type { IState } from '../store';
import { updateFundDataTree } from './updateFundDataTree';
import { updateProjectGridRows } from './updateProjectGridRows';

interface IOutdentProjectGridRowsProps {
  project: IProject;
  ids: string[];
  transactionId?: string;
}
export const outdentProjectGridRows = createAsyncThunk<void, IOutdentProjectGridRowsProps, { state: IState }>(
  'projectGridRows/outdentProjectGridRows',
  async (props, { getState, dispatch }) => {
    const { project, ids, transactionId } = props;

    // make sure all the row IDs are valid
    const refElements = ensureProjectGridRows(getState().projectGridRows, ids);

    // all elements should have the same parent -> be at the same level
    const parentScopeElementIds = [...new Set(refElements.map((refElement) => refElement.parentScopeElementId))];
    if (parentScopeElementIds.length > 1) {
      throw new Error('Cannot outdent multiple rows with different parents');
    }

    // we cannot outdent a top level element
    const parentScopeElementId = parentScopeElementIds[0];
    invariant(parentScopeElementId, 'Cannot outdent root element');

    // make sure parent is in store
    const parentElement = projectGridRowsSelectors.selectById(getState().projectGridRows, parentScopeElementId);
    invariant(parentElement, 'Could not find parent element for outdentProjectGridRow');

    // new elements will have the same base WBS as their current parent
    const parentWBS = parentElement.wbsFullLabel.split('.').map((wbs) => Number.parseInt(wbs, 10));
    const wbsParentBase = parentWBS.slice(0, -1);
    const wbsCurrentLevelNext = parentWBS[parentWBS.length - 1] + 1;
    const parentBasePath = parentElement.path.slice(0, -1);
    const nextParentScopeElementId = parentElement.parentScopeElementId ?? null;

    const nextProjectGridRows = refElements.map((refElement, index) => {
      const nextWBS = [...wbsParentBase, wbsCurrentLevelNext + index].join('.');

      const nextProjectGridRow = {
        ...refElement,
        wbsFullLabel: nextWBS,
        parentScopeElementId: nextParentScopeElementId,
        path: [...parentBasePath, refElement.id],
      };

      return nextProjectGridRow;
    });

    dispatch(removeProjectGridRows({ projectId: project.id, ids, preserveChildren: true }));

    for (const row of nextProjectGridRows) {
      dispatch(insertProjectGridRow(row));
    }

    await awaitUntil(() => getState().projectGridRows.isProcessingQueue === false);

    await outdentScopeElement({ ids });

    // updated elements include all of the outdented rows and the previous parent row (since it now has different children)
    const updatedElements = ensureProjectGridRows(getState().projectGridRows, [...ids, parentScopeElementId]);

    dispatch(updateProjectGridRows({ projectGridRows: updatedElements, project, transactionId }));
    dispatch(updateProjectGridRowsTreeVersion({ projectId: project.id }));

    for (const id of ids) {
      dispatch(updateFundDataTree({ projectGridRowId: id }));
    }
  }
);
