import { useMemo } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { FEATURE_FLAGS } from '../../config';
import { DropdownMenu, type IDropdownMenuOption } from './DropdownMenu';
import { useReportError } from '../../hooks/useReportError';

export const HelpMenu = () => {
  const reportError = useReportError();
  const options = useMemo(() => {
    const out: IDropdownMenuOption[] = [
      {
        label: 'YouTube Channel',
        externalUrl: 'https://www.youtube.com/@getinscope',
      },
      {
        label: 'Provide Feedback',
        externalUrl: 'https://forms.gle/gSWUfvH7AoRvxk6u5',
      },
      {
        label: 'Help Desk',
        externalUrl: 'https://inscope.helpscoutdocs.com/',
      },
    ];
    if (!FEATURE_FLAGS.disableInlineManual) {
      out.unshift({
        label: 'On-Screen Guide',
        onClick: () => {
          const trigger = document.querySelector<HTMLElement>('.inmplayer-trigger');
          if (trigger) {
            trigger.click();
          } else {
            reportError(new Error('Inline Manual trigger not found'));
          }
        },
      });
    }

    return out;
  }, [reportError]);
  return (
    <DropdownMenu
      label={<FaQuestionCircle className="m-0 size-4 shrink-0" />}
      buttonClassName="bg-app-green-primary hover:bg-app-green-hover w-9 h-9 text-white items-center justify-center flex rounded-lg transition-colors"
      options={options}
    />
  );
};
