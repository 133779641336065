import type { IProjectPreferences } from '@app/types';
import { type AnyAction, createAsyncThunk, type ThunkDispatch } from '@reduxjs/toolkit';
import { setIsProcessingProjectPreferencesQueue, updateProjectPreferencesQueue, upsertProjectPreferences } from '.';
import { createProjectPreferences, updateProjectPreferences } from '../../api/projectUserPreferences.api';
import type { IState } from '../store';

interface IUpdateScopeElementFromQueueParams {
  getState: () => IState;
  dispatch: ThunkDispatch<IState, unknown, AnyAction>;
}

// Iterate over the queue as long as there are any items in it
async function processQueue(params: IUpdateScopeElementFromQueueParams): Promise<void> {
  const { getState, dispatch } = params;

  const queue = [...getState().projectPreferences.updateQueue];

  const nextData = queue.shift();

  if (!nextData) {
    return;
  }
  dispatch(updateProjectPreferencesQueue(queue));

  if (nextData.id) {
    await updateProjectPreferences(nextData);
  } else {
    const serverData = await createProjectPreferences(nextData);
    dispatch(upsertProjectPreferences(serverData));
  }

  return processQueue({ getState, dispatch });
}

// Queue a list of changes to scope element
export const queueProjectPreferencesUpdate = createAsyncThunk<void, IProjectPreferences, { state: IState }>(
  'projectGridRows/queueProjectPreferencesUpdate',
  async (payload, { getState, dispatch }) => {
    dispatch(upsertProjectPreferences(payload));

    const nextQueue = getState().projectPreferences.updateQueue.filter(
      (entry) => entry.projectId !== payload.projectId
    );
    nextQueue.push(payload);

    dispatch(updateProjectPreferencesQueue(nextQueue));

    const isProcessingQueue = getState().projectPreferences.isProcessingQueue;
    if (isProcessingQueue) {
      return;
    }
    dispatch(setIsProcessingProjectPreferencesQueue(true));

    return await processQueue({
      getState,
      dispatch,
    });
  }
);
