import { eachDayOfInterval, parseJSON, startOfDay } from 'date-fns';
import { ALL_WEEKDAYS, ALL_WEEKDAYS_HASH, DEFAULT_SCHEDULE_SETTINGS } from '@app/config';

type IWeekDay = keyof typeof ALL_WEEKDAYS_HASH;

interface IProps {
  interval: {
    start: Date | string | null;
    end: Date | string | null;
  };
  includeWeekends: boolean | null;
  weekdays: string[] | null;
}

const today = startOfDay(new Date());

interface IReturnType {
  calendarDays: Date[];
  activeDays: Date[];
  businessDays: Date[];
}
export function getActiveDaysInInterval(props: IProps): IReturnType {
  const { interval } = props;

  if (!interval.start || !interval.end) {
    return {
      calendarDays: [],
      activeDays: [],
      businessDays: [],
    };
  }

  const start = interval.start instanceof Date ? interval.start : parseJSON(interval.start);
  const end = interval.end instanceof Date ? interval.end : parseJSON(interval.end);

  const days = eachDayOfInterval({ start, end });
  const includeWeekends = props.includeWeekends ?? DEFAULT_SCHEDULE_SETTINGS.INCLUDE_WEEKENDS;
  const weekdays = props.weekdays ?? DEFAULT_SCHEDULE_SETTINGS.WEEKDAYS;
  const workingWeekDays = (includeWeekends ? ALL_WEEKDAYS : weekdays).map((x: IWeekDay) => ALL_WEEKDAYS_HASH[x]);

  const daysWithExcludedWeekend = days.filter((day) => workingWeekDays.includes(day.getDay()));

  // make sure that last day is always active, as will use it as aggregate for all of the tasks in the past
  const lastDayIndex = daysWithExcludedWeekend.length - 1;
  const activeDays = daysWithExcludedWeekend.filter((day, index) => day >= today || index === lastDayIndex);
  return {
    calendarDays: days,
    activeDays,
    businessDays: daysWithExcludedWeekend,
  };
}
