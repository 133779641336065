import type { IFundData, IProjectGridRow } from '@app/types';
import { getProjectGridRowTotalCostValueForFundCalculation } from '../getProjectGridRowTotalCostValueForFundCalculation';
import type { IUpdateProjectGridRowFundDataProps } from './thunkTypes';

export function recalculateValues({
  fundData,
  projectGridRow,
  field,
}: {
  fundData: IFundData;
  projectGridRow: IProjectGridRow;
  field: IUpdateProjectGridRowFundDataProps['field'];
}) {
  const copy = structuredClone(fundData);
  const totalValue = getProjectGridRowTotalCostValueForFundCalculation(copy.inherent, projectGridRow);

  // Changing the value to percentage should clear the amount, and backcalculate percentage
  switch (copy.fundMethod) {
    case 'percentage':
      copy.amount = (totalValue * copy.percentage) / 100;
      break;
    case 'amount':
      copy.percentage = totalValue === 0 ? 0 : (copy.amount / totalValue) * 100;
      break;
    case 'variable':
      // in case of variable, we recalculate the other value, depending on which one was changed
      if (field === 'percentage') {
        copy.amount = (totalValue * copy.percentage) / 100;
      } else if (field === 'amount') {
        copy.percentage = totalValue === 0 ? 0 : (copy.amount / totalValue) * 100;
      }
  }

  return copy;
}
