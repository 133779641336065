import { Portal } from '@headlessui/react';
import type * as React from 'react';

interface IProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const ErrorLayout = (props: IProps) => {
  const { title, children } = props;

  return (
    <Portal>
      <div className="fixed inset-0 z-error-layout flex justify-center bg-app-gray-pale p-8 xl:items-center">
        <div className="flex flex-col gap-16">
          <h1 className="text-5xl font-bold text-app-blue-dark">{title}</h1>

          <div className="flex flex-col gap-6 text-xl leading-relaxed">{children}</div>

          <div className="text-sm">InScope &copy; {new Date().getFullYear()}</div>
        </div>
      </div>
    </Portal>
  );
};
