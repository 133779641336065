import type { ICostDistributionZoomLevel } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectsOverviewCostDistributionSettings = createAsyncThunk<
  void,
  {
    costDistributionZoomLevel?: ICostDistributionZoomLevel;
    costDistributionStartDate?: Date;
    costDistributionEndDate?: Date;
    rememberCostDistributionPeriod?: boolean;
    costDistributionStaticRangeId?: string | null;
  },
  { state: IState }
>('uiView/setProjectsOverviewCostDistributionSettings', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
