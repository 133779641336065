import axios from 'axios';
import { useEffect } from 'react';
import { API_PREFIX } from '@app/config';

axios.defaults.baseURL = API_PREFIX;

interface IProps {
  children: React.ReactNode;
}
export const AxiosConfigProvider = (props: IProps) => {
  const { children } = props;

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          return window.location.reload();
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return children;
};
