import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IProjectFilterSet } from '../../types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectsOverviewAppliedFilterSet = createAsyncThunk<
  void,
  { filterSet: IProjectFilterSet | null; updateFilters?: boolean },
  { state: IState }
>('uiView/setAppliedFilterSet', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
