import { createAsyncThunk } from '@reduxjs/toolkit';
import { upsertOneSubcontractGridRow } from '.';
import type { ISubcontractGridRow } from '../../types';
import type { IState } from '../store';
import { queueSubcontractGridRowUpdate } from './queueSubcontractGridRowUpdate';

/**
 * Forecast value cannot be negative
 */
function processRow(row: ISubcontractGridRow) {
  if (row.forecastValue < 0) {
    return { ...row, forecastValue: 0 };
  }
  return row;
}

export const upsertSubcontractGridRow = createAsyncThunk<void, ISubcontractGridRow, { state: IState }>(
  'subcontractGridRows/upsertSubcontractGridRow',
  async (row, { dispatch }) => {
    const nextRow = processRow(row);
    dispatch(upsertOneSubcontractGridRow(nextRow));
    dispatch(queueSubcontractGridRowUpdate(nextRow));
  }
);
