import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IWorkloadFilterSetFields } from '../../types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setWorkloadFilters = createAsyncThunk<void, Partial<IWorkloadFilterSetFields>, { state: IState }>(
  'uiView/setWorkloadFilters',
  (_, { dispatch }) => {
    dispatch(persistUserPreferences());
  }
);
