import { useEffect } from 'react';
import CircularProgressIndicator from './CircularProgressIndicator';

export const AppLoader = () => {
  useEffect(() => {
    const bodyClassName = 'auth-layout';
    document.body.classList.add(bodyClassName);

    return () => {
      document.body.classList.remove(bodyClassName);
    };
  }, []);

  return (
    <>
      <div className="mb-8 flex justify-center">
        <img src="/images/logo-inscope.svg" width="250" height="50" alt="InScope" />
      </div>
      <div className="flex items-center justify-center ">
        <CircularProgressIndicator className="text-white" />
      </div>
    </>
  );
};
