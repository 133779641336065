import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IUnitOfMeasure } from '../types';
import type { IState } from './store';

const adapter = createEntityAdapter<IUnitOfMeasure>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'unitsOfMeasure',
  initialState,
  reducers: {
    setAllUnitsOfMeasure: adapter.setAll,
    removeOneUnitOfMeasure: adapter.removeOne,
    updateOneUnitOfMeasure: adapter.updateOne,
    addOneUnitOfMeasure: adapter.addOne,
  },
});

export const unitsOfMeasureSelectors = adapter.getSelectors();

export const selectUnitOfMeasureByName = createSelector(
  [(state: IState) => state.unitsOfMeasure, (_state: IState, name: string) => name],
  (state, name) => {
    const item = unitsOfMeasureSelectors.selectAll(state).find((i) => i.name === name);
    invariant(item, `Could not find unit of measure with name ${name}`);
    return item;
  }
);

export const { setAllUnitsOfMeasure, removeOneUnitOfMeasure, updateOneUnitOfMeasure, addOneUnitOfMeasure } =
  slice.actions;

export default slice.reducer;
