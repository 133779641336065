import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IFinanceElementType } from '../types';
import type { IState } from './store';

const adapter = createEntityAdapter<IFinanceElementType>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'financeElementTypes',
  initialState,
  reducers: {
    setAllFinanceElementTypes: adapter.setAll,
  },
});

export const financeElementTypeSelectors = adapter.getSelectors();

export const selectFinanceElementTypeByName = createSelector(
  [(state: IState) => state.financeElementTypes, (_state: IState, name: string) => name],
  (state, name) => {
    const type = financeElementTypeSelectors.selectAll(state).find((t) => t.name === name);
    invariant(type, `Finance element type with name ${name} not found`);
    return type;
  }
);

export const { setAllFinanceElementTypes } = slice.actions;

export default slice.reducer;
