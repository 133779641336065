import { store } from '@app/store';
import { selectFinanceSectionInputModesByGuid } from '@app/store/selectors/selectFinanceSectionInputModesByGuid';
import type {
  IFinanceSectionInputModeByGuidConfig,
  IFinancialColumnConfig,
  IProjectGridRow,
  IScopeElementProjectResource,
  ISubcontractGridRow,
} from '@app/types';
import { findScopeElementProjectResource } from './findScopeElementProjectResource';
import { getEmptyProjectGridRowAggregateData } from './getEmptyProjectGridRowAggregateData';
import { getProjectGridSubcontractTotal } from './getProjectGridSubcontractTotal';
import { getProjectResourceInputMode } from './getProjectResourceInputMode';

function getResourcesTotalFromColumns(
  resources: IScopeElementProjectResource[],
  columns: IFinancialColumnConfig[],
  inputModes: IFinanceSectionInputModeByGuidConfig
) {
  return columns.reduce((acc, column) => {
    const resource = findScopeElementProjectResource(resources, column.projectResource);
    if (!resource) {
      return acc;
    }
    const inputMode = getProjectResourceInputMode(column.projectResource, inputModes);

    if (inputMode === 'cost') {
      return acc + (resource.cost ?? 0);
    }

    return acc + (resource.units ?? 0) * (column.rate ?? 0);
  }, 0);
}

export function calculateProjectGridRowAggregates(
  projectGridRow: Omit<IProjectGridRow, 'agg'>,
  subcontractGridRows: ISubcontractGridRow[]
): IProjectGridRow {
  const agg = getEmptyProjectGridRowAggregateData();

  const inputModesByGuid = selectFinanceSectionInputModesByGuid(store.getState());
  const financeColumns = store.getState().projectGridRows.financeColumnData;

  for (const selectedSectionId of ['budget', 'actual', 'remaining', 'forecast'] as const) {
    const bucket = `${selectedSectionId}Resources` as const;
    const resources = projectGridRow[bucket];

    const subcontract = getProjectGridSubcontractTotal(selectedSectionId, subcontractGridRows);

    const personnel = getResourcesTotalFromColumns(
      resources,
      financeColumns[selectedSectionId]?.personnel ?? [],
      inputModesByGuid
    );
    const equipment = getResourcesTotalFromColumns(
      resources,
      financeColumns[selectedSectionId]?.equipment ?? [],
      inputModesByGuid
    );
    const expense = getResourcesTotalFromColumns(
      resources,
      financeColumns[selectedSectionId]?.expense ?? [],
      inputModesByGuid
    );

    agg[selectedSectionId] = {
      total: subcontract + personnel + equipment + expense,
      subcontract,
      personnel,
      equipment,
      expense,
    };
  }

  agg.totalCost = agg.forecast.total + agg.actual.total;

  return { ...projectGridRow, agg };
}
