import { useCallback, useState } from 'react';

export function useDialogState() {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(() => setIsOpen(true), []);
  const onRequestClose = useCallback(() => setIsOpen(false), []);

  return [isOpen, openDialog, onRequestClose] as const;
}
