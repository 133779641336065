import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectStatusReportScopeElementPeriod = createAsyncThunk<
  void,
  {
    zoomLevel?: string;
    startDate?: Date;
    endDate?: Date;
    rememberPeriod?: boolean;
    staticRangeId?: string | null;
  },
  { state: IState }
>('uiView/setProjectStatusReportScopeElementPeriod', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
