import type { ITimesheetRow } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { addOneTimesheetRow, timesheetRowSelectors, updateOneTimesheetRow } from '.';
import type { IState } from '../store';
import { queueTimesheetEntryTransaction } from '../timesheet/queueTimesheetEntryTransaction';
import { selectTimesheetRowEntries, updateOneTimesheetEntry } from '../timesheetEntries';
import { generateNewTimesheetRowFromEntry } from '../timesheetEntries/generateNewTimesheetRowFromEntry';

export const updateTimesheetRow = createAsyncThunk<
  void,
  { rowGroup: string; changes: Partial<ITimesheetRow> },
  { state: IState }
>('timesheetRows/updateTimesheetRow', async (props, { getState, dispatch }) => {
  const { rowGroup, changes } = props;

  const row = timesheetRowSelectors.selectById(getState().timesheetRows, rowGroup);
  invariant(row, 'Row not found');

  const entries = selectTimesheetRowEntries(getState(), rowGroup);
  const entryIds = entries.map((e) => e.id);

  const submittedEntries = entries.filter((e) => !!e.submitted);
  const submittedEntryIds = submittedEntries.map((e) => e.id);
  const unsubmittedEntries = entries.filter((e) => !e.submitted);
  const unsubmittedEntryIds = unsubmittedEntries.map((e) => e.id);

  // This thunk is called, when we change one of the row dropdowns. We don't want to move the row
  // the user is currently working on, so instead of moving the unsubmitted entries, we move the submitted ones
  // to the new row. We have to update all entries though, because the rowGroup is part of the entry.
  if (submittedEntries.length > 0) {
    const entry = submittedEntries[0];
    const newRow = generateNewTimesheetRowFromEntry(entry);
    newRow.timesheetEntryIds = submittedEntryIds;
    dispatch(addOneTimesheetRow(newRow));

    // update row group on the unsubmitted entries
    for (const entryId of submittedEntryIds) {
      dispatch(updateOneTimesheetEntry({ id: entryId, changes: { rowGroup: newRow.rowGroup } }));
    }
    dispatch(updateOneTimesheetRow({ id: rowGroup, changes: { timesheetEntryIds: unsubmittedEntryIds } }));
  }

  // Update unsubmitted entries with changes coming from the row
  for (const entryId of unsubmittedEntryIds) {
    dispatch(updateOneTimesheetEntry({ id: entryId, changes }));
  }

  // Queue updates to all entries
  for (const entryId of entryIds) {
    dispatch(queueTimesheetEntryTransaction({ id: entryId, type: 'update' }));
  }

  // Update current row
  dispatch(updateOneTimesheetRow({ id: rowGroup, changes }));
});
