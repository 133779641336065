import type { IFinanceSectionInputModeByGuidConfig, IProjectResource } from '@app/types';
import invariant from '@app/core/utilities/invariant';

export function getProjectResourceInputMode(
  projectResource: IProjectResource,
  lookup: IFinanceSectionInputModeByGuidConfig
) {
  const inputMode = lookup[projectResource.financeElementTypeId][projectResource.resourceTypeId];
  invariant(inputMode, `No input mode found for project resource ${projectResource.id}`);
  return inputMode;
}
