import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ISubcontractorsFilterRequest } from '../types';

interface ISubcontractorsListState {
  filterSetFields: ISubcontractorsFilterRequest;
}

const initialFilterSetFields: ISubcontractorsFilterRequest = {
  searchText: '',
  projectIds: [],
};

const initialState: ISubcontractorsListState = {
  filterSetFields: { ...initialFilterSetFields },
};

const subcontractorsListSlice = createSlice({
  name: 'subcontractorsList',
  initialState,
  reducers: {
    setSubcontractsListProjectIds(state, action: PayloadAction<{ projectIds: string[] }>) {
      state.filterSetFields.projectIds = action.payload.projectIds;
    },
    setSubcontractsListSearchText(state, action: PayloadAction<{ searchText: string }>) {
      state.filterSetFields.searchText = action.payload.searchText;
    },
    clearSubcontractorsListFilters(state) {
      state.filterSetFields = { ...initialFilterSetFields };
    },
  },
});
export const { clearSubcontractorsListFilters, setSubcontractsListProjectIds, setSubcontractsListSearchText } =
  subcontractorsListSlice.actions;

export default subcontractorsListSlice.reducer;
