import { indexArrToWbs } from '@app/core/utilities/grid/indexArrToWbs';
import type { IFundData, IProjectGridRow, IScopeElement, ISubcontractGridRow } from '@app/types';
import { calculateProjectGridRowAggregates } from './calculateProjectGridRowAggregates';

export interface IMapScopeElementToProjectGridRowParams {
  scopeElement: IScopeElement;
  indexArr?: number[] | string;
  wbsFullLabel?: string;
  path?: string[];
  rowId?: number;
  dependencyString?: string;
  children?: string[];
  parentScopeElementId?: string | null;
  subcontractGridRows?: ISubcontractGridRow[];
  subcontractGridRowIds?: string[];
  fundData: IFundData[];
}

export function mapScopeElementToProjectGridRow(params: IMapScopeElementToProjectGridRowParams): IProjectGridRow {
  const {
    scopeElement,
    indexArr,
    wbsFullLabel,
    path = [],
    dependencyString = '',
    rowId,
    children = [],
    subcontractGridRowIds,
    subcontractGridRows = [],
    fundData,
  } = params;

  const nextSubcontractGridRowIds = subcontractGridRowIds ?? subcontractGridRows.map(({ id }) => id);

  const nextWbsFullLabel = Array.isArray(indexArr)
    ? indexArrToWbs(indexArr)
    : wbsFullLabel ?? scopeElement.wbsFullLabel;

  const out = calculateProjectGridRowAggregates(
    {
      ...scopeElement,
      wbsFullLabel: nextWbsFullLabel,
      path,
      dependencyString,
      children,
      subcontractGridRows,
      subcontractGridRowIds: nextSubcontractGridRowIds,
      costDistribution: scopeElement.costDistribution ?? [],
      fundData,
    },
    subcontractGridRows
  );

  return rowId ? { ...out, rowId } : out;
}
