import { updateBudgetNote } from '@app/api/budgetNotes.api';
import type { IBudgetNote } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { updateOneBudgetGridRow } from '../budgetGridRows';

export const updateBudgetGridNote = createAsyncThunk<void, { rowId: string; note: IBudgetNote }, { state: IState }>(
  'budgetGridData/updateBudgetGridNote',

  async (params, { dispatch }) => {
    const { rowId, note } = params;
    dispatch(updateOneBudgetGridRow({ id: rowId, changes: { note } }));
    updateBudgetNote(note);
  }
);
