import {
  FINANCE_CELL_EMPTY_PLACEHOLDER,
  type IFinanceCellEmptyPlaceholder,
  SUBCONTRACT_GRID_TOTAL_ROW_ID,
} from '@app/config';
import type { IState } from '@app/store';
import type { ISubcontractBillValue, ISubcontractLimitValue } from '@app/types';
import { createSelector } from '@reduxjs/toolkit';
import { adapter } from '.';

const subcontractGridRowsSelectors = adapter.getSelectors();

export const selectSubcontractGridRowsBySubcontractId = createSelector(
  [
    (state: IState) => subcontractGridRowsSelectors.selectAll(state.subcontractGridRows),
    (_state: IState, subcontractId: string) => subcontractId,
  ],
  (rows, subcontractId) => {
    return rows.filter((row) => row.subcontractId === subcontractId);
  }
);

export const selectSubcontractGridRowsByScopeElementId = createSelector(
  [
    (state: IState) => subcontractGridRowsSelectors.selectAll(state.subcontractGridRows),
    (_state: IState, scopeElementId: string) => scopeElementId,
  ],
  (rows, scopeElementId) => {
    return rows.filter((row) => row.projectScopeId === scopeElementId);
  }
);

interface ISubcontractGridTotals {
  forecastValue: number | IFinanceCellEmptyPlaceholder;
  billValues: ISubcontractBillValue[];
  limitValues: ISubcontractLimitValue[];
}

export const selectSubcontractGridTotalsBySubcontractId = createSelector(
  [
    (state: IState) => subcontractGridRowsSelectors.selectAll(state.subcontractGridRows),
    (_state: IState, params: { subcontractId: string; pursuitStatusId?: string }) => params,
  ],
  (rows, { subcontractId, pursuitStatusId }): ISubcontractGridTotals => {
    const billDict: Map<string, ISubcontractBillValue> = new Map();
    const limitDict: Map<string, ISubcontractLimitValue> = new Map();

    return rows.reduce(
      (acc, row) => {
        if (row.subcontractId !== subcontractId) {
          return acc;
        }

        for (const billValue of row.billValues) {
          const existing = billDict.get(billValue.subcontractBillId);
          billDict.set(billValue.subcontractBillId, {
            ...billValue,
            subcontractScopeElementId: SUBCONTRACT_GRID_TOTAL_ROW_ID,
            value: (existing?.value ?? 0) + billValue.value,
          });
        }

        for (const limitValue of row.limitValues) {
          const existing = limitDict.get(limitValue.subcontractLimitId);
          limitDict.set(limitValue.subcontractLimitId, {
            ...limitValue,
            subcontractScopeElementId: SUBCONTRACT_GRID_TOTAL_ROW_ID,
            value: (existing?.value ?? 0) + limitValue.value,
          });
        }

        const forecastValue =
          row.project.projectStatusId !== pursuitStatusId && acc.forecastValue !== FINANCE_CELL_EMPTY_PLACEHOLDER
            ? acc.forecastValue + row.forecastValue
            : FINANCE_CELL_EMPTY_PLACEHOLDER;

        return {
          billValues: Array.from(billDict.values()),
          limitValues: Array.from(limitDict.values()),
          forecastValue,
        };
      },
      { forecastValue: 0, billValues: [], limitValues: [] } as ISubcontractGridTotals
    );
  }
);

export const selectSubcontractIdsWithNonZeroBillValues = createSelector(
  [(state: IState) => subcontractGridRowsSelectors.selectAll(state.subcontractGridRows)],
  (rows) => {
    return rows.reduce((acc, row) => {
      for (const billValue of row.billValues) {
        if (billValue.value > 0) {
          acc.add(row.subcontractId);
        }
      }

      return acc;
    }, new Set<string>());
  }
);
