import { ErrorLayout } from './ErrorLayout';

interface IProps {
  title?: string;
}
export function AppErrorFallbackUI(props: IProps) {
  const { title } = props;

  return (
    <ErrorLayout title={title ?? 'Oh, no!'}>
      <p className="text-2xl font-bold">This page is not working as indended.</p>

      <p>We will fix it as soon as possible. In the meantime, here is what you can do:</p>

      <ul className="list-disc space-y-4 pl-6">
        <li>
          <button className="link" type="button" onClick={() => window.location.reload()}>
            Refresh the page
          </button>{' '}
          - sometimes, it’s a temporary glitch in the system.
        </li>
        <li>
          <button className="link" type="button" onClick={() => window.history.go(-1)}>
            Go to the previous page
          </button>{' '}
          and repeat your last action.
        </li>
      </ul>
      <p>We are sorry for the inconvenience.</p>
    </ErrorLayout>
  );
}
