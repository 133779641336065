import { type Draft, createSelector } from '@reduxjs/toolkit';
import type { IProjectGridRow } from '@app/types';
import type { IState } from '@app/store';

// Create a redux selector that will return all ascendants of a project grid row

// Recursive function to get all ascendants of a project grid row
const getAllAscendants = (
  rows: Draft<Record<string, IProjectGridRow>>,
  scopeElementId: string | null | undefined,
  ascendants: IProjectGridRow[] = []
): IProjectGridRow[] => {
  const parent = rows[rows[scopeElementId ?? '']?.parentScopeElementId ?? ''];
  if (parent) {
    ascendants.push(parent);
    return getAllAscendants(rows, parent.id, ascendants);
  }
  return ascendants;
};

// Redux selector to select all ascendants of a project grid row
export const selectAllProjectGridRowAscendants = createSelector(
  (state: IState) => state.projectGridRows.entities,
  (_state: IState, scopeElementId: string | undefined | null) => scopeElementId,
  (rows, scopeElementId) => getAllAscendants(rows, scopeElementId)
);
