import { FaSave } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LoadingButton } from '../LoadingButton';

interface IProps {
  label?: string;
  isSubmitting: boolean;
  cancelUrl?: string;
  onCancel?: () => void;
  readOnly?: boolean;
}

export const FormActions = (props: IProps) => {
  const { label = 'Save', isSubmitting, cancelUrl, onCancel, readOnly } = props;
  return (
    <div className="flex flex-wrap items-center">
      <LoadingButton type="submit" className="btn-green mr-2" isProcessing={isSubmitting} disabled={readOnly}>
        <FaSave className="mr-2" />
        {label}
      </LoadingButton>
      {cancelUrl && (
        <Link to={cancelUrl} className="btn-white">
          Cancel
        </Link>
      )}
      {onCancel && (
        <button type="button" className="btn-white" onClick={onCancel}>
          Cancel
        </button>
      )}
    </div>
  );
};
