import { IconFaArrowUpRightFromSquare } from '@app/icons/IconFaArrowUpRightFromSquare';
import { Menu, Portal } from '@headlessui/react';
import clsx from 'clsx';
import type * as React from 'react';
import { useState } from 'react';
import { usePopper } from 'react-popper';

interface IBaseDropdownMenuOption {
  label: string;
  hint?: string;
}
interface IButtonMenuOption extends IBaseDropdownMenuOption {
  onClick: () => void;
}

interface IExternalUrlMenuOption extends IBaseDropdownMenuOption {
  externalUrl: string;
}

export type IDropdownMenuOption = IButtonMenuOption | IExternalUrlMenuOption;

interface IProps {
  /**
   * The options to display in the dropdown menu.
   * Each option can be either a button or an external link.
   * If the option is a button, it must have an `onClick` handler.
   * If the option is an external link, it must have an `externalUrl`.
   * If the option has a `hint`, it will be displayed as a tooltip.
   * If the option has a `label`, it will be displayed as a label.
   */
  options: IDropdownMenuOption[];
  /**
   * The label to display on the button.
   */
  label: React.ReactNode | string;
  /**
   * The placement of the menu relative to the button.
   * @default 'bottom'
   */
  placement?: 'bottom-start' | 'bottom' | 'bottom-end';
  /**
   * Class name for the wrapper element
   */

  wrapperClassName?: string;
  /**
   * Class name for the menu button
   * @default 'inline-block cursor-pointer text-2xl'
   */
  buttonClassName?: string;

  /**
   * Class name for the menu element
   * @default 'w-56'
   */
  menuClassName?: string;
}

const triangleStyle = {
  clipPath: 'polygon(50% 0, 100% 100%, 0% 100%)',
};
const triangleWrapperStyles = {
  filter: 'drop-shadow(0 -2px 2px rgb(0 0 0 / 0.1))',
};

export function DropdownMenu(props: IProps) {
  const {
    options,
    label,
    buttonClassName,
    wrapperClassName = 'relative inline-block',
    placement = 'bottom',
    menuClassName,
  } = props;

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  return (
    <Menu as="div" className={wrapperClassName}>
      <Menu.Button ref={setReferenceElement} className={buttonClassName}>
        {label}
      </Menu.Button>

      <Portal>
        <Menu.Items>
          <div
            className={clsx(
              'absolute z-dialog rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
              menuClassName
            )}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <span
              className={clsx('pointer-events-none absolute -top-2', {
                'left-6': placement === 'bottom-start',
                'left-1/2 -translate-x-1/2': placement === 'bottom',
                'right-6': placement === 'bottom-end',
              })}
              style={triangleWrapperStyles}
            >
              <span style={triangleStyle} className="block h-2 w-4 bg-white" />
            </span>
            <ul className="divide-y divide-gray-100">
              {options.map((menuOption, index) => (
                <li className="p-1" key={index}>
                  {'externalUrl' in menuOption && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={menuOption.externalUrl}
                          target="_blank"
                          rel="noreferrer"
                          className={clsx('block w-full rounded px-3 py-2 text-left text-sm', {
                            'bg-app-blue-primary text-white': active,
                          })}
                        >
                          <span className="flex items-center gap-2">
                            {menuOption.label}{' '}
                            <IconFaArrowUpRightFromSquare className="size-3" aria-label="External link" />
                          </span>
                          {typeof menuOption.hint === 'string' && (
                            <span className={clsx('mt-1 block text-xs text-app-gray-dark', { 'text-white': active })}>
                              {menuOption.hint}
                            </span>
                          )}
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {'onClick' in menuOption && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={clsx('w-full rounded px-3 py-2 text-left text-sm', {
                            'bg-app-blue-primary text-white': active,
                          })}
                          onClick={menuOption.onClick}
                        >
                          {menuOption.label}
                          {typeof menuOption.hint === 'string' && (
                            <span className={clsx('mt-1 block text-xs text-app-gray-dark', { 'text-white': active })}>
                              {menuOption.hint}
                            </span>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Menu.Items>
      </Portal>
    </Menu>
  );
}
