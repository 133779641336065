import { getActiveDaysInInterval } from '../../getActiveDaysInInterval';
import type { IWorkloadGridRow } from '@app/types';

export function getWorkloadGridRowDays(workloadGridRow: IWorkloadGridRow) {
  return getActiveDaysInInterval({
    interval: { start: workloadGridRow.startDate, end: workloadGridRow.finishDate },
    includeWeekends: workloadGridRow.includeWeekends,
    weekdays: workloadGridRow.weekdays,
  });
}
