import type { IFinanceSocketSectionId, ISubcontractGridRow } from '@app/types';

export function getProjectGridSubcontractTotal(
  sectionId: IFinanceSocketSectionId,
  subcontractGridRows: ISubcontractGridRow[]
) {
  const limitTotal = subcontractGridRows.reduce((acc, { limitValues }) => {
    return acc + limitValues.reduce((acc2, { value }) => acc2 + value, 0);
  }, 0);

  const billTotal = subcontractGridRows.reduce((acc, { billValues }) => {
    return acc + billValues.reduce((acc2, { value }) => acc2 + value, 0);
  }, 0);

  const remainingTotal = limitTotal - billTotal;
  const forecastTotal = subcontractGridRows.reduce((acc, cur) => acc + cur.forecastValue, 0);

  switch (sectionId) {
    case 'budget':
      return limitTotal;
    case 'actual':
      return billTotal;
    case 'remaining':
      return remainingTotal;
    case 'forecast':
      return forecastTotal;
  }
}
