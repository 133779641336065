import { type IProjectGridRowsSliceState, projectGridRowsSelectors } from '.';
import type { IProjectGridRow } from '../../types';
export function getProjectGridSiblingRows(state: IProjectGridRowsSliceState, projectGridRow: IProjectGridRow) {
  const siblingRows = projectGridRowsSelectors
    .selectAll(state)
    .filter(
      (element) =>
        element.projectId === projectGridRow.projectId &&
        element.parentScopeElementId === projectGridRow.parentScopeElementId &&
        !element.newRow
    );
  siblingRows.sort((a, b) => a.wbsFullLabel.localeCompare(b.wbsFullLabel, undefined, { numeric: true }));

  return siblingRows;
}
