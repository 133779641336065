import type { ITimesheetRow } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeOneTimesheetRow } from '.';
import type { IState } from '../store';
import { queueTimesheetEntryTransaction } from '../timesheet/queueTimesheetEntryTransaction';
import { removeManyTimesheetEntries, selectTimesheetRowEntries } from '../timesheetEntries/index';

export const deleteTimesheetRow = createAsyncThunk<void, ITimesheetRow, { state: IState }>(
  'timesheetRows/deleteTimesheetRow',
  async (props, { getState, dispatch }) => {
    const row = props;

    const entries = selectTimesheetRowEntries(getState(), row.rowGroup);

    const nonSubmittedEntries = entries.filter((entry) => !entry.submitted);

    const transactionEntries = entries.filter((entry) => !entry.submitted && (entry.isPersisted || entry.isProcessing));

    if (nonSubmittedEntries.length > 0) {
      const ids = nonSubmittedEntries.map((entry) => entry.id);
      dispatch(removeManyTimesheetEntries(ids));
    }

    for (const entry of transactionEntries) {
      dispatch(queueTimesheetEntryTransaction({ id: entry.id, type: 'remove' }));
    }

    // remove entry row if all entries have been removed
    if (entries.length === nonSubmittedEntries.length) {
      dispatch(removeOneTimesheetRow(row.rowGroup));
    }
  }
);
