import { clsx } from 'clsx';
import type * as React from 'react';

interface IProps {
  /**
   * The HTML element to use for the title.
   * @default 'h2'
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * Class name for the title element
   * @default 'mb-4'
   */
  className?: string;
  /**
   * The title text
   */
  children: React.ReactNode;
}

export const AdminSectionTitle = (props: IProps) => {
  const { as = 'h3', children, className = 'mb-4' } = props;
  const Element = as;
  return <Element className={clsx(className, ' font-bold text-app-blue-dark')}>{children}</Element>;
};
