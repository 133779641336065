import type { ISubcontractBillValue, ISubcontractGridRow } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IState } from '../store';
import { upsertSubcontractGridRow } from './upsertSubcontractGridRow';

interface IProps {
  id: string;
  subcontractBillId: string;
  value: number;
}

function getNextRowBillValues(row: ISubcontractGridRow, nextBill: ISubcontractBillValue) {
  const nextBillValues = structuredClone(row.billValues);

  if (!nextBillValues.find((bill) => bill.id === nextBill.id)) {
    nextBillValues.push(nextBill);
    return nextBillValues;
  }
  return nextBillValues.map((bill) => (bill.id === nextBill.id ? nextBill : bill));
}

export const setSubcontractGridRowBillValue = createAsyncThunk<void, IProps, { state: IState }>(
  'subcontractGridRows/setBillValue',
  async (props, { getState, dispatch }) => {
    const { id, subcontractBillId, value } = props;
    const row = getState().subcontractGridRows.entities[id];
    invariant(row);

    const currentBill = row.billValues.find((billValue) => billValue.subcontractBillId === subcontractBillId);

    const nextBill = currentBill
      ? { ...currentBill, value }
      : {
          id: crypto.randomUUID(),
          subcontractBillId,
          subcontractScopeElementId: row.id,
          value,
          projectScopeId: row.projectScopeId,
        };

    const delta = currentBill ? value - currentBill.value : value;
    const nextForecastValue = row.forecastValue - delta;
    const nextRow = { ...row, billValues: getNextRowBillValues(row, nextBill), forecastValue: nextForecastValue };

    dispatch(upsertSubcontractGridRow(nextRow));
  }
);
