import { shortISODateFormat } from '@app/config';
import invariant from '@app/core/utilities/invariant';
import { roundTwoDecimalPlaces } from '@app/core/utilities/roundTwoDecimalPlaces';
import type { IBudgetGridColDefMeta, IBudgetGridRow, IPeriodicProjectBudgetData } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { recalculateBudgetTotals, updateOneBudgetGridRow } from '.';
import { updateBudgetGridData } from '../budgetGridData/updateBudgetGridData';
import { updateBudgetGridNote } from '../budgetGridData/updateBudgetGridNote';
import type { IState } from '../store';

interface UpdateBudgetGridRowProps {
  data: IBudgetGridRow;
  colMeta: IBudgetGridColDefMeta;
  newValue: number | string;
}
interface IGetNewBudgetDataItemProps {
  projectId: string;
  period: string;
  fundId: string | null;
  budgetAdjustmentId: string | null;
}
function getNewBudgetDataItem(params: IGetNewBudgetDataItemProps): IPeriodicProjectBudgetData {
  const { projectId, period, fundId, budgetAdjustmentId } = params;
  return {
    id: crypto.randomUUID(),
    projectId,
    period,
    value: 0,
    fundId,
    budgetAdjustmentId,
    cost: 0,
  };
}

function getNextBudgetItem({
  state,
  projectId,
  periodString,
  budgetAdjustmentId,
  fundId,
}: {
  state: IState;
  projectId: string;
  periodString: string;
  budgetAdjustmentId: string | null;
  fundId: string | null;
}) {
  return (
    Object.values(state.budgetGridData.entities).find((item) => {
      if (!item) {
        return false;
      }
      if (
        item.projectId === projectId &&
        item.period === periodString &&
        item.fundId === fundId &&
        item.budgetAdjustmentId === budgetAdjustmentId
      ) {
        return true;
      }
      return false;
    }) ??
    getNewBudgetDataItem({
      projectId,
      period: periodString,
      fundId,
      budgetAdjustmentId,
    })
  );
}

/**
 * Calculates the values for each period based on the total value and the number of periods.
 * The last period value is adjusted to account for rounding differences.
 *
 * @param total - The total value.
 * @param periodCount - The number of periods.
 * @returns An array of period values.
 */
function getPeriodValues(total: number, periodCount: number) {
  const periodValue = periodCount > 0 ? roundTwoDecimalPlaces(total / periodCount) : 0;
  const periodValues = Array.from({ length: periodCount }, () => periodValue);
  const roundingDeltaInCents = total * 100 - periodValues.reduce((acc, value) => acc + value * 100, 0);
  periodValues[periodValues.length - 1] += roundTwoDecimalPlaces(roundingDeltaInCents / 100);
  return periodValues;
}
export const updateBudgetGridRow = createAsyncThunk<void, UpdateBudgetGridRowProps, { state: IState }>(
  'budgetGridRows/updateBudgetGridRow',

  async (params, { getState, dispatch }) => {
    const { data, colMeta, newValue } = params;

    if (data.level !== 'project') {
      throw new Error('Only project level rows can be edited');
    }

    const budgetAdjustmentId = data.financeType === 'adjustment' ? data.budgetAdjustmentId : null;

    // update notes
    if (colMeta.colId === 'project-notes') {
      if (data.financeType !== 'baseBudget' && data.financeType !== 'adjustment') {
        throw new Error('Only base budget and adjustment rows can be edited');
      }
      if (typeof newValue !== 'string') {
        throw new Error('Only strings can be edited');
      }
      const note = structuredClone(data.note) ?? {
        id: crypto.randomUUID(),
        projectId: data.projectId,
        budgetAdjustmentId,
        notes: '',
      };
      note.notes = newValue;
      dispatch(updateBudgetGridNote({ rowId: data.id, note }));
      return;
    }

    // update funds
    if (typeof newValue === 'string') {
      throw new Error('Only numbers can be edited');
    }
    const periodGroup = colMeta.periodGroup;
    invariant(periodGroup, 'Period group is required on editable columns');

    const bucket = colMeta.fundId ? data.fundData[colMeta.fundId] : data.budgetData;
    invariant(bucket, 'Budget data bucket not found');

    const periodValues = getPeriodValues(newValue, periodGroup.length);

    const nextDataItems = periodGroup.map((period, index) => {
      const periodString = format(period.date, shortISODateFormat);
      const nextItem = structuredClone(
        getNextBudgetItem({
          state: getState(),
          projectId: data.projectId,
          periodString,
          budgetAdjustmentId,
          fundId: colMeta.fundId ?? null,
        })
      );
      nextItem.value = periodValues[index];
      return nextItem;
    });

    const { fundId } = colMeta;
    if (fundId) {
      const nextData = structuredClone(data.fundData);
      periodGroup.forEach((period, index) => {
        nextData[fundId][format(period.date, shortISODateFormat)] = periodValues[index];
      });
      dispatch(updateOneBudgetGridRow({ id: data.id, changes: { fundData: nextData } }));
    } else {
      const nextData = structuredClone(data.budgetData);
      periodGroup.forEach((period, index) => {
        nextData[format(period.date, shortISODateFormat)] = periodValues[index];
      });
      dispatch(updateOneBudgetGridRow({ id: data.id, changes: { budgetData: nextData } }));
    }

    dispatch(updateBudgetGridData(nextDataItems));
    dispatch(recalculateBudgetTotals({ rowId: data.id, fundId: colMeta.fundId }));
  }
);
