import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IProjectGridRow } from '../../types';
import { projectGridRowsSelectors } from '../projectGridRows';
import type { IState } from '../store';
import { queueScopeElementsUpdate } from './queueScopeElementsUpdate';
import { prepareScopeElementUpdate } from './prepareScopeElementUpdate';

interface ICreateProjectGridRowProps {
  projectGridRow: IProjectGridRow;
  parentId: string | null | undefined;
  transactionId?: string;
}
export const createProjectGridRow = createAsyncThunk<void, ICreateProjectGridRowProps, { state: IState }>(
  'projectGridRows/createProjectGridRow',
  async (props, { getState, dispatch }) => {
    const {
      projectGridRow: { id },
      parentId,
      transactionId,
    } = props;

    // find the previous element in the list
    const projectGridRow = projectGridRowsSelectors.selectById(getState().projectGridRows, id);

    invariant(projectGridRow, 'projectGridRow not found');

    const parentRow = parentId ? projectGridRowsSelectors.selectById(getState().projectGridRows, parentId) : undefined;
    const nextPath = parentRow ? parentRow.path : [];
    const scopeElement = prepareScopeElementUpdate(projectGridRow);

    dispatch(
      queueScopeElementsUpdate({
        data: [
          {
            scopeElement,
            overrides: () => {
              const fundData = getState().projectGridRows.entities[id].fundData ?? [];
              return { path: [...nextPath, id], parentScopeElementId: parentId, fundData };
            },
          },
        ],
        transactionId,
      })
    );
  }
);
