import axios from 'axios';
import type { IBudgetNote } from '@app/types';

export const getBudgetNotes = () => {
  return axios.get<IBudgetNote[]>('/project-budgets-notes');
};

export const updateBudgetNote = (data: IBudgetNote) => {
  return axios.post<IBudgetNote>('/project-budgets-notes', data);
};
