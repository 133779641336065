import type { IGridColDefMeta, IProjectPreferences, IToggleableProjectGridSectionId } from '@app/types';
import { createEntityAdapter, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IState } from '../store';
import { queueProjectPreferencesUpdate } from './queueProjectPreferencesUpdate';

const adapter = createEntityAdapter<IProjectPreferences, string>({
  selectId: (projectPreferences) => projectPreferences.projectId,
});

const selectors = adapter.getSelectors();

const initialState = adapter.getInitialState({
  isProcessingQueue: false,
  updateQueue: [] as IProjectPreferences[],
});

const projectPreferencesSlice = createSlice({
  name: 'projectPreferences',
  initialState,
  reducers: {
    setProjectPreferences: adapter.upsertOne,
    upsertProjectPreferences(state, action: PayloadAction<IProjectPreferences>) {
      return adapter.upsertOne(state, action.payload);
    },
    updateProjectPreferencesQueue(state, action: PayloadAction<IProjectPreferences[]>) {
      state.updateQueue = action.payload;
    },
    setIsProcessingProjectPreferencesQueue(state, action: PayloadAction<boolean>) {
      state.isProcessingQueue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queueProjectPreferencesUpdate.fulfilled, (state) => {
      state.isProcessingQueue = false;
    });
  },
});

export const {
  setProjectPreferences,
  upsertProjectPreferences,
  updateProjectPreferencesQueue,
  setIsProcessingProjectPreferencesQueue,
} = projectPreferencesSlice.actions;

export const projectVisibleSectionsSelector = createSelector(
  [
    (state: IState) => state.projectPreferences,
    (_state: IState, projectId: string) => projectId,
    (_state: IState, _projectId, colDefMeta: IGridColDefMeta[]) => colDefMeta,
  ],
  (state, projectId, colDefMeta) => {
    const columnState = selectors.selectById(state, projectId)?.gridState.gridUIState.columnState;
    if (!columnState) {
      return [];
    }
    const outset = columnState.reduce((acc, cur) => {
      if (cur.hide) {
        return acc;
      }
      const sectionId = colDefMeta.find((def) => def.colId === cur.colId)?.sectionId;
      if (sectionId) {
        acc.add(sectionId as IToggleableProjectGridSectionId);
      }

      return acc;
    }, new Set() as Set<IToggleableProjectGridSectionId>);

    return Array.from(outset);
  }
);

export const projectPreferencesSelector = createSelector(
  [(state: IState) => state.projectPreferences, (_state: IState, projectId: string) => projectId],
  (state, projectId) => {
    const pref = selectors.selectById(state, projectId);
    invariant(pref);
    return pref;
  }
);

export const projectCostDistributionZoomLevelSelector = createSelector(
  [(state: IState) => state, (_state: IState, projectId: string) => projectId],
  (state, projectId) => projectPreferencesSelector(state, projectId).gridState.costDistributionZoomLevel
);

export const projectCostDistributionStartDateSelector = createSelector(
  [(state: IState) => state, (_state: IState, projectId: string) => projectId],
  (state, projectId) => projectPreferencesSelector(state, projectId).gridState.costDistributionStartDate
);

export const projectCostDistributionEndDateSelector = createSelector(
  [(state: IState) => state, (_state: IState, projectId: string) => projectId],
  (state, projectId) => projectPreferencesSelector(state, projectId).gridState.costDistributionEndDate
);

export const projectRememberCostDistributionPeriodSelector = createSelector(
  [(state: IState) => state, (_state: IState, projectId: string) => projectId],
  (state, projectId) => projectPreferencesSelector(state, projectId).gridState.rememberCostDistributionPeriod
);

export const projectColumnGroupShowConfigSelector = createSelector(
  [(state: IState) => state, (_state: IState, projectId: string) => projectId],
  (state, projectId) => projectPreferencesSelector(state, projectId).gridState.columnGroupShowConfig
);

export default projectPreferencesSlice.reducer;
