import { difference } from 'lodash-es';
import { type IProjectGridRowsSliceState, projectGridRowsSelectors, adapter } from './index';
import { getAllChildrenIds } from './getAllChildrenIds';

interface IParams {
  state: IProjectGridRowsSliceState;
  projectId: string;
  rowIds: string | string[];
  preserveChildren?: boolean;
}
export function removeProjectGridRowsReducer({ state, projectId, rowIds, preserveChildren = false }: IParams) {
  const allRows = projectGridRowsSelectors.selectAll(state).filter((r) => r.projectId === projectId);
  const ids = Array.isArray(rowIds) ? rowIds : [rowIds];

  const rowIdsToRemove = preserveChildren ? ids : [...ids, ...ids.flatMap((id) => getAllChildrenIds(allRows, id))];

  // remove the row from the state
  for (const projectRow of allRows) {
    state = adapter.upsertOne(state, { ...projectRow, children: difference(projectRow.children, rowIdsToRemove) });
  }
  return adapter.removeMany(state, rowIdsToRemove);
}
