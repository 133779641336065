import { createEntityAdapter, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IEmployee, IProject, ITimesheetRow } from '../../types';
import type { IState } from '../store';
import { selectTimesheetRowEntries } from '../timesheetEntries';

const adapter = createEntityAdapter<ITimesheetRow, string>({
  selectId: (row) => row.rowGroup,
});

export const timesheetRowSelectors = adapter.getSelectors();

const slice = createSlice({
  name: 'timesheetRows',
  initialState: adapter.getInitialState(),
  reducers: {
    setAllTimesheetRows: adapter.setAll,
    createNewTimesheetRow: (state, action: PayloadAction<{ project: IProject; employee: IEmployee }>) => {
      const { project, employee } = action.payload;

      return adapter.addOne(state, {
        rowGroup: crypto.randomUUID(),
        projectId: project.id,
        activityCodeId: '',
        scopeElementId: '',
        scopeElementName: '',
        scopeElementWbs: '',
        resourceCategoryId: '',
        resourceCategoryName: '',
        timesheetEntryIds: [],
        personId: employee.person.personId,
        projectName: project.name,
        projectCode: project.projectCode,
        firstName: employee.person.firstName,
        lastName: employee.person.lastName,
        isTimeSheetTask: true,
      });
    },
    addOneTimesheetRow: (state, action: PayloadAction<ITimesheetRow>) => {
      return adapter.addOne(state, action.payload);
    },
    updateOneTimesheetRow: adapter.updateOne,
    removeOneTimesheetRow: adapter.removeOne,
  },
});

export const {
  setAllTimesheetRows,
  createNewTimesheetRow,
  updateOneTimesheetRow,
  addOneTimesheetRow,
  removeOneTimesheetRow,
} = slice.actions;

export const selectAllTimesheetRows = createSelector([(state: IState) => state.timesheetRows], (state) =>
  timesheetRowSelectors.selectAll(state)
);

export const selectTimesheetRowTotal = createSelector(
  (state: IState) => state,
  (_state: IState, rowGroup: string) => rowGroup,
  (state, rowGroup) => {
    const entries = selectTimesheetRowEntries(state, rowGroup);
    return entries.reduce((total, entry) => total + entry.duration, 0);
  }
);

export default slice.reducer;
