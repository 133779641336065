import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const clearPrioritizeLandingFilters = createAsyncThunk<void, void, { state: IState }>(
  'uiView/clearPrioritizeLandingFilters',
  (_, { dispatch }) => {
    dispatch(persistUserPreferences());
  }
);
