import { Link } from 'react-router-dom';
import { ErrorLayout } from './ErrorLayout';

export const NotFound = () => (
  <ErrorLayout title="It’s gone!">
    <p className="text-2xl font-bold">We can’t find this page.</p>
    <p>Here is what you can do:</p>
    <ul className="list-disc space-y-4 pl-6">
      <li>
        <button className="link" onClick={() => window.history.go(-1)} type="button">
          Go to the previous page
        </button>
      </li>
      <li>
        <Link to="/" className="link">
          Go to the home page
        </Link>
      </li>
      <li>If you typed the URL, check it for possible typos.</li>
    </ul>
  </ErrorLayout>
);
