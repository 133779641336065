import { GANTT_MINIMUM_GRID_PANE_WIDTH, defaultProjectColumnGroupShowConfig } from '@app/config';
import { getDefaultCostDistributionSettings } from '@app/core/utilities/costDistribution/getDefaultCostDistributionDateRange';
import type { IProjectPreferences, ITenantTypeName } from '@app/types';

export function getNewProjectPreferences(projectId: string, tenantTypeName: ITenantTypeName): IProjectPreferences {
  const defaultCostDistributionSettings = getDefaultCostDistributionSettings(tenantTypeName);

  return {
    id: null,
    projectId,
    projectColor: null,
    gridState: {
      columnInputMode: {},
      costDistributionStaticRangeId: defaultCostDistributionSettings.staticRangeId,
      gridUIState: {
        columnState: [],
        columnGroupState: [],
        filterModel: {},
      },
      columnGroupShowConfig: defaultProjectColumnGroupShowConfig,
      costDistributionZoomLevel: defaultCostDistributionSettings.zoomLevel,
      costDistributionStartDate: defaultCostDistributionSettings.startDate,
      costDistributionEndDate: defaultCostDistributionSettings.endDate,
      rememberCostDistributionPeriod: defaultCostDistributionSettings.rememberPeriod,
    },
    ganttState: {
      columns: [],
      splitterSettings: {
        minimum: GANTT_MINIMUM_GRID_PANE_WIDTH,
        columnIndex: 6,
      },
      showBaseline: false,
    },
  };
}
