import { ERROR_BULK_UPDATE_SCOPE_ELEMENTS } from '@app/config';
import invariant from '@app/core/utilities/invariant';
import { getNewProjectGridRow } from '@app/core/utilities/projectGrid/getNewProjectGridRow';
import type { IFundData, IProjectFinanceColumnData, IProjectGridRow } from '@app/types';
import { type PayloadAction, type SerializedError, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  type IProjectGridRowUpdatePayload,
  addProjectGridRowAfter,
  addProjectGridRowBefore,
  addProjectGridRowChild,
  createProjectGridRow,
  indentProjectGridRows,
  outdentProjectGridRows,
  queueScopeElementsUpdate,
  removeManyProjectGridRows,
  updateProjectGridRows,
} from '../thunks';
import { moveProjectGridRowsDown } from '../thunks/moveProjectGridRowsDown';
import { moveProjectGridRowsUp } from '../thunks/moveProjectGridRowsUp';
import { prepareProjectGridRowUpdate } from '../thunks/prepareProjectGridRowUpdate';
import { updateProjectGridRowSubcontractBaseLimit } from '../thunks/updateProjectGridRowSubcontractBaseLimit';
import { updateProjectGridRowSubcontractForecast } from '../thunks/updateProjectGridRowSubcontractForecast';
import { ensureProjectGridRows } from './ensureProjectGridRows';
import { getProjectGridSiblingRows } from './getProjectGridSiblingRows';

import { queueFundDataUpdate } from '../thunks/queueProjectFundUpdate';
import { getTopLevelRows } from './getTopLevelRows';
import { insertProjectGridRowReducer } from './insertProjectGridRowReducer';
import { recalculateRowTreeReducer } from './recalculateRowTreeReducer';
import { removeProjectGridRowsReducer } from './removeProjectGridRowsReducer';
import { updateTreeVersion } from './updateTreeVersion';
import { updateProjectGridRowFundData } from '../thunks/updateProjectGridRowFundData';
import { updateFundDataTree } from '../thunks/updateFundDataTree';

export const adapter = createEntityAdapter<IProjectGridRow>({
  // make sure to show the newRow as last always
  sortComparer: (a, b) => {
    if (!a.rowId) {
      return 1;
    }
    if (!b.rowId) {
      return -1;
    }
    return a.rowId - b.rowId;
  },
});

export const projectGridRowsSelectors = adapter.getSelectors();

interface IActiveDescriptionEditorProps {
  projectGridRowId: string | null; // scope element id
  rowNodeId: string | null; // AG Grid row node id
}

export interface IUpdateQueueEntry {
  transactionId: string;
  data: IProjectGridRowUpdatePayload[];
}

export interface IFundDataUpdateQueueEntry {
  transactionId: string;
  type: 'create' | 'update' | 'delete';
  data: IFundData;
}

const initialState = adapter.getInitialState({
  isProcessingQueue: false,
  isProcessingFundDataQueue: false,
  updateQueue: [] as IUpdateQueueEntry[],
  fundDataUpdateQueue: [] as IFundDataUpdateQueueEntry[],
  fulfilledTransactions: [] as string[],
  unrecoverableError: null as { message: string; error: SerializedError } | null,
  isBlockingActionInProgress: false,
  activeDescriptionEditor: {
    projectGridRowId: null,
    rowNodeId: null,
  } as IActiveDescriptionEditorProps,
  treeVersion: {} as {
    [projectId: string]: number;
  },
  milestoneId: null as string | null,
  yellowAlertDays: 0,
  redAlertDays: 0,
  financeColumnData: {
    budget: {
      personnel: [],
      equipment: [],
      expense: [],
      subcontract: [],
    },
    actual: {
      personnel: [],
      equipment: [],
      expense: [],
      subcontract: [],
    },
    remaining: {
      personnel: [],
      equipment: [],
      expense: [],
      subcontract: [],
    },
    forecast: {
      personnel: [],
      equipment: [],
      expense: [],
      subcontract: [],
    },
  } as IProjectFinanceColumnData,
  refreshNodeTreeRowId: null as string[] | string | null,
});

export type IProjectGridRowsSliceState = typeof initialState;

const slice = createSlice({
  name: 'projectGridRows',
  initialState,
  reducers: {
    // used to reset the state when the project is changed/selected
    setAllProjectGridRows: (
      state,
      action: PayloadAction<{ projectGridRows: IProjectGridRow[]; projectId: string }>
    ) => {
      const { projectGridRows, projectId } = action.payload;
      adapter.removeAll(state);
      adapter.setMany(state, projectGridRows);
      adapter.addOne(state, getNewProjectGridRow(state.ids, projectId));

      recalculateRowTreeReducer({ state, rows: getTopLevelRows(state, projectId) });
      updateTreeVersion(state, projectId);
    },
    queueProjectGridRowsUpdate: (state, action: PayloadAction<IUpdateQueueEntry>) => {
      state.updateQueue.push(action.payload);
    },
    queueProjectGridRowsFundDataUpdate: (state, action: PayloadAction<IFundDataUpdateQueueEntry>) => {
      state.fundDataUpdateQueue.push(action.payload);
    },
    setIsProcessingProjectGridRowFundDataUpdateQueue: (state, action: PayloadAction<boolean>) => {
      state.isProcessingFundDataQueue = action.payload;
    },
    fulfillProjectGridRowTransaction: (state, action: PayloadAction<string | string[]>) => {
      const transactionIds = Array.isArray(action.payload) ? action.payload : [action.payload];
      state.fulfilledTransactions = state.fulfilledTransactions.concat(transactionIds);
    },
    setRefreshNodeTreeRowId: (state, action: PayloadAction<string[] | string | null>) => {
      state.refreshNodeTreeRowId = action.payload;
    },
    updateOneProjectGridRow: adapter.updateOne,
    updateManyProjectGridRows: adapter.updateMany,
    upsertManyProjectGridRows: adapter.upsertMany,
    upsertOneProjectGridRow: adapter.upsertOne,
    clearProjectGridRowUpdateQueue: (state) => {
      state.updateQueue = [];
    },
    clearProjectGridRowFundDataUpdateQueue: (state) => {
      state.fundDataUpdateQueue = [];
    },
    setIsProcessingProjectGridRowUpdateQueue: (state, action: PayloadAction<boolean>) => {
      state.isProcessingQueue = action.payload;
    },
    clearBlockingActionInProgress: (state) => {
      state.isBlockingActionInProgress = false;
    },
    showBlockingActionInProgress: (state) => {
      state.isBlockingActionInProgress = true;
    },
    clearProjectGridUnrecoverableError: (state) => {
      state.unrecoverableError = null;
    },
    setProjectGridUnrecoverableError: (state, action: PayloadAction<{ message: string; error: SerializedError }>) => {
      state.unrecoverableError = action.payload;
    },
    resetUIState: (state) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = null;
      state.activeDescriptionEditor = {
        projectGridRowId: null,
        rowNodeId: null,
      };
    },
    setActiveDescriptionEditor: (state, action: PayloadAction<IActiveDescriptionEditorProps>) => {
      state.activeDescriptionEditor = action.payload;
    },
    resetActiveDescriptionEditor: (state) => {
      state.activeDescriptionEditor = {
        projectGridRowId: null,
        rowNodeId: null,
      };
    },
    setProjectGridFinanceColumnData: (state, action: PayloadAction<IProjectFinanceColumnData>) => {
      state.financeColumnData = action.payload;
    },
    removeProjectGridRows: (
      state,
      action: PayloadAction<{ projectId: string; ids: string[]; preserveChildren?: boolean }>
    ) => {
      const { projectId, ids, preserveChildren } = action.payload;
      return removeProjectGridRowsReducer({ state, projectId, rowIds: ids, preserveChildren });
    },
    recalculateProjectScopeElementTree: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload;
      const rows = getTopLevelRows(state, projectId);
      return recalculateRowTreeReducer({ state, rows });
    },
    insertProjectGridRow: (state, action: PayloadAction<IProjectGridRow>) => {
      return insertProjectGridRowReducer(state, action.payload);
    },
    setProjectGridRowsConfigVariables: (
      state,
      action: PayloadAction<Partial<{ milestoneId: string; yellowAlertDays: number; redAlertDays: number }>>
    ) => {
      const { milestoneId, yellowAlertDays, redAlertDays } = action.payload;
      state.milestoneId = milestoneId ?? state.milestoneId;
      state.yellowAlertDays = yellowAlertDays ?? state.yellowAlertDays;
      state.redAlertDays = redAlertDays ?? state.redAlertDays;
    },
    addSubcontractGridRowIdToProjectGridRow: (
      state,
      action: PayloadAction<{ projectGridRowId: string; subcontractGridRowId: string }>
    ) => {
      const projectGridRow = state.entities[action.payload.projectGridRowId];
      invariant(projectGridRow);

      const subcontractGridRowIds = Array.from(
        new Set([...projectGridRow.subcontractGridRowIds, action.payload.subcontractGridRowId])
      );

      adapter.updateOne(state, { id: action.payload.projectGridRowId, changes: { subcontractGridRowIds } });
    },

    updateTodosStatus: (
      state,
      action: PayloadAction<{
        id: string;
        scopeElementItems: number;
        scopeElementItemsComplete: number;
        redAlert: boolean;
        yellowAlert: boolean;
      }>
    ) => {
      const { id, scopeElementItems, scopeElementItemsComplete, redAlert, yellowAlert } = action.payload;

      return adapter.updateOne(state, {
        id,
        changes: {
          scopeElementItems,
          scopeElementItemsComplete,
          redAlert,
          yellowAlert,
        },
      });
    },

    updateProjectGridRowsTreeVersion: (state, action: PayloadAction<{ projectId: string }>) => {
      return updateTreeVersion(state, action.payload.projectId);
    },
  },
  extraReducers: (builder) => {
    // Add grid row after referenced ID
    builder.addCase(addProjectGridRowAfter.pending, (state, action) => {
      const { projectGridRow, id } = action.meta.arg;
      const projectId = projectGridRow.projectId;
      const projectGridRows = projectGridRowsSelectors.selectAll(state).filter((row) => row.projectId === projectId);

      const refElement = projectGridRows.find((element) => element.id === id);
      invariant(refElement, 'Could not find referenced element for addProjectGridRowAfter');

      const parentElement = refElement.parentScopeElementId
        ? projectGridRowsSelectors.selectById(state, refElement.parentScopeElementId)
        : null;

      const parentPath = parentElement?.path ?? [];

      const refWBS = refElement.wbsFullLabel.split('.').map((wbs) => Number.parseInt(wbs, 10));
      const nextWBS = [...refWBS.slice(0, -1), refWBS[refWBS.length - 1] + 1].join('.');

      const path = [...parentPath, projectGridRow.id];

      const nextProjectGridRow = {
        ...projectGridRow,
        wbsFullLabel: nextWBS,
        path,
        isProcessing: true,
        parentScopeElementId: parentElement?.id ?? null,
      };

      state.isBlockingActionInProgress = true;

      return insertProjectGridRowReducer(state, nextProjectGridRow);
    });

    builder.addCase(addProjectGridRowAfter.fulfilled, (state, action) => {
      state.isBlockingActionInProgress = false;
      updateTreeVersion(state, action.meta.arg.projectGridRow.projectId);
      return adapter.upsertOne(state, { ...action.payload, isProcessing: false, newRow: false });
    });

    builder.addCase(addProjectGridRowAfter.rejected, (state, { error }) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We failed when adding a new scope element', error };
    });
    // Add grid row before referenced ID
    builder.addCase(addProjectGridRowBefore.pending, (state, action) => {
      const { projectGridRow, id } = action.meta.arg;
      const projectId = projectGridRow.projectId;
      const projectGridRows = projectGridRowsSelectors.selectAll(state).filter((row) => row.projectId === projectId);

      const refElement = projectGridRows.find((element) => element.id === id);
      invariant(refElement, 'Could not find referenced element for addProjectGridRowBefore');

      const parentElement = refElement.parentScopeElementId
        ? projectGridRowsSelectors.selectById(state, refElement.parentScopeElementId)
        : null;

      const parentPath = parentElement?.path ?? [];
      const nextWBS = refElement.wbsFullLabel;

      const path = [...parentPath, projectGridRow.id];

      const nextProjectGridRow = {
        ...projectGridRow,
        wbsFullLabel: nextWBS,
        path,
        isProcessing: true,
        parentScopeElementId: parentElement?.id ?? null,
      };

      state.isBlockingActionInProgress = true;
      return insertProjectGridRowReducer(state, nextProjectGridRow);
    });

    builder.addCase(addProjectGridRowBefore.fulfilled, (state, action) => {
      state.isBlockingActionInProgress = false;
      updateTreeVersion(state, action.meta.arg.projectGridRow.projectId);
      adapter.upsertOne(state, { ...action.payload, isProcessing: false, newRow: false });
    });

    builder.addCase(addProjectGridRowBefore.rejected, (state, action) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We failed when adding a new scope element', error: action.error };
    });

    // ADD CHILD ROW
    builder.addCase(addProjectGridRowChild.pending, (state, action) => {
      const { projectGridRow, id } = action.meta.arg;

      const parentElement = projectGridRowsSelectors.selectById(state, id);
      invariant(parentElement, 'Could not find referenced element for addProjectGridRowChild');

      const nextWBS = `${parentElement.wbsFullLabel}.${parentElement.children.length + 1}`;

      const path = [...parentElement.path, projectGridRow.id];

      const nextProjectGridRow = {
        ...projectGridRow,
        wbsFullLabel: nextWBS,
        path,
        isProcessing: true,
        parentScopeElementId: id,
      };

      state.isBlockingActionInProgress = true;
      return insertProjectGridRowReducer(state, nextProjectGridRow);
    });

    builder.addCase(addProjectGridRowChild.fulfilled, (state) => {
      state.isBlockingActionInProgress = false;
    });

    builder.addCase(addProjectGridRowChild.rejected, (state, action) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We failed when adding a new scope element', error: action.error };
    });

    // INDENT ROW
    builder.addCase(indentProjectGridRows.pending, (state) => {
      state.isBlockingActionInProgress = true;
    });

    builder.addCase(indentProjectGridRows.fulfilled, (state, action) => {
      state.isBlockingActionInProgress = false;
      return updateTreeVersion(state, action.meta.arg.project.id);
    });

    builder.addCase(indentProjectGridRows.rejected, (state, action) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We couldn’t indent this scope element', error: action.error };
    });

    // OUTDENT ROW
    builder.addCase(outdentProjectGridRows.pending, (state) => {
      state.isBlockingActionInProgress = true;
    });

    builder.addCase(outdentProjectGridRows.fulfilled, (state) => {
      state.isBlockingActionInProgress = false;
    });

    builder.addCase(outdentProjectGridRows.rejected, (state, action) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We couldn’t outdent this scope element', error: action.error };
    });

    // MOVE ROW UP
    builder.addCase(moveProjectGridRowsUp.pending, (state, action) => {
      state.isBlockingActionInProgress = true;

      const { projectId, ids } = action.meta.arg;

      // make sure all the row IDs are valid
      const refElements = ensureProjectGridRows(state, ids);

      const siblingRows = getProjectGridSiblingRows(state, refElements[0]);

      const reorderedRows = siblingRows.reduce((acc, cur) => {
        if (ids.includes(cur.id) === false) {
          acc.push(cur);
          return acc;
        }
        const lastItem = acc.pop();
        invariant(lastItem, 'Trying to move row up but no previous row found');
        acc.push(cur, lastItem);
        return acc;
      }, [] as IProjectGridRow[]);

      const reorderedRowIds = reorderedRows.map((reorderedRow) => reorderedRow.id);

      const parentId = siblingRows[0]?.parentScopeElementId;
      let topLevelRows: Array<IProjectGridRow | undefined>;

      if (parentId) {
        const parentRow = projectGridRowsSelectors.selectById(state, parentId);
        invariant(parentRow, 'Could not find parent element for moveProjectGridRowsUp');

        adapter.updateOne(state, {
          id: parentId,
          changes: { children: reorderedRowIds },
        });
        topLevelRows = getTopLevelRows(state, projectId);
      } else {
        topLevelRows = reorderedRows;
      }

      return recalculateRowTreeReducer({ state, rows: topLevelRows });
    });

    builder.addCase(moveProjectGridRowsUp.fulfilled, (state, action) => {
      state.isBlockingActionInProgress = false;
      return updateTreeVersion(state, action.meta.arg.projectId);
    });

    builder.addCase(moveProjectGridRowsUp.rejected, (state, { error }) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We couldn’t move these scope elements up', error };
    });

    // MOVE ROW DOWN
    builder.addCase(moveProjectGridRowsDown.pending, (state, action) => {
      state.isBlockingActionInProgress = true;

      const { projectId, ids } = action.meta.arg;
      const refElements = ensureProjectGridRows(state, ids);
      const siblingRows = getProjectGridSiblingRows(state, refElements[0]);

      const reorderedRows = [...siblingRows]
        .reverse()
        .reduce((acc, cur) => {
          if (ids.includes(cur.id) === false) {
            acc.push(cur);
            return acc;
          }
          const lastItem = acc.pop();
          invariant(lastItem, 'Trying to move row down but no next row found');
          acc.push(cur, lastItem);
          return acc;
        }, [] as IProjectGridRow[])
        .reverse();

      const reorderedRowIds = reorderedRows.map((reorderedRow) => reorderedRow.id);

      const parentId = siblingRows[0]?.parentScopeElementId;
      let topLevelRows: Array<IProjectGridRow | undefined>;

      if (parentId) {
        const parentRow = projectGridRowsSelectors.selectById(state, parentId);
        invariant(parentRow, 'Could not find parent element for moveProjectGridRowsDown');

        adapter.updateOne(state, {
          id: parentId,
          changes: { children: reorderedRowIds },
        });
        topLevelRows = getTopLevelRows(state, projectId);
      } else {
        topLevelRows = reorderedRows;
      }

      return recalculateRowTreeReducer({ state, rows: topLevelRows });
    });

    builder.addCase(moveProjectGridRowsDown.fulfilled, (state, action) => {
      state.isBlockingActionInProgress = false;
      return updateTreeVersion(state, action.meta.arg.projectId);
    });

    builder.addCase(moveProjectGridRowsDown.rejected, (state, { error }) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = { message: 'We couldn’t move these scope elements down', error };
    });

    // create project grid row
    builder.addCase(createProjectGridRow.pending, (state, action) => {
      const {
        projectGridRow: { projectId },
        parentId,
      } = action.meta.arg;

      const { milestoneId, yellowAlertDays, redAlertDays } = state;
      invariant(milestoneId, 'milestoneId not set in projectGridRows config');

      const parentRow = parentId ? projectGridRowsSelectors.selectById(state, parentId) : undefined;

      const nextItem = prepareProjectGridRowUpdate({
        projectGridRow: { ...action.meta.arg.projectGridRow, parentScopeElementId: parentId ?? null },
        state,
        milestoneId,
        yellowAlertDays,
        redAlertDays,
      });

      adapter.setOne(state, nextItem);
      if (parentRow) {
        adapter.setOne(state, { ...parentRow, children: [...parentRow.children, nextItem.id] });
      }
      adapter.addOne(state, getNewProjectGridRow(state.ids, projectId));

      return recalculateRowTreeReducer({ state, rows: getTopLevelRows(state, projectId) });
    });

    builder.addCase(createProjectGridRow.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t save this scope element', error };
    });

    // bulk update project grid rows
    builder.addCase(updateProjectGridRows.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t save your changes', error };
    });

    // fund data
    builder.addCase(updateProjectGridRowFundData.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t save your changes', error };
    });
    builder.addCase(updateFundDataTree.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t save your changes', error };
    });

    // funds
    builder.addCase(queueFundDataUpdate.fulfilled, (state, action) => {
      if (action.payload.updatedFundData.length === 0 && action.payload.removedFundData.length === 0) {
        return state;
      }

      const updatedProjectGridRowIds = new Set<string>();

      state.isProcessingFundDataQueue = false;
      for (const fundData of action.payload.updatedFundData) {
        updatedProjectGridRowIds.add(fundData.scopeElementId);
      }

      for (const removedFundData of action.payload.removedFundData) {
        updatedProjectGridRowIds.add(removedFundData.scopeElementId);
      }

      state.refreshNodeTreeRowId = Array.from(updatedProjectGridRowIds);
    });

    builder.addCase(queueFundDataUpdate.rejected, (state, { error }) => {
      state.unrecoverableError = { message: 'We couldn’t save your changes', error };
    });

    // queue scope elements update
    builder.addCase(queueScopeElementsUpdate.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        return state;
      }
      state.isProcessingQueue = false;
      return adapter.upsertMany(
        state,
        action.payload.map((row) => ({ ...row, isProcessing: false, newRow: false }))
      );
    });

    builder.addCase(queueScopeElementsUpdate.rejected, (state, action) => {
      state.isProcessingQueue = false;

      let errorMessage = 'We couldn’t save your latest changes.';

      if (action.error.message === ERROR_BULK_UPDATE_SCOPE_ELEMENTS) {
        const faultyScopeElements = action.meta.arg.data.filter(
          (item) =>
            (!item.scopeElement.scheduledStartDate || !item.scopeElement.scheduledEndDate) &&
            item.scopeElement.dependencies?.length
        );
        if (faultyScopeElements.length) {
          const wbsLabels = faultyScopeElements
            .map(({ scopeElement }) => `WBS ${scopeElement.wbsFullLabel}`)
            .join(', ');

          errorMessage = `The following scope element(s) have dependencies defined, but are missing the start and finish date: ${wbsLabels}. Please, schedule the start and finish dates before setting dependencies.`;
        }
      }

      state.unrecoverableError = { message: errorMessage, error: action.error };
    });
    builder.addCase(updateProjectGridRowSubcontractBaseLimit.rejected, (state, { error }) => {
      state.unrecoverableError = {
        message: 'We’ve encountered an error while trying to save scope element limit.',
        error,
      };
    });
    builder.addCase(updateProjectGridRowSubcontractForecast.rejected, (state, { error }) => {
      state.unrecoverableError = {
        message: 'We’ve encountered an error while trying to save scope element forecast.',
        error,
      };
    });

    // remove many project grid rows
    builder.addCase(removeManyProjectGridRows.pending, (state) => {
      state.isBlockingActionInProgress = true;
    });

    builder.addCase(removeManyProjectGridRows.fulfilled, (state) => {
      state.isBlockingActionInProgress = false;
    });

    builder.addCase(removeManyProjectGridRows.rejected, (state, { error }) => {
      state.isBlockingActionInProgress = false;
      state.unrecoverableError = {
        message: 'We’ve encountered an error while trying to remove some scope elements.',
        error,
      };
    });
  },
});

export const {
  removeProjectGridRows,
  updateOneProjectGridRow,
  updateManyProjectGridRows,
  insertProjectGridRow,
  upsertOneProjectGridRow,
  upsertManyProjectGridRows,
  setAllProjectGridRows,
  clearProjectGridRowUpdateQueue,
  resetUIState,
  queueProjectGridRowsUpdate,
  fulfillProjectGridRowTransaction,
  setActiveDescriptionEditor,
  resetActiveDescriptionEditor,
  clearBlockingActionInProgress,
  showBlockingActionInProgress,
  setIsProcessingProjectGridRowUpdateQueue,
  clearProjectGridUnrecoverableError,
  setProjectGridUnrecoverableError,
  recalculateProjectScopeElementTree,
  updateTodosStatus,
  setProjectGridRowsConfigVariables,
  addSubcontractGridRowIdToProjectGridRow,
  queueProjectGridRowsFundDataUpdate,
  setIsProcessingProjectGridRowFundDataUpdateQueue,
  clearProjectGridRowFundDataUpdateQueue,
  setProjectGridFinanceColumnData,
  updateProjectGridRowsTreeVersion,
  setRefreshNodeTreeRowId,
} = slice.actions;

export default slice.reducer;
