export const ENVIRONMENT_DEVELOPMENT = 'development' as const;
export const ENVIRONMENT_STAGING = 'staging' as const;
export const ENVIRONMENT_PRODUCTION = 'production' as const;
export const ENVIRONMENT_UNKNOWN = 'unknown' as const;

export const ENVIRONMENTS = [
  { type: ENVIRONMENT_DEVELOPMENT, host: 'localhost' },
  { type: ENVIRONMENT_DEVELOPMENT, host: '127.0.0.1' },
  { type: ENVIRONMENT_DEVELOPMENT, host: /^project-data-dev/ },
  { type: ENVIRONMENT_STAGING, host: 'project-data-non-prod.azurewebsites.net' },
  { type: ENVIRONMENT_STAGING, host: 'project-data-stage.azurewebsites.net' },
  { type: ENVIRONMENT_STAGING, host: 'project-data-demo.azurewebsites.net' },
  { type: ENVIRONMENT_STAGING, host: 'demo.getinscope.com' },
  { type: ENVIRONMENT_PRODUCTION, host: 'project-data-prod.azurewebsites.net' },
  { type: ENVIRONMENT_PRODUCTION, host: 'app.getinscope.com' },
];

export type IEnvironmentType = 'development' | 'staging' | 'production' | 'unknown';
