import {
  PROJECT_PREFERENCE_VERSION_10,
  PROJECT_PREFERENCE_VERSION_4,
  PROJECT_PREFERENCE_VERSION_5,
  PROJECT_PREFERENCE_VERSION_6,
  PROJECT_PREFERENCE_VERSION_7,
  PROJECT_PREFERENCE_VERSION_8,
  PROJECT_PREFERENCE_VERSION_9,
} from '../config';
import { getNewProjectPreferences } from '../core/utilities/projectPreferences/getNewProjectPreferences';

import type { IProjectGanttState, IProjectGridState, IProjectPreferences, ITenantTypeName } from '../types';

export interface IProjectPreferenceResponse {
  id: string;
  userId: string;
  projectId: string;
  projectColor: string;
  scopeElementViewState: string;
  updatedOn: string;
}

export function migrateProjectPreferences(
  data: IProjectPreferenceResponse,
  tenantTypeName: ITenantTypeName
): IProjectPreferences {
  const { scopeElementViewState, ...rest } = data;

  const defaultProjectPreferences = getNewProjectPreferences(data.projectId, tenantTypeName);

  try {
    const parsed = JSON.parse(scopeElementViewState) as {
      ganttState: any;
      gridState: any;
      version: string;
    };

    const { gridState, ganttState } = parsed;
    let version = parsed.version;

    if (version === PROJECT_PREFERENCE_VERSION_4) {
      gridState.columnGroupShowConfig['details-externalCode'] = 'open';
      version = PROJECT_PREFERENCE_VERSION_5;
      console.log('Migrated preferences to version 5'); // eslint-disable-line no-console
    }

    if (version === PROJECT_PREFERENCE_VERSION_5) {
      gridState.visibleSections.push('funds');
      version = PROJECT_PREFERENCE_VERSION_6;
      console.log('Migrated preferences to version 6'); // eslint-disable-line no-console
    }

    if (version === PROJECT_PREFERENCE_VERSION_6) {
      gridState.visibleSections = undefined;
      version = PROJECT_PREFERENCE_VERSION_7;
      console.log('Migrated preferences to version 7'); // eslint-disable-line no-console
    }

    if (version === PROJECT_PREFERENCE_VERSION_7) {
      const defaultGridState = defaultProjectPreferences.gridState;

      gridState.columnGroupShowConfig = gridState.columnGroupShowConfig ?? defaultGridState.columnGroupShowConfig;
      gridState.costDistributionZoomLevel =
        gridState.costDistributionZoomLevel ?? defaultGridState.costDistributionZoomLevel;
      gridState.costDistributionStartDate =
        gridState.costDistributionStartDate ?? defaultGridState.costDistributionStartDate;
      gridState.costDistributionEndDate = gridState.costDistributionEndDate ?? defaultGridState.costDistributionEndDate;
      gridState.rememberCostDistributionPeriod =
        gridState.rememberCostDistributionPeriod ?? defaultGridState.rememberCostDistributionPeriod;

      version = PROJECT_PREFERENCE_VERSION_8;
      console.log('Migrated preferences to version 8'); // eslint-disable-line no-console
    }

    if (version === PROJECT_PREFERENCE_VERSION_8) {
      if (tenantTypeName === 'Owner') {
        gridState.costDistributionZoomLevel = 'year';
      }

      version = PROJECT_PREFERENCE_VERSION_9;
      console.log('Migrated preferences to version 9'); // eslint-disable-line no-console
    }

    if (version === PROJECT_PREFERENCE_VERSION_9) {
      gridState.costDistributionStaticRangeId = null;

      version = PROJECT_PREFERENCE_VERSION_10;
      console.log('Migrated preferences to version 10'); // eslint-disable-line no-console
    }

    return {
      ...rest,
      ganttState: ganttState as IProjectGanttState,
      gridState: gridState as IProjectGridState,
    };
  } catch (error) {
    // TODO: update track exception here appInsightsReactPlugin.trackException({ exception: error as Error });
    console.warn(error); // eslint-disable-line no-console
  }
  return {
    ...defaultProjectPreferences,
    ...rest,
  };
}
