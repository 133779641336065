import { useFormContext } from 'react-hook-form';
import { FormError } from '../FormError';
import { FormInput } from '../FormInput';
import { FormLabel } from '../FormLabel';
import { FormSelect } from '../FormSelect';
import { statesList } from '../../../../config/usStates';

interface IProps {
  required?: boolean;
  fieldName: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoComplete?: boolean;
  wrapperClassName?: string;
}

function buildId(parentName: string, field: string) {
  return `${parentName}-${field}`;
}
function buildName(parentName: string, field: string) {
  return `${parentName}.${field}`;
}

export const AddressFormPartial = (props: IProps) => {
  const { required, fieldName, disabled, autoComplete, wrapperClassName = 'mb-4' } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={wrapperClassName}>
      <div className="mb-4">
        <FormLabel htmlFor={buildId(fieldName, 'address1')} required={required}>
          Street
        </FormLabel>
        <FormInput
          {...register(buildName(fieldName, 'address1'))}
          id={buildId(fieldName, 'address1')}
          disabled={disabled}
          autoComplete={autoComplete ? 'address-line-1' : 'off'}
        />
        <FormError errors={errors} name={buildName(fieldName, 'address1')} />
      </div>
      <div className="mb-4">
        <FormLabel htmlFor={buildId(fieldName, 'address2')}>Street (Line 2)</FormLabel>
        <FormInput
          {...register(buildName(fieldName, 'address2'))}
          id={buildId(fieldName, 'address2')}
          disabled={disabled}
          autoComplete={autoComplete ? 'address-line-2' : 'off'}
        />
        <FormError errors={errors} name={buildName(fieldName, 'address2')} />
      </div>
      <div className="mb-4 grid grid-cols-3 gap-4">
        <div>
          <FormLabel htmlFor={buildId(fieldName, 'city')} required={required}>
            City
          </FormLabel>
          <FormInput
            {...register(buildName(fieldName, 'city'))}
            id={buildId(fieldName, 'city')}
            disabled={disabled}
            autoComplete={autoComplete ? 'address-level-2' : 'off'}
          />
          <FormError errors={errors} name={buildName(fieldName, 'city')} />
        </div>

        <div>
          <FormLabel htmlFor={buildId(fieldName, 'region')} required={required}>
            State
          </FormLabel>
          <FormSelect
            {...register(buildName(fieldName, 'region'))}
            id={buildId(fieldName, 'region')}
            disabled={disabled}
            autoComplete={autoComplete ? 'address-level-1' : 'off'}
          >
            <option />
            {statesList.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormSelect>
          <FormError errors={errors} name={buildName(fieldName, 'region')} />
        </div>

        <div>
          <FormLabel htmlFor={buildId(fieldName, 'postalCode')} required={required}>
            Zip
          </FormLabel>
          <FormInput
            {...register(buildName(fieldName, 'postalCode'))}
            id={buildId(fieldName, 'postalCode')}
            disabled={disabled}
            autoComplete={autoComplete ? 'postal-code' : 'off'}
          />
          <FormError errors={errors} name={buildName(fieldName, 'postalCode')} />
        </div>
      </div>
    </div>
  );
};
