import type { ISubcontractDetails } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import { addSubcontractGridRowIdToProjectGridRow } from '../projectGridRows';
import type { IState } from '../store';
import { upsertSubcontractGridRow } from '../subcontractGridRows/upsertSubcontractGridRow';
import { getOrCreateSubcontractGridRowForUpdate } from './getOrCreateSubcontractGridRowForUpdate';
import { calculateProjectGridRowAggThunk } from './calculateProjectGridRowAggThunk';

interface IProps {
  projectGridRowId: string;
  value: number;
  subcontract: ISubcontractDetails;
}
export const updateProjectGridRowSubcontractForecast = createAsyncThunk<void, IProps, { state: IState }>(
  'projectGridRows/updateProjectGridRowSubcontractForecast',
  async (props, { getState, dispatch }) => {
    const { value, projectGridRowId, subcontract } = props;
    // use subcontract grid row queue to create the new scope element

    const projectGridRow = getState().projectGridRows.entities[projectGridRowId];
    invariant(projectGridRow, `projectGridRowId ${projectGridRowId} not found`);

    const subcontractGridRow = getOrCreateSubcontractGridRowForUpdate({
      projectGridRow,
      subcontract,
      subcontractGridRowEntities: getState().subcontractGridRows.entities,
    });

    const nextSubcontractGridRow = { ...subcontractGridRow, forecastValue: value };

    dispatch(upsertSubcontractGridRow(nextSubcontractGridRow));

    // Make sure to update the project grid row if a new subcontract grid row is created
    dispatch(
      addSubcontractGridRowIdToProjectGridRow({ projectGridRowId, subcontractGridRowId: subcontractGridRow.id })
    );

    dispatch(calculateProjectGridRowAggThunk(projectGridRowId));
  }
);
