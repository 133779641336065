import { clsx } from 'clsx';
import * as React from 'react';
import { FORM_TEXTAREA_BASE_CLASS } from './constants';
import { getFontSizeClass } from './getFontSizeClass';

type IFormTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  textSize?: 'xs' | 'sm' | 'base' | 'lg';
};

export const FormTextarea = React.forwardRef<HTMLTextAreaElement, IFormTextareaProps>(
  function FormTextarea(props, ref) {
    const { className, textSize = 'base', ...rest } = props;
    return (
      <textarea
        className={clsx(className, FORM_TEXTAREA_BASE_CLASS, getFontSizeClass(textSize), 'px-4 read-only:opacity-50')}
        ref={ref}
        {...rest}
      />
    );
  }
);
