import { noop } from 'lodash';
import { type ReactNode, createContext, useContext, useMemo, useState } from 'react';

interface IntervalCallback {
  id: string;
  fn: () => void | Promise<void>;
  interval: number;
  timer: NodeJS.Timeout;
}
interface IGlobalIntervalContext {
  callbacks: IntervalCallback[];
  registerCallback: (callback: Omit<IntervalCallback, 'timer'>) => void;
  deregisterCallback: (callbackId: string) => void;
  deregisterAllCallbacks: () => void;
}

const GlobalIntervalContext = createContext<IGlobalIntervalContext>({
  callbacks: [],
  registerCallback: noop,
  deregisterCallback: noop,
  deregisterAllCallbacks: noop,
});

interface IProps {
  children?: ReactNode;
}

export const GlobalIntervalContextProvider = (props: IProps) => {
  const { children } = props;

  const [callbacks, setCallbacks] = useState<IntervalCallback[]>([]);

  const value: IGlobalIntervalContext = useMemo(
    () => ({
      callbacks,
      registerCallback: (callback: Omit<IntervalCallback, 'timer'>) => {
        const timer = setInterval(() => {
          callback.fn();
        }, callback.interval);
        setCallbacks((prev) => [...prev, { ...callback, timer }]);
      },
      deregisterCallback: (callbackId: string) => {
        const items = callbacks.filter((c) => c.id === callbackId);
        for (const callback of items) {
          clearInterval(callback.timer);
        }

        setCallbacks((prev) => prev.filter((c) => c.id !== callbackId));
      },
      deregisterAllCallbacks: () => {
        for (const callback of callbacks) {
          clearInterval(callback.timer);
        }
        setCallbacks([]);
      },
    }),
    [callbacks]
  );

  return <GlobalIntervalContext.Provider value={value}>{children}</GlobalIntervalContext.Provider>;
};

export const useGlobalIntervalContext = () => useContext(GlobalIntervalContext);
