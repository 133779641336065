import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IState } from '.';
import type { IScopeElementType } from '../types';

const adapter = createEntityAdapter<IScopeElementType>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'scopeElementTypes',
  initialState,
  reducers: {
    setAllScopeElementTypes: adapter.setAll,
  },
});

export const scopeElementTypeSelectors = adapter.getSelectors();

export const selectScopeElementTypeByName = createSelector(
  [(state: IState) => state.scopeElementTypes, (_state: IState, name: string) => name],
  (state, name) => {
    const item = scopeElementTypeSelectors.selectAll(state).find((s) => s.name === name);
    invariant(item, `scopeElementType not found: ${name}`);
    return item;
  }
);

export const { setAllScopeElementTypes } = slice.actions;

export default slice.reducer;
