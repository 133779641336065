import type { IUiViewState, IUserPreferences } from '@app/types';
import axios from 'axios';

interface IUserPreferencesResponse {
  id: string;
  userId: string;
  projectOverviewViewState: string | null;
  workloadViewState: string | null;
  uiViewState: string | null;
  updatedOn: string;
}

export interface IDirtyUserPreferences {
  id: string;
  userId: string;
  projectOverviewViewState: unknown;
  workloadViewState: unknown;
  uiViewState: unknown;
}

function processJSONViewState<T = unknown>(viewState: string | null) {
  if (!viewState) {
    return null;
  }
  try {
    return JSON.parse(viewState) as T;
  } catch (error) {
    return null;
  }
}

const processDirtyUserPreferencesResponse = (data: IUserPreferencesResponse) => {
  return {
    id: data.id,
    userId: data.userId,
    projectOverviewViewState: processJSONViewState(data.projectOverviewViewState),
    workloadViewState: processJSONViewState(data.workloadViewState),
    uiViewState: processJSONViewState(data.uiViewState),
  };
};

const processCleanUserPreferencesResponse = (data: IUserPreferencesResponse) => {
  return {
    id: data.id,
    userId: data.userId,
    uiViewState: processJSONViewState<IUiViewState>(data.uiViewState),
    projectOverviewViewState: null,
    workloadViewState: null,
  };
};

export const getUserPreferences = async (): Promise<IDirtyUserPreferences | null> => {
  const { data } = await axios.get<IUserPreferencesResponse>('/user-preferences');

  return data ? processDirtyUserPreferencesResponse(data) : null;
};

type ICreateUserPreferenceParams = Omit<IUserPreferences, 'id'>;

function preparePreferencesData(userPreferences: Omit<IUserPreferences, 'id'>) {
  return {
    ...userPreferences,
    projectOverviewViewState: null,
    workloadViewState: null,
    uiViewState: JSON.stringify(userPreferences.uiViewState),
  };
}

export async function createUserPreferences(
  userPreferences: ICreateUserPreferenceParams
): Promise<ReturnType<typeof processCleanUserPreferencesResponse>> {
  const response = await axios.post<IUserPreferencesResponse>(
    '/user-preferences',
    preparePreferencesData(userPreferences)
  );

  return processCleanUserPreferencesResponse(response.data);
}

export async function updateUserPreferences(
  userPreferences: IUserPreferences
): Promise<ReturnType<typeof processCleanUserPreferencesResponse>> {
  const response = await axios.put<IUserPreferencesResponse>(
    `/user-preferences/${userPreferences.id}`,
    preparePreferencesData(userPreferences)
  );

  return processCleanUserPreferencesResponse(response.data);
}
