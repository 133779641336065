import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import { RouterProvider } from 'react-router-dom';

import * as ConfirmDialog from './core/components/ConfirmDialog';
import { EnvironmentInfoBar } from './core/components/EnvironmentInfoBar';
import { NotificationsConfig } from './core/components/NotificationsConfig';
import { AxiosConfigProvider } from './core/providers/AxiosConfigProvider';
import { store } from './store';

import 'react-tooltip/dist/react-tooltip.css';
import { AppLoader } from './core/components/AppLoader';
import { GlobalIntervalContextProvider } from './core/providers/GlobalIntervalContextProvider';
import { queryClient } from './queryClient';
import { router } from './router';
import { rollbarConfig } from './config';
import { RollbarPersonProvider } from './core/providers/RollbarPersonProvider';

const App = () => {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <RollbarPersonProvider />
            <AxiosConfigProvider>
              <GlobalIntervalContextProvider>
                <ConfirmDialog.Provider>
                  <EnvironmentInfoBar />
                  <RouterProvider router={router} fallbackElement={<AppLoader />} />
                </ConfirmDialog.Provider>
              </GlobalIntervalContextProvider>
            </AxiosConfigProvider>
            <NotificationsConfig />
          </Provider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
