import invariant from '@app/core/utilities/invariant';
import { type IProjectGridRowsSliceState, projectGridRowsSelectors } from '.';

export function ensureProjectGridRows(state: IProjectGridRowsSliceState, ids: string[]) {
  return ids.map((id) => {
    const refElement = projectGridRowsSelectors.selectById(state, id);
    invariant(refElement, 'Could not find referenced element for moveProjectGridRowsUp');
    return refElement;
  });
}
