import clsx from 'clsx';
import {
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_STAGING,
  ENVIRONMENT_UNKNOWN,
} from '../../config';
import { getEnvironment } from '../utilities/getEnvironment';

export const EnvironmentInfoBar = () => {
  const env = getEnvironment();
  if (env.type === ENVIRONMENT_PRODUCTION) {
    return null;
  }
  return (
    <footer className="pointer-events-none fixed inset-y-0 right-0 z-environment-bar flex items-center whitespace-nowrap">
      <div>
        <div
          className={clsx(' origin-center translate-x-1/2 rotate-90 rounded-b px-2 pb-1 pt-6 text-center text-xs', {
            'bg-amber-500 text-neutral-900': env.type === ENVIRONMENT_DEVELOPMENT,
            'bg-cyan-500 text-white': env.type === ENVIRONMENT_STAGING,
            'bg-rose-600': env.type === ENVIRONMENT_UNKNOWN,
          })}
        >
          <span className="font-bold uppercase">{env.type}:</span> {env.host}
        </div>
      </div>
    </footer>
  );
};
