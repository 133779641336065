import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IGridUIState } from '../../types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectStatusReportOverviewGridUIState = createAsyncThunk<
  void,
  { gridUIState: IGridUIState },
  { state: IState }
>('uiView/setProjectStatusReportOverviewGridUIState', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
