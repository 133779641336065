import { useQuery } from '@tanstack/react-query';
import { getCurrentUserQuery } from '@app/queries/currentUser.queries';
import { useAppSelector } from '@app/store';

/** Purpose of this component is to hit the current user endpoint at least every five minutes. This way we should keep the session going */
export const UserSessionManager = () => {
  const user = useAppSelector((state) => state.auth.user);
  useQuery({ ...getCurrentUserQuery(), initialData: user });
  return null;
};
