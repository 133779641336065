import { createAsyncThunk } from '@reduxjs/toolkit';
import { type IGetWorkloadElementsParams, refreshWorkloadElements } from '../../api/workloadElement.api';
import type { IWorkloadGridRow } from '../../types';
import { mapWorkloadElementToWorkloadGridRow } from '../../core/utilities/workload/mapWorkloadElementToWorkloadGridRow';
import type { IState } from '../store';
import { addManyWorkloadGridRows, removeAllWorkloadGridRows } from '../workload';

export const refreshWorkload = createAsyncThunk<IWorkloadGridRow[], IGetWorkloadElementsParams, { state: IState }>(
  'workload/refresh',
  async (params, { dispatch }) => {
    const { data } = await refreshWorkloadElements(params);

    const newRows = data.map(mapWorkloadElementToWorkloadGridRow);

    dispatch(removeAllWorkloadGridRows());
    dispatch(addManyWorkloadGridRows(newRows));

    return newRows;
  }
);
