import axios from 'axios';
import type { IUser, IUserAuthorizationResult } from '../types';
import { API_PREFIX } from '../config';

interface ILoginRequest {
  readonly email: string;
  readonly password: string;
  readonly rememberMe: boolean;
}

export const clearAuthCookie = async () => {
  return axios.post('/account/clear');
};

export const login = async (loginRequest: ILoginRequest) => {
  return axios.post<IUser>('/account/login', loginRequest);
};

export const logout = async () => {
  const response = await fetch(`${API_PREFIX}/account/logout`, { method: 'POST', credentials: 'include' });
  if (response.status === 200 || response.status === 401) {
    return;
  }

  throw new Error(`Unexpected response status: ${response.status}`);
};

interface IRecoverAccountModel {
  readonly email: string;
}
export const recoverAccount = async (params: IRecoverAccountModel) => {
  return axios.post('/account/recover', params);
};

interface IResetPasswordModel {
  readonly userId: string;
  readonly token: string;
  readonly password: string;
}

export const resetPassword = async (resetPasswordModel: IResetPasswordModel) => {
  return axios.post('/account/reset-password', resetPasswordModel);
};

export const getUserAuthorization = async (id: string) => {
  return axios.get<boolean>(`/user-authorization/${id}`);
};

export const getBulkUserAuthorization = async (PermissionIds: string[]) => {
  return axios.post<IUserAuthorizationResult[]>('/user-authorization', { PermissionIds });
};

interface IResendConfirmationEmailParams {
  email: string;
}
export const resendConfirmationEmail = (params: IResendConfirmationEmailParams) => {
  return axios.post<boolean>('/account/send-confirm-email', params);
};
interface IConfirmAccountParams {
  userId: string;
  token: string;
}
export const confirmAccount = (params: IConfirmAccountParams) => {
  return axios.post<boolean>('/account/confirm-email', params);
};

interface IChangePasswordParams {
  userId: string;
  newPassword: string;
  currentPassword: string;
}
export const changePassword = (params: IChangePasswordParams) => {
  return axios.post<boolean>('/account/change-password', params);
};
