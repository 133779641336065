import {
  mapProjectGridRowToSubcontractScopeRequest,
  mapSubcontractScopeToSubcontractGridRow,
} from '@app/pages/subs/components/subcontractGridHelpers';
import type { IProjectGridRow, ISubcontractDetails, ISubcontractGridRow } from '@app/types';
import type { Draft } from '@reduxjs/toolkit';

interface IGetSubcontractGridRowProps {
  projectGridRow: IProjectGridRow;
  subcontract: ISubcontractDetails;
  subcontractGridRowEntities: Draft<Record<string, ISubcontractGridRow>>;
}
export function getOrCreateSubcontractGridRowForUpdate(props: IGetSubcontractGridRowProps) {
  const { projectGridRow, subcontract, subcontractGridRowEntities } = props;
  const subcontractGridRow = projectGridRow.subcontractGridRowIds
    .map((id) => subcontractGridRowEntities[id])
    .find((row) => row?.subcontractId === subcontract.id);
  if (subcontractGridRow) {
    return subcontractGridRow;
  }

  const subcontractScopeRequest = mapProjectGridRowToSubcontractScopeRequest(projectGridRow, subcontract.id);
  const subcontractScope = { ...subcontractScopeRequest, id: crypto.randomUUID() };
  return mapSubcontractScopeToSubcontractGridRow({ subcontractScope, subcontract });
}
