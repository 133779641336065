import type { IPeriodicProjectBudgetData } from '@app/types';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const adapter = createEntityAdapter<IPeriodicProjectBudgetData>();

const initialState = adapter.getInitialState();

// export type ISubcontractGridRowsSliceState = typeof initialState;

const slice = createSlice({
  name: 'budgetGridData',
  initialState,
  reducers: {
    setAllBudgetDataItems: adapter.setAll,
    upsertManyBudgetDataItems: adapter.upsertMany,
  },
});

export const { setAllBudgetDataItems, upsertManyBudgetDataItems } = slice.actions;

export default slice.reducer;
