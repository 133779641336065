import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { ISubcontractDetails } from '../../types';
import { addSubcontractGridRowIdToProjectGridRow } from '../projectGridRows';
import type { IState } from '../store';
import { upsertOneSubcontractGridRow } from '../subcontractGridRows';
import { getOrCreateSubcontractGridRowForUpdate } from './getOrCreateSubcontractGridRowForUpdate';
import { calculateProjectGridRowAggThunk } from './calculateProjectGridRowAggThunk';
import type { useSetSubcontractGridRowLimitValueWithConfirm } from '@app/pages/subs/$subcontractId/hooks/useSetSubcontractGridRowLimitValueWithConfirm';

interface IProps {
  projectGridRowId: string;
  subcontract: ISubcontractDetails;
  value: number;
  setLimitValue: ReturnType<typeof useSetSubcontractGridRowLimitValueWithConfirm>;
  onComplete: () => void;
}
export const updateProjectGridRowSubcontractBaseLimit = createAsyncThunk<void, IProps, { state: IState }>(
  'projectGridRows/updateProjectGridRowSubcontractBaseLimit',
  async (props, { getState, dispatch }) => {
    const { value, projectGridRowId, subcontract, setLimitValue, onComplete } = props;

    const projectGridRow = getState().projectGridRows.entities[projectGridRowId];
    invariant(projectGridRow, `projectGridRowId ${projectGridRowId} not found`);

    const subcontractBaseLimit = subcontract.subcontractLimits.find((limit) => limit.isBaseLimit);
    invariant(subcontractBaseLimit, 'subcontract base limit not found');

    const subcontractGridRow = getOrCreateSubcontractGridRowForUpdate({
      projectGridRow,
      subcontract,
      subcontractGridRowEntities: getState().subcontractGridRows.entities,
    });

    dispatch(upsertOneSubcontractGridRow(subcontractGridRow));

    /**
     * Set limit value will show a confirm dialog that will ask the user if they want to update the forecast
     * Regardless of the answer, it will call the afterUpdate callback
     *
     * We pass the onComplete callback to the afterUpdate callback to make sure the grid is updated after the limit value is set
     *
     * This is what we call a fikołek in Polish. You don’t want fikołki in you code. I barely wrote it and I already hate it.
     * However, this is just the reflection of the UI, so imagine how these poor users will feel when they see this.
     */
    setLimitValue({
      subcontractGridRowId: subcontractGridRow.id,
      subcontractLimitId: subcontractBaseLimit.id,
      value,
      afterUpdate: () => {
        // Make sure to update the project grid row if a new subcontract grid row is created
        dispatch(
          addSubcontractGridRowIdToProjectGridRow({ projectGridRowId, subcontractGridRowId: subcontractGridRow.id })
        );

        dispatch(calculateProjectGridRowAggThunk(projectGridRowId));
        onComplete();
      },
    });
  }
);
