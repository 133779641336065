import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useLogoutAction() {
  const navigate = useNavigate();

  return useCallback(async () => {
    navigate('/logout');
  }, [navigate]);
}
