import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { IToggleableSubSocketSectionId } from '../types';

interface ISubcontractState {
  isLoading: boolean;
  error: string;
  visibleSections: IToggleableSubSocketSectionId[];
}

const initialState: ISubcontractState = {
  isLoading: false,
  error: '',
  visibleSections: ['limits', 'bills', 'remaining', 'forecasts'],
};

const subcontractSlice = createSlice({
  name: 'subcontract',
  initialState,
  reducers: {
    setSubcontractVisibleSections(state, action: PayloadAction<IToggleableSubSocketSectionId[]>) {
      state.visibleSections = action.payload;
    },
  },
});

export const { setSubcontractVisibleSections } = subcontractSlice.actions;
export default subcontractSlice.reducer;
