import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { IProjectStatus } from '../types';
import type { IState } from './store';

const adapter = createEntityAdapter<IProjectStatus>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'projectStatuses',
  initialState,
  reducers: {
    setAllProjectStatuses: adapter.setAll,
  },
});

export const projectStatusSelectors = adapter.getSelectors();

export const selectProjectStatusByName = createSelector(
  [(state: IState) => state.projectStatuses, (_state: IState, name: string) => name],
  (state, name) => projectStatusSelectors.selectAll(state).find((s) => s.name === name)
);

export const { setAllProjectStatuses } = slice.actions;

export default slice.reducer;
