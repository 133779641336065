import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from '@app/core/utilities/invariant';
import type { IProject, IProjectGridRow } from '../../types';
import { upsertManyProjectGridRows } from '../projectGridRows';
import { recalculatePercentComplete } from '../projectGridRows/recalculatePercentComplete';
import type { IState } from '../store';
import { prepareProjectGridRowUpdate } from './prepareProjectGridRowUpdate';
import { queueScopeElementsUpdate } from './queueScopeElementsUpdate';
import { prepareScopeElementUpdate } from './prepareScopeElementUpdate';

interface IUpdateProjectGridRowsProps {
  projectGridRows: IProjectGridRow[];
  transactionId?: string;
  project: IProject;
}
export const updateProjectGridRows = createAsyncThunk<void, IUpdateProjectGridRowsProps, { state: IState }>(
  'projectGridRows/updateProjectGridRows',
  async (props, { getState, dispatch }) => {
    const { transactionId, projectGridRows, project } = props;

    if (projectGridRows.length === 0) {
      return;
    }

    const { milestoneId, yellowAlertDays, redAlertDays } = getState().projectGridRows;
    invariant(milestoneId, 'milestoneId not set in project grid rows config');

    const nextItems = projectGridRows.map((projectGridRow) =>
      prepareProjectGridRowUpdate({
        state: getState().projectGridRows,
        projectGridRow,
        milestoneId,
        yellowAlertDays,
        redAlertDays,
      })
    );

    dispatch(upsertManyProjectGridRows(nextItems));

    const updatedAncestors = recalculatePercentComplete({
      getState,
      dispatch,
      ids: nextItems.map(({ id }) => id),
      project,
    });

    dispatch(upsertManyProjectGridRows(updatedAncestors));

    const allUpdatedItems = updatedAncestors.reduce((acc, projectGridRow) => {
      const nextItem = prepareProjectGridRowUpdate({
        state: getState().projectGridRows,
        projectGridRow,
        milestoneId,
        yellowAlertDays,
        redAlertDays,
      });

      // it's possible that the ancestor was updated in the previous step
      // so we need to replace the original updated value with the new one
      const filteredItems = acc.filter(({ id }) => id !== nextItem.id);

      return [...filteredItems, nextItem];
    }, nextItems);

    const nextData = allUpdatedItems.map((item) => {
      const { path, dependencyString, children, subcontractGridRowIds, subcontractGridRows, agg } = item;

      return {
        scopeElement: prepareScopeElementUpdate(item),
        overrides: () => {
          const fundData = getState().projectGridRows.entities[item.id].fundData ?? [];
          return { path, dependencyString, children, subcontractGridRowIds, subcontractGridRows, fundData, agg };
        },
      };
    });

    dispatch(queueScopeElementsUpdate({ data: nextData, transactionId }));
  }
);
