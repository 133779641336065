import { useTenantTypeString } from '@app/hooks/useTenantTypeString';

interface Props {
  vendor: string;
  owner: string;
}
export const TenantTypeString = (props: Props) => {
  const { vendor, owner } = props;
  return useTenantTypeString(vendor, owner);
};
