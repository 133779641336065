import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../api/auth.api';
import { useQueryClient } from '@tanstack/react-query';
import CircularProgressIndicator from '../core/components/CircularProgressIndicator';
import { useSearchParams } from 'react-router-dom';
import { useReportError } from '../hooks/useReportError';

export function Component() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');

  const reportError = useReportError();

  useEffect(() => {
    logout()
      .catch((error) => {
        reportError(error);
      })
      .finally(() => {
        queryClient.clear();
        dispatch({ type: 'USER_LOGOUT' });
        if ('inline_manual_player' in window) {
          (window as any).inline_manual_player.destroy();
        }
        navigate(`/login${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`, { replace: true });
      });
  }, [dispatch, navigate, queryClient, returnUrl, reportError]);

  return (
    <div className="flex items-center justify-center ">
      <CircularProgressIndicator className="text-white" />
    </div>
  );
}
