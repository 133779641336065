import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { ITenantType } from '../types';
import type { IState } from './store';
import invariant from '@app/core/utilities/invariant';

const adapter = createEntityAdapter<ITenantType>();

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'tenantTypes',
  initialState,
  reducers: {
    setAllTenantTypes: adapter.setAll,
  },
});

export const tenantTypeSelectors = adapter.getSelectors();

export const { setAllTenantTypes } = slice.actions;

export const selectTenantTypeByName = createSelector(
  [(state: IState) => state.tenantTypes, (_state: IState, name: string) => name],
  (state, name) => {
    const item = tenantTypeSelectors.selectAll(state).find((s) => s.name === name);
    invariant(item, `tenantType not found: ${name}`);
    return item;
  }
);

export default slice.reducer;
