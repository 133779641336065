import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { IProjectClassification } from '../types';

const adapter = createEntityAdapter<IProjectClassification>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'projectClassifications',
  initialState,
  reducers: {
    setAllProjectClassifications: adapter.setAll,
    removeOneProjectClassification: adapter.removeOne,
    updateOneProjectClassification: adapter.updateOne,
    addOneProjectClassification: adapter.addOne,
  },
});

export const projectClassificationSelectors = adapter.getSelectors();

export const {
  setAllProjectClassifications,
  removeOneProjectClassification,
  updateOneProjectClassification,
  addOneProjectClassification,
} = slice.actions;

export default slice.reducer;
