import { noop } from 'lodash-es';
import { createContext, useCallback, useMemo, useState, useContext, type ReactNode } from 'react';
import { useReportError } from '@app/hooks/useReportError';
import { showErrorToast } from '../utilities/toasts';
import { AppDialog } from './AppDialog';
import { LoadingButton } from './LoadingButton';
import clsx from 'clsx';

export interface IConfirmDialogProps {
  /**
   * The title of the dialog.
   */
  title: string;
  /**
   * The message to display in the dialog.
   */
  message: ReactNode | string;
  /**
   * On confirm callback.
   */
  onConfirm: () => Promise<void> | void;
  /**
   * On cancel callback.
   */
  onCancel?: () => Promise<void> | void;
  /**
   * The label for the submit button.
   * @default 'OK'
   */
  confirmLabel?: string;
  /**
   * The label for the cancel button.
   * @default 'Cancel'
   */
  cancelLabel?: string;

  /**
   * Color of the confirm button
   * @default 'blue'
   */
  confirmColor?: 'orange' | 'blue' | 'red' | 'green' | 'blue-dark';
}

interface IConfirmDialogContext {
  openConfirmDialog: (params: IConfirmDialogProps) => void;
  closeConfirmDialog: () => void;
}

const ConfirmDialogContext = createContext<IConfirmDialogContext>({
  openConfirmDialog: noop,
  closeConfirmDialog: noop,
});

const defaultDialogProps: IConfirmDialogProps = {
  title: '',
  message: '',
  onConfirm: () => Promise.resolve(),
};

type IComponentProps = IConfirmDialogProps & { isOpen: boolean; onRequestClose: () => void };

const Component = (props: IComponentProps) => {
  const {
    isOpen,
    onRequestClose,
    onCancel,
    title,
    message,
    onConfirm,
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    confirmColor = 'blue-dark',
  } = props;

  const reportError = useReportError();

  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
    try {
      await onConfirm();
      onRequestClose();
    } catch (error) {
      reportError(error);
      showErrorToast({ message: 'We couldn’t complete this request. Please try again.' });
    }
    setIsConfirming(false);
  }, [onConfirm, onRequestClose, reportError]);

  const handleClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    onRequestClose();
  }, [onCancel, onRequestClose]);

  return (
    <AppDialog open={isOpen} onRequestClose={handleClose} title={title} size="md" zIndex="z-confirm-dialog">
      <div className="mb-4 flex flex-col gap-4 text-base">{message}</div>
      <div className="flex items-center gap-4">
        <LoadingButton
          type="button"
          className={clsx(
            {
              'btn-orange': confirmColor === 'orange',
              'btn-blue': confirmColor === 'blue',
              'btn-red': confirmColor === 'red',
              'btn-green': confirmColor === 'green',
              'btn-blue-dark': confirmColor === 'blue-dark',
            },
            'btn-lg'
          )}
          onClick={handleConfirm}
          isProcessing={isConfirming}
        >
          {confirmLabel}
        </LoadingButton>
        <button type="button" className="btn-white btn-lg" onClick={handleClose} disabled={isConfirming}>
          {cancelLabel}
        </button>
      </div>
    </AppDialog>
  );
};

export const Provider = ({ children }: { children?: ReactNode }) => {
  const [dialogProps, setDialogProps] = useState<IConfirmDialogProps>(defaultDialogProps);
  const [isOpen, setIsOpen] = useState(false);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openConfirmDialog = useCallback((props: IConfirmDialogProps) => {
    setDialogProps(props);
    setIsOpen(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setIsOpen(false);
    setDialogProps(defaultDialogProps);
  }, []);

  const value = useMemo(() => ({ openConfirmDialog, closeConfirmDialog }), [openConfirmDialog, closeConfirmDialog]);

  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      <Component {...dialogProps} isOpen={isOpen} onRequestClose={onRequestClose} />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => useContext(ConfirmDialogContext);
