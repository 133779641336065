import type { IFundData, IProjectGridRow } from '@app/types';
import { recalculateValues } from './recalculateValues';

/**
 * Change current fund data to inherent and recalculate the values
 *
 * @param fundData IFundData
 * @param projectGridRow IProjectGridRow
 * @returns IFundData
 */
export function changeFundDataToInherent(fundData: IFundData, projectGridRow: IProjectGridRow) {
  const next = structuredClone(fundData);
  next.inherent = true;

  return recalculateValues({
    fundData: next,
    projectGridRow,
    field: 'fundMethod',
  });
}
