import * as React from 'react';
import InputMask from 'react-input-mask';
import { FormInput } from './FormInput';

type IFormPhoneInputProps = {
  name: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FormPhoneInput = React.forwardRef(function FormPhoneInput(
  props: IFormPhoneInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { onChange, onBlur, disabled, ...rest } = props;

  return (
    <InputMask mask="(999) 999-9999" maskPlaceholder=" " onChange={onChange} onBlur={onBlur} disabled={disabled}>
      <FormInput {...rest} type="tel" ref={ref} />
    </InputMask>
  );
});
