import type { IBudgetGridRow, IProgramBudgetBudgetGridRow, IProgramBudgetCostsGridRow } from '@app/types';
import type { Draft, EntityState } from '@reduxjs/toolkit';
import { findInvariant } from '@app/core/utilities/findInvariant';
import invariant from '@app/core/utilities/invariant';

export function recalculateBudgetOverallTotals(
  state: Draft<EntityState<IBudgetGridRow, string>>,
  fundId?: string | null
) {
  const allRows = Object.values(state.entities);

  const totalRows = allRows.filter((row) => row.level === 'total');
  const totalBudgetRow = findInvariant(totalRows, (row) => row.financeType === 'budget');
  const totalCostsRow = findInvariant(totalRows, (row) => row.financeType === 'costs');
  const totalVarianceRow = findInvariant(totalRows, (row) => row.financeType === 'variance');

  const totalBudgetData: Record<string, number> = {};
  const programBudgetRows = allRows.filter(
    (row) => row.financeType === 'budget' && row.level === 'program'
  ) as IProgramBudgetBudgetGridRow[];

  const projectScores: number[] = [];
  const projectBudgetScores: number[] = [];

  for (const programBudgetRow of programBudgetRows) {
    const matchingProgramCostRow = findInvariant(
      allRows,
      (row) => row.financeType === 'costs' && row.level === 'program' && row.programId === programBudgetRow.programId
    ) as IProgramBudgetCostsGridRow;

    projectScores.push(matchingProgramCostRow.score);
    projectBudgetScores.push(programBudgetRow.score);

    const programBudgetBucket = fundId ? programBudgetRow.fundData[fundId] : programBudgetRow.budgetData;
    invariant(programBudgetBucket, `No budget data found for fund ${fundId} in row ${programBudgetRow.id}`);
    for (const period of Object.keys(programBudgetBucket)) {
      totalBudgetData[period] = (totalBudgetData[period] ?? 0) + programBudgetBucket[period];
    }
  }
  if (fundId) {
    state.entities[totalBudgetRow.id].fundData[fundId] = totalBudgetData;
  } else {
    state.entities[totalBudgetRow.id].budgetData = totalBudgetData;
  }

  const totalCostsData: Record<string, number> = {};
  const programCostsRows = allRows.filter((row) => row.financeType === 'costs' && row.level === 'program');
  for (const row of programCostsRows) {
    const programCostsBucket = fundId ? row.fundData[fundId] : row.budgetData;
    invariant(programCostsBucket, `No budget data found for fund ${fundId} in row ${row.id}`);
    for (const period of Object.keys(programCostsBucket)) {
      totalCostsData[period] = (totalCostsData[period] ?? 0) + programCostsBucket[period];
    }
  }
  if (fundId) {
    state.entities[totalCostsRow.id].fundData[fundId] = totalCostsData;
  } else {
    state.entities[totalCostsRow.id].budgetData = totalCostsData;
  }

  const totalVarianceData: Record<string, number> = {};
  const programVarianceRows = allRows.filter((row) => row.financeType === 'variance' && row.level === 'program');
  for (const row of programVarianceRows) {
    const varianceBucket = fundId ? row.fundData[fundId] : row.budgetData;
    for (const period of Object.keys(varianceBucket)) {
      totalVarianceData[period] = (totalVarianceData[period] ?? 0) + varianceBucket[period];
    }
  }
  if (fundId) {
    state.entities[totalVarianceRow.id].fundData[fundId] = totalVarianceData;
  } else {
    state.entities[totalVarianceRow.id].budgetData = totalVarianceData;
  }

  /**
   * Total budget score is a sum of all program budget scores
   * Total costs score is a sum of all program costs scores
   */
  state.entities[totalBudgetRow.id].score = projectBudgetScores.reduce((acc, cur) => acc + cur, 0);
  state.entities[totalCostsRow.id].score = projectScores.reduce((acc, cur) => acc + cur, 0);
}
