import { type QueryKey, useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { IBaseQueryOptions } from '../types';

export function useBaseQuery<T>(
  queryKey: QueryKey,
  request: (signal: AbortSignal | undefined) => Promise<AxiosResponse<T, any>>,
  options?: Omit<IBaseQueryOptions<T>, 'onSuccess' | 'onError' | 'onSettled'>
): UseQueryResult<T | undefined, unknown> {
  return useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await request(signal);
      return response.data;
    },
    ...options,
  });
}
