import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { IProjectSubStatuses } from '../types';

const adapter = createEntityAdapter<IProjectSubStatuses>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = adapter.getInitialState();

const slice = createSlice({
  name: 'projectSubStatuses',
  initialState,
  reducers: {
    setAllProjectSubStatuses: adapter.setAll,
    removeOneProjectSubStatus: adapter.removeOne,
    updateOneProjectSubStatus: adapter.updateOne,
    addOneProjectSubStatus: adapter.addOne,
  },
});

export const projectSubStatusesSelectors = adapter.getSelectors();

export const {
  setAllProjectSubStatuses,
  removeOneProjectSubStatus,
  updateOneProjectSubStatus,
  addOneProjectSubStatus,
} = slice.actions;

export default slice.reducer;
