import axios from 'axios';
import { CURRENT_PROJECT_PREFERENCE_VERSION } from '../config';
import type { IProjectPreferences, ITenantTypeName } from '../types';
import { type IProjectPreferenceResponse, migrateProjectPreferences } from '../migrations/migrateProjectPreferences';

export const getProjectPreferences = async (
  projectId: string,
  tenantTypeName: ITenantTypeName
): Promise<IProjectPreferences | null> => {
  const response = await axios.get<IProjectPreferenceResponse | undefined>(`/project-user-preferences/${projectId}`);
  if (response.data) {
    return migrateProjectPreferences(response.data, tenantTypeName);
  }
  return null;
};

export async function createProjectPreferences(data: IProjectPreferences): Promise<IProjectPreferences> {
  const { gridState, ganttState, ...rest } = data;

  const scopeElementViewState = JSON.stringify({
    gridState,
    ganttState,
    version: CURRENT_PROJECT_PREFERENCE_VERSION,
  });

  const response = await axios.post<IProjectPreferences>('/project-user-preferences', {
    ...rest,
    scopeElementViewState,
  });

  return response.data;
}

export async function updateProjectPreferences(data: IProjectPreferences): Promise<IProjectPreferences> {
  const { gridState, ganttState, ...rest } = data;

  const scopeElementViewState = JSON.stringify({
    gridState,
    ganttState,
    version: CURRENT_PROJECT_PREFERENCE_VERSION,
  });

  const response = await axios.put<IProjectPreferences>(`/project-user-preferences/${data.id}`, {
    ...rest,
    scopeElementViewState,
  });

  return response.data;
}
