import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ITimesheetEntry, ITimesheetRow } from '../../types';
import type { IState } from '../store';
import { updateOneTimesheetRow } from '../timesheetRows';
import { addOneTimesheetEntry } from '.';

export const addTimesheetEntry = createAsyncThunk<
  void,
  { row: ITimesheetRow; entry: ITimesheetEntry },
  { state: IState }
>('timesheetEntries/addTimesheetEntry', async (props, { dispatch }) => {
  const { row, entry } = props;

  dispatch(addOneTimesheetEntry(entry));
  dispatch(
    updateOneTimesheetRow({
      id: row.rowGroup,
      changes: { timesheetEntryIds: [...row.timesheetEntryIds, entry.id] },
    })
  );
});
