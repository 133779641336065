import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IProjectStatusReportScopeElementItemFilters } from '@app/types';
import type { IState } from '../store';
import { persistUserPreferences } from './persistUserPreferences';

export const setProjectStatusReportScopeElementItemFilters = createAsyncThunk<
  void,
  { changes: Partial<IProjectStatusReportScopeElementItemFilters> },
  { state: IState }
>('uiView/setProjectStatusReportScopeElementItemFilters', (_, { dispatch }) => {
  dispatch(persistUserPreferences());
});
