import type { IFundData } from '@app/types';

export function hasRowChanged(fundDataA: IFundData, fundDataB: IFundData) {
  return (
    fundDataA.fundMethod !== fundDataB.fundMethod ||
    fundDataA.amount !== fundDataB.amount ||
    fundDataA.percentage !== fundDataB.percentage ||
    fundDataA.inherent !== fundDataB.inherent
  );
}
