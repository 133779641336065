import { Outlet, ScrollRestoration } from 'react-router-dom';
import { InlineManual } from './InlineManual';
import { RouteLoaderOverlay } from './RouteLoaderOverlay';
import { SiteTitle } from './SiteTitle';
import TopNav from './TopNav';
import { FEATURE_FLAGS } from '@app/config';
import { UserSessionManager } from './UserSessionManager';

export default function Root() {
  return (
    <>
      <SiteTitle />
      <div>
        <TopNav />
        <main id="content" className="flex h-dvh flex-col overflow-y-hidden pt-16">
          <Outlet />
        </main>
      </div>
      {!FEATURE_FLAGS.disableInlineManual && <InlineManual />}

      <ScrollRestoration />

      <RouteLoaderOverlay />

      <UserSessionManager />
    </>
  );
}
