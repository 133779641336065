import { indexArrToWbs } from '@app/core/utilities/grid/indexArrToWbs';
import type { IProjectGridRow } from '@app/types';
import { adapter, type IProjectGridRowsSliceState } from './index';
import type { Draft } from '@reduxjs/toolkit';

interface IRecalculateRowTreeParams {
  state: Draft<IProjectGridRowsSliceState>;
  rows: (IProjectGridRow | undefined)[];
  indexArr?: number[];
  path?: string[];
  rowIdMap?: Map<'currentIndex', number>;
}
export function recalculateRowTreeReducer({
  state,
  rows,
  indexArr = [],
  path = [],
  rowIdMap = new Map(),
}: IRecalculateRowTreeParams) {
  rows.forEach((row, index) => {
    if (!row || (row.newRow && !row.isProcessing)) {
      return;
    }
    const rowId = (rowIdMap.get('currentIndex') ?? 0) + 1;
    rowIdMap.set('currentIndex', rowId);
    const nextIndexArr = [...indexArr, index];

    const nextPath = [...path, row.id];
    const childrenRows = row.children.map((id) => state.entities[id]);
    const parentRow = row.parentScopeElementId ? state.entities[row.parentScopeElementId] : null;

    adapter.updateOne(state, {
      id: row.id,
      changes: {
        wbsFullLabel: indexArrToWbs(nextIndexArr),
        sequenceId: index + 1,
        path: nextPath,
        rowId,
        parentRowId: parentRow?.rowId,
      },
    });

    recalculateRowTreeReducer({ state, rows: childrenRows, indexArr: nextIndexArr, path: nextPath, rowIdMap });
  });
  return state;
}
