import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IExpenseReportFilterSetFields } from '../types';
import { formatISO, startOfWeek, addDays, endOfDay } from 'date-fns';

interface IExpenseReportOverviewState {
  filterSetFields: IExpenseReportFilterSetFields;
  showParent: boolean;
  useDates: boolean;
}

const initialFilterSetFields = {
  startDate: formatISO(startOfWeek(new Date())),
  endDate: formatISO(endOfDay(addDays(startOfWeek(new Date()), 6))),
  programIds: [],
  employeeIds: [],
  projectIds: [],
  expenseStatusIds: [],
  activityCodeIds: [],
  projectStatusIds: [],
  categoryIds: [],
  resourceCategoryIds: [],
};

const initialState: IExpenseReportOverviewState = {
  filterSetFields: { ...initialFilterSetFields },
  showParent: false,
  useDates: false,
};

const expenseReportOverviewSlice = createSlice({
  name: 'expenseReportOverview',
  initialState,
  reducers: {
    setExpenseReportOverviewFilters(state, action: PayloadAction<Partial<IExpenseReportFilterSetFields>>) {
      state.filterSetFields = { ...state.filterSetFields, ...action.payload };
    },
    clearExpenseReportOverviewFilters(state) {
      state.filterSetFields = { ...initialFilterSetFields };
    },
    toggleShowParent(state) {
      state.showParent = !state.showParent;
    },
    toggleUseDates(state) {
      state.useDates = !state.useDates;
    },
  },
});

export const { setExpenseReportOverviewFilters, clearExpenseReportOverviewFilters, toggleShowParent, toggleUseDates } =
  expenseReportOverviewSlice.actions;

export default expenseReportOverviewSlice.reducer;
