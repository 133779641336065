import { add, endOfYear, format, startOfYear } from 'date-fns';
import { shortISODateFormat } from '@app/config';
import type { ICostDistributionZoomLevel, ITenantTypeName } from '@app/types';

export function getDefaultCostDistributionSettings(tenantTypeName: ITenantTypeName) {
  return {
    startDate: format(startOfYear(new Date()), shortISODateFormat),
    zoomLevel: (tenantTypeName === 'Owner' ? 'year' : 'month') as ICostDistributionZoomLevel,
    endDate: format(endOfYear(add(new Date(), { years: 4 })), shortISODateFormat),
    rememberPeriod: false,
    staticRangeId: null,
  };
}
