import { type IProjectGridRowsSliceState, projectGridRowsSelectors } from './index';

export function getTopLevelRows(state: IProjectGridRowsSliceState, projectId: string) {
  return projectGridRowsSelectors.selectAll(state).filter((row) => {
    if (row.projectId !== projectId) {
      return false;
    }
    if (row.parentScopeElementId) {
      return false;
    }
    if (row.newRow && !row.isProcessing) {
      return false;
    }
    return true;
  });
}
