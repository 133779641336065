import {
  DISTRIBUTION_FRONT,
  DISTRIBUTION_BACK,
  DISTRIBUTION_NORMAL,
  DEFAULT_SCHEDULE_SETTINGS,
  DISTRIBUTION_UNIFORM,
} from '@app/config';
import type { IWorkloadGridRow } from '@app/types';
import { calculateBackDistribution } from './calculateBackDistribution';
import { calculateFrontDistribution } from './calculateFrontDistribution';
import { calculateNormalDistribution } from './calculateNormalDistribution';
import { calculateUniformDistribution } from './calculateUniformDistribution';
import { getWorkloadGridRowDays } from './getWorkloadGridRowDays';
import type { ICalculateWorkloadDistributionProps } from './ICalculateWorkloadDistributionProps';

function calculateDays(params: ICalculateWorkloadDistributionProps) {
  switch (params.distributionMethod) {
    case DISTRIBUTION_UNIFORM:
      return calculateUniformDistribution(params);
    case DISTRIBUTION_FRONT:
      return calculateFrontDistribution(params);
    case DISTRIBUTION_BACK:
      return calculateBackDistribution(params);
    case DISTRIBUTION_NORMAL:
      return calculateNormalDistribution(params);
  }
}

export function calculateWorkloadDistribution(
  workloadGridRow: IWorkloadGridRow,
  updatedField?: string
): IWorkloadGridRow {
  const { startDate, finishDate } = workloadGridRow;

  if (!startDate || !finishDate) {
    return {
      ...workloadGridRow,
      days: {},
      distributionMethod: workloadGridRow.distributionMethod,
      allocation: null,
    };
  }
  const hoursPerDay = workloadGridRow.hoursPerDay ?? DEFAULT_SCHEDULE_SETTINGS.HOURS_PER_DAY;

  const { calendarDays, activeDays } = getWorkloadGridRowDays(workloadGridRow);
  const totalAvailableHours = activeDays.length * hoursPerDay;

  const rowAllocation = workloadGridRow.allocation ?? 0;
  const calculatedAllocation =
    totalAvailableHours > 0 ? Math.round((workloadGridRow.draftHours / totalAvailableHours) * 100) : 0;

  const allocation = updatedField === 'allocation' ? rowAllocation : calculatedAllocation;
  const hours = updatedField === 'allocation' ? (allocation / 100) * totalAvailableHours : workloadGridRow.draftHours;

  const distributionMethod = allocation > 100 ? DISTRIBUTION_UNIFORM : workloadGridRow.distributionMethod;

  return {
    ...workloadGridRow,
    distributionMethod,
    allocation,
    draftHours: hours,
    days: calculateDays({ distributionMethod, days: calendarDays, hours, hoursPerDay, activeDays }),
  };
}
